export const alertActionType = {
    FETCH_ALERT_LIST: 'FETCH_ALERT_LIST',
    FETCH_ALERT_LIST_SUCCESS: 'FETCH_ALERT_LIST_SUCCESS',
    FETCH_ALERT_LIST_ERROR: 'FETCH_ALERT_LIST_ERROR',

    FETCH_SINGLE_PATROL: 'FETCH_SINGLE_PATROL',
    FETCH_SINGLE_PATROL_SUCCESS: 'FETCH_SINGLE_PATROL_SUCCESS',
    FETCH_SINGLE_PATROL_ERROR: 'FETCH_SINGLE_PATROL_ERROR',

    FETCH_SINGLE_SENTRY: 'FETCH_SINGLE_SENTRY',
    FETCH_SINGLE_SENTRY_SUCCESS: 'FETCH_SINGLE_SENTRY_SUCCESS',
    FETCH_SINGLE_SENTRY_ERROR: 'FETCH_SINGLE_SENTRY_ERROR',
  

    FETCH_SINGLE_DEVICE_ALERT: 'FETCH_SINGLE_DEVICE_ALERT',
    FETCH_SINGLE_DEVICE_ALERT_SUCCESS: 'FETCH_SINGLE_DEVICE_ALERT_SUCCESS',
    FETCH_SINGLE_DEVICE_ALERT_ERROR: 'FETCH_SINGLE_DEVICE_ALERT_ERROR',

    FETCH_SINGLE_CLEANER_ALERT: 'FETCH_SINGLE_CLEANER_ALERT',
    FETCH_SINGLE_CLEANER_ALERT_SUCCESS: 'FETCH_SINGLE_CLEANER_ALERT_SUCCESS',
    FETCH_SINGLE_CLEANER_ALERT_ERROR: 'FETCH_SINGLE_CLEANER_ALERT_ERROR',

    ASSIGN_ALERT: 'ASSIGN_ALERT',
    ASSIGN_ALERT_SUCCESS: 'ASSIGN_ALERT_SUCCESS',
    ASSIGN_ALERT_ERROR: 'ASSIGN_ALERT_ERROR',
    CLEAR_ASSIGN_ALERT_STATUS: 'CLEAR_ASSIGN_ALERT_STATUS',

    FETCH_DASHBOARD_GUAGES :'FETCH_DASHBOARD_GUAGES',
    FETCH_DASHBOARD_GUAGES_SUCCESS :'FETCH_DASHBOARD_GUAGES_SUCCESS',
    FETCH_DASHBOARD_GUAGES_ERROR :'FETCH_DASHBOARD_GUAGES_ERROR',

    FETCH_ALERT_TREND: 'FETCH_ALERT_TREND',
    FETCH_ALERT_TREND_SUCCESS: 'FETCH_ALERT_TREND_SUCCESS',
    FETCH_ALERT_TREND_ERROR: 'FETCH_ALERT_TREND_ERROR',

    FETCH_AUDITTRAIL_LIST : 'FETCH_AUDITTRAIL_LIST',
    FETCH_AUDITTRAIL_LIST_SUCCESS : 'FETCH_AUDITTRAIL_LIST_SUCCESS',
    FETCH_AUDITTRAIL_LIST_ERROR : 'FETCH_AUDITTRAIL_LIST_ERROR',

    FETCH_SINGLE_SENSOR_ALERT: 'FETCH_SINGLE_SENSOR_ALERT',
    FETCH_SINGLE_SENSOR_ALERT_SUCCESS: 'FETCH_SINGLE_SENSOR_ALERT_SUCCESS',
    FETCH_SINGLE_SENSOR_ALRET_ERROR: 'FETCH_SINGLE_SENSOR_ALRET_ERROR',

    FETCH_TRACKING_REPORT : "FETCH_TRACKING_REPORT",
    FETCH_TRACKING_REPORT_SUCCESS : "FETCH_TRACKING_REPORT_SUCCESS",
    FETCH_TRACKING_REPORT_ERROR : "FETCH_TRACKING_REPORT_ERROR",

    FETCH_TOP_PERSONNEL_ALERTS : "FETCH_TOP_PERSONNEL_ALERTS",
    FETCH_TOP_PERSONNEL_ALERTS_SUCCESS : "FETCH_TOP_PERSONNEL_ALERTS_SUCCESS",
    FETCH_TOP_PERSONNEL_ALERTS_ERROR : "FETCH_TOP_PERSONNEL_ALERTS_ERROR",

    FETCH_TIME_BASED_REPORT : "FETCH_TIME_BASED_REPORT",
    FETCH_TIME_BASED_REPORT_SUCCESS : "FETCH_TIME_BASED_REPORT_SUCCESS",
    FETCH_TIME_BASED_REPORT_ERROR : "FETCH_TIME_BASED_REPORT_ERROR",

    FETCH_SENSOR_REPORT : "FETCH_SENSOR_REPORT",
    FETCH_SENSOR_REPORT_SUCCESS : "FETCH_SENSOR_REPORT_SUCCESS",
    FETCH_SENSOR_REPORT_ERROR : "FETCH_SENSOR_REPORT_ERROR",

    FETCH_TAG_BATTERY_ALERT : "FETCH_TAG_BATTERY_ALERT",
    FETCH_TAG_BATTERY_ALERT_SUCCESS : "FETCH_TAG_BATTERY_ALERT_SUCCESS",
    FETCH_TAG_BATTERY_ALERT_ERROR : "FETCH_TAG_BATTERY_ALERT_ERROR",

    FETCH_VA_ALERT : "FETCH_VA_ALERT",
    FETCH_VA_ALERT_SUCCESS : "FETCH_VA_ALERT_SUCCESS",
    FETCH_VA_ALERT_ERROR : "FETCH_VA_ALERT_ERROR",

    FETCH_SURVEILLANCE_PERFORMANCE : "FETCH_SURVEILLANCE_PERFORMANCE",
    FETCH_SURVEILLANCE_PERFORMANCE_SUCCESS : "FETCH_SURVEILLANCE_PERFORMANCE_SUCCESS",
    FETCH_SURVEILLANCE_PERFORMANCE_ERROR : "FETCH_SURVEILLANCE_PERFORMANCE_ERROR",

    FETCH_ENCODER_DROPDOWN : "FETCH_ENCODER_DROPDOWN",
    FETCH_ENCODER_DROPDOWN_SUCCESS : "FETCH_ENCODER_DROPDOWN_SUCCESS",
    FETCH_ENCODER_DROPDOWN_ERROR : "FETCH_ENCODER_DROPDOWN_ERROR",

    FETCH_BATTERY_PERFORMANCE : "FETCH_BATTERY_PERFORMANCE",
    FETCH_BATTERY_PERFORMANCE_SUCCESS : "FETCH_BATTERY_PERFORMANCE_SUCCESS",
    FETCH_BATTERY_PERFORMANCE_ERROR : "FETCH_BATTERY_PERFORMANCE_ERROR",

    FETCH_SINGLE_CAMERA_ALERT : "FETCH_SINGLE_CAMERA_ALERT",
    FETCH_SINGLE_CAMERA_ALERT_SUCCESS : "FETCH_SINGLE_CAMERA_ALERT_SUCCESS",
    FETCH_SINGLE_CAMERA_ALERT_ERROR : "FETCH_SINGLE_CAMERA_ALERT_ERROR",

    FETCH_CLEANER_ALERT : "FETCH_CLEANER_ALERT",
    FETCH_CLEANER_ALERT_SUCCESS : "FETCH_CLEANER_ALERT_SUCCESS",
    FETCH_CLEANER_ALERT_ERROR : "FETCH_CLEANER_ALERT_ERROR",
}
//alert list
export const fetchAlertList = (data) => {
    return {
        type: alertActionType.FETCH_ALERT_LIST,
        data: data
    }
}
export const fetchAlertListSuccess = (data) => {
    return {
        type: alertActionType.FETCH_ALERT_LIST_SUCCESS,
        payload: data
    }
}
export const fetchAlertListError = (error) => {
    return {
        type: alertActionType.FETCH_ALERT_LIST_ERROR,
        error
    }
}
export const fetchSinglePatrol = (id) => {
    return {
        type: alertActionType.FETCH_SINGLE_PATROL,
        id: id
    }
}
export const fetchSinglePatrolSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SINGLE_PATROL_SUCCESS,
        payload: data
    }
}
export const fetchSinglePatrolError = (error) => {
    return {
        type: alertActionType.FETCH_SINGLE_PATROL_ERROR,
        error
    }
}
export const fetchSingleSentry = (id) => {
    return {
        type: alertActionType.FETCH_SINGLE_SENTRY,
        id: id
    }
}
export const fetchSingleSentrySuccess = (data) => {
    return {
        type: alertActionType.FETCH_SINGLE_SENTRY_SUCCESS,
        payload: data
    }
}
export const fetchSingleSentryError = (error) => {
    return {
        type: alertActionType.FETCH_SINGLE_SENTRY_ERROR,
        error
    }
}

export const fetchSingleDeviceAlert = (id) => {
    return {
        type: alertActionType.FETCH_SINGLE_DEVICE_ALERT,
        id: id
    }
}
export const fetchSingleDeviceAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SINGLE_DEVICE_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchSingleDeviceAlertError = (error) => {
    return {
        type: alertActionType.FETCH_SINGLE_DEVICE_ALERT_ERROR,
        error
    }
}


export const fetchSingleCleanerAlert = (id) => {
    return {
        type: alertActionType.FETCH_SINGLE_CLEANER_ALERT,
        id: id
    }
}
export const fetchSingleCleanerAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SINGLE_CLEANER_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchSingleCleanerAlertError = (error) => {
    return {
        type: alertActionType.FETCH_SINGLE_CLEANER_ALERT_ERROR,
        error
    }
}

export const assignAlert = (data) => {
    return {
        type: alertActionType.ASSIGN_ALERT,
        data:data
    }
}
export const assignAlertSuccess = (data) => {
    return {
        type: alertActionType.ASSIGN_ALERT_SUCCESS,
        payload: data
    }
}
export const assignAlertError = (error) => {
    return {
        type: alertActionType.ASSIGN_ALERT_ERROR,
        error
    }
}
export const clearAssignAlertStatus = () => {
    return {
        type: alertActionType.CLEAR_ASSIGN_ALERT_STATUS,
      
    }
}
//get dashboard guages
export const fetchDashboardGuages = (data) => {
    return {
        type: alertActionType.FETCH_DASHBOARD_GUAGES,
        data:data
    }
}
export const fetchDashboardGuagesSuccess = (data) => {
    return {
        type: alertActionType.FETCH_DASHBOARD_GUAGES_SUCCESS,
        payload: data
    }
}
export const fetchDashboardGuagesError = (error) => {
    return {
        type: alertActionType.FETCH_DASHBOARD_GUAGES_ERROR,
        error
    }
}
//get alert trend
export const fetchAlertTrend = (data) => {
    return {
        type: alertActionType.FETCH_ALERT_TREND,
        data: data
    }
}
export const fetchAlertTrendSuccess = (data) => {
    return {
        type: alertActionType.FETCH_ALERT_TREND_SUCCESS,
        payload: data
    }
}
export const fetchAlertTrendError = (error) => {
    return {
        type: alertActionType.FETCH_ALERT_TREND_ERROR,
        error
    }
}

//get audit list
export const fetchAuditList = (data) => {
    return {
        type: alertActionType.FETCH_AUDITTRAIL_LIST,
        data: data
    }
}
export const fetchAuditListSuccess = (data) => {
    return {
        type: alertActionType.FETCH_AUDITTRAIL_LIST_SUCCESS,
        payload: data
    }
}
export const fetchAuditListError = (error) => {
    return {
        type: alertActionType.FETCH_AUDITTRAIL_LIST_ERROR,
        error
    }
}
//fetch single sensor alert
export const fetchSinglesensorAlert =(id)=>{
    return{
        type: alertActionType.FETCH_SINGLE_SENSOR_ALERT,
        id: id
    }
}
export const fetchSinglesensorAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SINGLE_SENSOR_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchSinglesensorAlertError = (error) => {
    return {
        type: alertActionType.FETCH_SINGLE_SENSOR_ALRET_ERROR,
        error
    }
}
//fetch tracking report
export const fetchTrackingReport =(data)=>{
    return{
        type: alertActionType.FETCH_TRACKING_REPORT,
        data: data
    }
}
export const fetchTrackingReportSuccess = (data) => {
    return {
        type: alertActionType.FETCH_TRACKING_REPORT_SUCCESS,
        payload: data
    }
}
export const fetchTrackingReportError = (error) => {
    return {
        type: alertActionType.FETCH_TRACKING_REPORT_ERROR,
        error
    }
}
//fetch 10 personnel  alerts
export const fetchTopPersonnelAlert =(data)=>{
    return{
        type: alertActionType.FETCH_TOP_PERSONNEL_ALERTS,
        data: data
    }
}
export const fetchTopPersonnelAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_TOP_PERSONNEL_ALERTS_SUCCESS,
        payload: data
    }
}
export const fetchTopPersonnelAlertError = (error) => {
    return {
        type: alertActionType.FETCH_TOP_PERSONNEL_ALERTS_ERROR,
        error
    }
}
//fetch report based on time
export const fetchTimeBasedReport =(data)=>{
    return{
        type: alertActionType.FETCH_TIME_BASED_REPORT,
        data: data
    }
}
export const fetchTimeBasedReportSuccess = (data) => {
    return {
        type: alertActionType.FETCH_TIME_BASED_REPORT_SUCCESS,
        payload: data
    }
}
export const fetchTimeBasedReportError = (error) => {
    return {
        type: alertActionType.FETCH_TIME_BASED_REPORT_ERROR,
        error
    }
}
//fetch  sensor
export const fetchSensorReport =(data)=>{
    return{
        type: alertActionType.FETCH_SENSOR_REPORT,
        data: data
    }
}
export const fetchSensorReportSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SENSOR_REPORT_SUCCESS,
        payload: data
    }
}
export const fetchSensorReportError = (error) => {
    return {
        type: alertActionType.FETCH_SENSOR_REPORT_ERROR,
        error
    }
}
//fetch tag battery alert
export const fetchTagBatteryAlert =(data)=>{
    return{
        type: alertActionType.FETCH_TAG_BATTERY_ALERT,
        data: data
    }
}
export const fetchTagBatteryAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_TAG_BATTERY_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchTagBatteryAlertError = (error) => {
    return {
        type: alertActionType.FETCH_TAG_BATTERY_ALERT_ERROR,
        error
    }
}
//fetch va alert
export const fetchVaAlert =(data)=>{
    return{
        type: alertActionType.FETCH_VA_ALERT,
        data: data
    }
}
export const fetchVaAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_VA_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchVaAlertError = (error) => {
    return {
        type: alertActionType.FETCH_VA_ALERT_ERROR,
        error
    }
}
//fetch surveillance performance
export const fetchSurveillancePerformance =(data)=>{
    return{
        type: alertActionType.FETCH_SURVEILLANCE_PERFORMANCE,
        data: data
    }
}
export const fetchSurveillancePerformanceSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SURVEILLANCE_PERFORMANCE_SUCCESS,
        payload: data
    }
}
export const fetchSurveillancePerformanceError = (error) => {
    return {
        type: alertActionType.FETCH_SURVEILLANCE_PERFORMANCE_ERROR,
        error
    }
}
//fetch encoderdropdown
export const fetchEncoderDropDown =(data)=>{
    return{
        type: alertActionType.FETCH_ENCODER_DROPDOWN,
        data: data
    }
}
export const fetchEncoderDropDownSuccess = (data) => {
    return {
        type: alertActionType.FETCH_ENCODER_DROPDOWN_SUCCESS,
        payload: data
    }
}
export const fetchEncoderDropDownError = (error) => {
    return {
        type: alertActionType.FETCH_ENCODER_DROPDOWN_ERROR,
        error
    }
}
//fetch battery performance
export const fetchBatteryPerformance =(data)=>{
    return{
        type: alertActionType.FETCH_BATTERY_PERFORMANCE,
        data: data
    }
}
export const fetchBatteryPerformanceSuccess = (data) => {
    return {
        type: alertActionType.FETCH_BATTERY_PERFORMANCE_SUCCESS,
        payload: data
    }
}
export const fetchBatteryPerformanceError = (error) => {
    return {
        type: alertActionType.FETCH_BATTERY_PERFORMANCE_ERROR,
        error
    }
}
//fetch single camera alert
export const fetchSingleCameraAlert =(id)=>{
    return{
        type: alertActionType.FETCH_SINGLE_CAMERA_ALERT,
        id: id
    }
}
export const fetchSingleCameraAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_SINGLE_CAMERA_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchSingleCameraAlertError = (error) => {
    return {
        type: alertActionType.FETCH_SINGLE_CAMERA_ALERT_ERROR,
        error
    }
}
//fetch cleaner alert
export const fetchCleanerAlert =(data)=>{
    return{
        type: alertActionType.FETCH_CLEANER_ALERT,
        data:data
    }
}
export const fetchCleanerAlertSuccess = (data) => {
    return {
        type: alertActionType.FETCH_CLEANER_ALERT_SUCCESS,
        payload: data
    }
}
export const fetchCleanerAlertError = (error) => {
    return {
        type: alertActionType.FETCH_CLEANER_ALERT_ERROR,
        error
    }
}