import { Alert, Box, Chip, CircularProgress, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material"
import { Layout } from "../../layout/Layout"
import { DateTimePicker, DesktopDateTimePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { BorderTransparentButton, StyledCreateSmallButton } from "../../styles/ButtonStyles"
import React, { useEffect, useState } from "react";
import { MenuProps, RouteSwitch } from "../../Styles"
import CancelIcon from "@mui/icons-material/Cancel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useDispatch } from "react-redux"
import { clearUpdateRouteStatus, fetchSingleRoute, updateRoute } from "../../../redux/actions/RouteActions"
import { useSelector } from "react-redux"
import { fetchCheckPointList } from "../../../redux/actions/CheckPointActions"
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DragContainer } from "./drag&drop/DragContainer"
import { GmtToUtc } from "../../convertors/TimeConvertor"
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Kolkata');
export const RouteEdit = () => {
    const dispatch = useDispatch();
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
    const [selectedDays, setSelectedDays] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());
    const [clockStart, setClockStart] = useState(dayjs.utc(new Date()));
    const [clockEnd, setClockEnd] = useState(dayjs.utc(new Date()))
    const [selectedCheckPoints, setSelectedCheckPoints] = useState([]);
    const [checkPoints, setCheckPoints] = useState([]);
    const [isFollow, setIsFollow] = useState(false);
    const [endDateLimit, setEndDateLimit] = useState(null);
    const [alertMessage, setAlertMessage] = useState('')

    const [routeDetails, setRouteDetails] = useState({
        site_id: '',
        routename: "",
        id: 0,
        startime: "",
        endtime: "",
        week_days: [],
        organization_name: "",
        route_type:0,
        check_point_Data: []
    });
    const [validationErrors, setValidationErrors] = useState({
        route_name: '',
        check_point_id: '',
        week_days: '',
        check_point_id: '',
        week_days: '',
        end_time: '',
        start_time: '',
        route_type:''

    })
    const checkPointListData = useSelector(state => state.checkpoints.checkPointList);
    const singleRoute = useSelector(state => state.routes.singleRoute);
    const updateRouteStatus = useSelector(state => state.routes.editedRoute);
    const [endClockTime, setEndClockTime] = useState(null)

    useEffect(() => {
        let id = sessionStorage.getItem('EditRouteId');
        dispatch(fetchSingleRoute(id));

    }, [])
    useEffect(() => {
        if (!singleRoute.isFetching && singleRoute.payload && singleRoute.payload.success === true) {
            const temproute = singleRoute.payload.data;
            let temp = {
                site_id: temproute.site_id,
                routename: temproute.routename,
                is_order: temproute.is_order === 1 ? true : false,
                id: temproute.id,
                route_type:temproute.route_type,
                startime: temproute.startiime,
                endtime: temproute.endtime,
                week_days: temproute.week_days.map((item) => { return item }),
                organization_name: temproute.organization_name,
                check_point_Data: temproute.check_point_Data.map((item) => { return item })
            }

            setIsFollow(temp.is_order)
            setRouteDetails(temp);
            setSelectedDays(temp.week_days);
            let startutc = GmtToUtc(temp.startime);
            let endutc = GmtToUtc(temp.endtime);

            const start_time = dayjs(startutc);

            const end_time = dayjs(endutc);

            setEndDateLimit(start_time);
            setEndClockTime(end_time)
            setClockStart(start_time);
            setClockEnd(end_time);
            const route_type = temp.route_type
            //  let tempSelectedCheckpoints = temproute.check_point_Data.map((item)=>{return item})
            // setSelectedCheckPoints(tempSelectedCheckpoints);
            let data = {
                site_id: temproute.site_id,
                route_type:route_type
            }
            dispatch(fetchCheckPointList(data))
        }
    }, [singleRoute]);
    useEffect(() => {
        if (!checkPointListData.isFetching && checkPointListData.payload && checkPointListData.payload.success === true) {
            const tempCheckPoints = checkPointListData.payload.data;

            const temp = [];
            tempCheckPoints.map((items) => {
                if (items.checkpoint_type !== 1) {
                    temp.push(items)


                }
            })
            let tempSelected = [];
            temp.forEach(item => {
                const isSelected = routeDetails.check_point_Data.some(selected => selected.id === item.id.toString());

                if (isSelected) {
                    tempSelected.push(item);
                }
            });

            setSelectedCheckPoints(tempSelected);
            const filteredCheckpoints = temp.filter(checkpoint =>
                !tempSelected.some(selectedCheckpoint => selectedCheckpoint.id === checkpoint.id)
            );
            if (filteredCheckpoints) {
                setCheckPoints(filteredCheckpoints);
            }
            else {
                setCheckPoints([]);
            }

            // setAllRoutes(filteredCheckpoints);
        }
    }, [checkPointListData])
    useEffect(() => {
        if (!updateRouteStatus.isFetching && updateRouteStatus.payload && updateRouteStatus.payload.success === true) {
            sessionStorage.setItem('trackingTab', 'two')

            window.location.href = '/tracking'
        }
        if (!updateRouteStatus.isFetching && updateRouteStatus.error) {
            const error = updateRouteStatus.errorMessage.message;
            setAlertMessage(error);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearUpdateRouteStatus());

            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [updateRouteStatus]);
    useEffect(() => {

        if (selectedCheckPoints.length) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                check_point_id: '',
            }));
        }
    }, [selectedCheckPoints]);
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        // If "all" is selected, set all days
        if (selectedValue.includes('All')) {
            setSelectedDays(['All']);
        } else {
            // Remove "all" if it was selected before
            setSelectedDays(selectedValue.filter(day => day !== 'All'));
        }
        if (selectedValue.length !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                week_days: ''
            }))
        };
    }
    const handleEndTimeChange = (newTime) => {
        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const tempDate = newTime.$d.toISOString();

        setSelectedEndTime(tempDate);
        let temp = { ...routeDetails };
        temp['endtime'] = tempDate;
        setRouteDetails(temp);
        setEndClockTime(newTime)

        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_time: '',
            }));
        }
        if (newTime < endDateLimit) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_time: 'Invalid End Dates',
            }));
        }
        else {
            const startTimePlus24Hours = dayjs(endDateLimit).add(24, 'hour');
            if (newTime > startTimePlus24Hours) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    end_time: 'Invalid End Date Selection. End date should not be 24 hours greater than start date.',
                }));
            }
        }
    };
    const handleTimeChange = (newTime) => {

        const tempDate = newTime.$d.toISOString();
        setSelectedTime(tempDate);



        let temp = { ...routeDetails };
        temp['startime'] = tempDate;
        setRouteDetails(temp);
        setEndDateLimit(newTime)
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                start_time: '',
            }));
        }
        setEndDateLimit(newTime)
        if (newTime > endClockTime) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_time: 'Invalid End Date',
            }));
        }
        else {
            const startTimePlus24Hours = dayjs(newTime).add(24, 'hour');
            if (endClockTime > startTimePlus24Hours) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    end_time: 'Invalid End Date Selection. End date should not be 24 hours greater than start date.',
                }));
            }
        }
    }
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const onDragEnd = (results) => {
        const { source, destination, type, draggableId } = results;
        if (!destination) return;
        if (destination.droppableId === null) return;
        if (source.droppableId != destination.droppableId) {
            const item = selectedCheckPoints.find(item => item.id == draggableId);
            if (source.droppableId == "allcheckpoint") {
                const items = checkPoints.find(item => item.id == draggableId);
                let sourceItems = [...checkPoints];
                let destinationItems = [...selectedCheckPoints];
                sourceItems.splice(source.index, 1);
                destinationItems.splice(destination.index, 0, { ...items, order: destination.index });

                setSelectedCheckPoints(destinationItems);
                setCheckPoints(sourceItems);
            } else {
                let sourceItems = [...selectedCheckPoints];
                let destinationItems = [...checkPoints];
                sourceItems.splice(source.index, 1);
                destinationItems.splice(destination.index, 0, item);
                setSelectedCheckPoints(sourceItems);
                setCheckPoints(destinationItems);

            }
        }
        else {
            if (type === "group" && source.droppableId == "allcheckpoint") {
                const reorderedCheckPoints = [...checkPoints];

                const checkPointsSourceIndex = source.index;
                const checkPointsDestinatonIndex = destination.index;

                const [removedcheckPoints] = reorderedCheckPoints.splice(checkPointsSourceIndex, 1);
                reorderedCheckPoints.splice(checkPointsDestinatonIndex, 0, removedcheckPoints);

                return setCheckPoints(reorderedCheckPoints);
            }
            if (type === "group" && source.droppableId !== "allcheckpoint") {
                const reorderedCheckPoints = [...selectedCheckPoints];

                const checkPointsSourceIndex = source.index;
                const checkPointsDestinatonIndex = destination.index;

                const [removedcheckPoints] = reorderedCheckPoints.splice(checkPointsSourceIndex, 1);
                reorderedCheckPoints.splice(checkPointsDestinatonIndex, 0, { ...removedcheckPoints, order: checkPointsDestinatonIndex });

                return setSelectedCheckPoints(reorderedCheckPoints);
            }
        }
        if (!destination) return;
    }
    const handleAddCheckpoints = (id) => {
        let temp = [];
        let tempadd = [...selectedCheckPoints];
        checkPoints.map((item) => {
            if (item.id.toString() !== id.toString()) {
                temp.push(item)
            }
            else
                tempadd.push(item)

        })
        setCheckPoints(temp);
        setSelectedCheckPoints(tempadd)
    }
    const handleDeleteCheckPoint = (id) => {
        let temp = [];
        let tempadd = [...checkPoints]
        selectedCheckPoints.map((item) => {
            if (item.id.toString() !== id.toString()) {
                temp.push(item);
            }
            else {
                tempadd.push(item)
            }
        })
        setCheckPoints(tempadd);
        setSelectedCheckPoints(temp);
    }
    const removeAll = () => {
        let temp = [...checkPoints]
        selectedCheckPoints.map((item) => {
            temp.push(item);
        })
        setSelectedCheckPoints([]);
        setCheckPoints(temp);
    }
    const onChangeRoute = (value, key) => {
        let temp = { ...routeDetails };
        temp[key] = value;
        setRouteDetails(temp);
        if (key === 'routename') {
            const valueLength = value.trim().length;
            const nameLengthError =
                valueLength < 2 || valueLength >= 30
                    ? 'Route name must be between 2 and 30 characters long'
                    : '';
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                route_name: nameLengthError,
            }));
        }
    }
    const onUpdate = () => {
        const check_point_id = selectedCheckPoints.map(checkpoint => String(checkpoint.id));
        let id = sessionStorage.getItem('EditRouteId');
        selectedCheckPoints.map(checkpoint => String(checkpoint.id));
        let data = {
            route_name: routeDetails.routename,
            start_time: routeDetails.startime,
            end_time: routeDetails.endtime,
            organization_id: parseInt(sessionStorage.getItem('org_Id')),
            week_days: selectedDays,
            site_id: routeDetails.site_id,
            check_point_id: check_point_id,
            is_order: isFollow ? 1 : 0,
            created_user: parseInt(sessionStorage.getItem('id')),
            route_type: parseInt(routeDetails.route_type)

        }
        const isEmptyField = Object.values(data).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });
        const isemptyArray = !data.week_days.length || !data.check_point_id.length;
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                route_name: routeDetails.routename.trim() === '' ? 'This field is mandatory' : '',

                week_days: selectedDays.length ? '' : 'This field is mandatory',

                check_point_id: check_point_id.length ? '' : 'This field is mandatory'
            }));
        }
        if (isemptyArray) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                check_point_id: check_point_id.length ? '' : 'This field is mandatory',
                week_days: selectedDays.length ? '' : 'This field is mandatory',
            }));
        }
        if (!isemptyArray && !isEmptyField && allClear) {
            setValidationErrors({
                checkPointName: '',
                week_days: '',
                check_point_id: '',
                end_time: '',
                start_time: '',
            });
            dispatch(updateRoute(data, id));
        }
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                isCreate={true}
                activeSubtab={"two"}

            >
                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handlealertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handlealertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {updateRouteStatus.isFetching || singleRoute.isFetching?
                    <Box
                        sx={{ display: 'flex', justifyContent: 'center',mt:10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Box>
                    :
                    <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >

                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Route Name</InputLabel>
                            <TextField
                                className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                                placeholder="Create route name"
                                value={routeDetails.routename}
                                onChange={(e) => onChangeRoute(e.target.value, 'routename')}
                                error={!!validationErrors.route_name}
                                helperText={validationErrors.route_name}
                            />
                        </Grid>
                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                Sites</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                value={routeDetails.site_id}
                                disabled
                                sx={{ backgroundColor: '#d3d3d361' }}

                            >
                                <MenuItem
                                    value={routeDetails.site_id}>
                                    {routeDetails.organization_name}
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Type</InputLabel>
                            <Select
                            disabled
                                className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                value={routeDetails.route_type}
                                onChange={(e) => onChangeRoute(e.target.value, 'route_type')}
                                error={!!validationErrors.route_type}
                                helperText={validationErrors.route_type}
                                sx={{ backgroundColor: '#d3d3d361' }}

                            >

                                <MenuItem
                                    value={2}>
                                    Patrol
                                </MenuItem>
                                <MenuItem
                                    value={4}>
                                    Cleaner
                                </MenuItem>
                            </Select>
                            {validationErrors.route_type && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.route_type}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Days</InputLabel>

                            <Select
                                className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                multiple
                                value={selectedDays}
                                onChange={handleChange}
                                renderValue={(selected) => (
                                    <Stack gap={1} direction="row" flexWrap="wrap">
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                onDelete={() =>
                                                    setSelectedDays(
                                                        selectedDays.filter((item) => item !== value)
                                                    )
                                                }
                                                deleteIcon={
                                                    <CancelIcon
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                            />
                                        ))}
                                    </Stack>
                                )}
                                style={{ marginBottom: '10px' }}
                                MenuProps={MenuProps}
                                error={!!validationErrors.week_days}
                            >
                                {['All', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                                    <MenuItem key={index} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                            {validationErrors.week_days && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.week_days}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <Grid container>
                                <Grid item xs={10} sm={10} lg={5.8} md={5.8}>
                                    <InputLabel
                                        className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                        Start Time</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} className="datetime-container" >
                                        <DemoContainer
                                            className="datetime-container"
                                            sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                            <DesktopDateTimePicker
                                                // className="timepicker"
                                                slotProps={{
                                                    textField: {
                                                        helperText: validationErrors.start_time,
                                                        error: validationErrors.start_time !== '' ? true : false
                                                    },

                                                }}

                                                value={clockStart}
                                                onChange={(value) => handleTimeChange(value)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={10} sm={10} lg={5} md={5} sx={{ ml: -2.6, '@media (max-width: 600px)': { ml: 0, mt: 3 } }} >
                                    <InputLabel
                                        className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                        End Time</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                            <DesktopDateTimePicker
                                                slotProps={{
                                                    textField: {
                                                        helperText: validationErrors.end_time,
                                                        error: validationErrors.end_time !== '' ? true : false
                                                    },

                                                }}
                                                // minDateTime={clockStart}
                                                value={clockEnd}
                                                onChange={(value) => handleEndTimeChange(value)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={11.2} md={11.2} sx={{ mt: 3 }}
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                        >
                            <div className="drag_box">
                                <Grid container>
                                    <DragContainer
                                        onDragEnd={onDragEnd}
                                        checkPoints={checkPoints}
                                        isDarkMode={isDarkMode}
                                        handleAddCheckpoints={handleAddCheckpoints}
                                        selectedCheckPoints={selectedCheckPoints}
                                        handleDeleteCheckPoint={handleDeleteCheckPoint}
                                        validationErrors={validationErrors}
                                        isOrder={isFollow}
                                    />
                                    {selectedCheckPoints.length ?
                                        <Grid container lg={12} md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Grid item sx={{ mr: '13%' }}>
                                                <BorderTransparentButton
                                                    onClick={removeAll}
                                                >
                                                    Remove All
                                                </BorderTransparentButton>
                                            </Grid>
                                        </Grid> : <></>
                                    }
                                </Grid>
                            </div>
                        </Grid>
{routeDetails.route_type != 4 ?
                        <Grid item xs={10} sm={10} lg={12} md={12} sx={{ mt: 3 }} className="route_switch">
                            <Typography className="modalhead">
                                Strictly follow order

                            </Typography>
                            <RouteSwitch
                                checked={isFollow}
                                onChange={() => setIsFollow(!isFollow)}
                            />
                        </Grid>:<></>
                        }
                        <Grid item>
                            <StyledCreateSmallButton
                                onClick={onUpdate}
                                sx={{ mt: 5, width: 150 }} variant="contained"
                                disableRipple>
                                Update
                            </StyledCreateSmallButton>
                        </Grid>
                    </Grid >
                }
            </Layout >
        </>
    )
}