import { Alert, Grid, Typography } from '@mui/material';
import React from 'react';
import { alertTimeOnly } from '../../../../convertors/TimeConvertor';
export const CleanerRouteDetails = ({ historyData }) => {


    return (
        <div className='device_view reducepb' >
            <Grid container >
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Typography className='device_view_text label_color rowdiv_label' sx={{ pb: '16% !important', '@media (max-width: 600px)': { pb: "5px !important" } }}>
                        Route
                    </Typography>
                    <Typography className='device_view_text content_color rowdiv_text' sx={{ pl: '2%', '@media (max-width: 600px)': { pb: "10px !important" } }}>
                   {historyData.routename}
                    </Typography>
                </Grid>
                <Grid item lg={7} md={7} sm={12}>
                    <Typography className='device_view_text label_color rowdiv_label' sx={{ pb: '6% !important', '@media (max-width: 600px)': { pb: "5px !important" } }}>
                        Checkpoints
                    </Typography>

                    <div className="cleanercheckpointbox">
                        {historyData.checkpoints?.map((checkpoint, index) => (
                            <>
                                <span className={checkpoint.firstseenat == "" ? "alert_stage_text default_text "
                                    : "alert_stage_text valid_text"} key={index}>
                                        {checkpoint.checkpoint}
                                        <br />

                                    {checkpoint.firstseenat != 0 || checkpoint.firstseenat != '' ? ` (${alertTimeOnly(checkpoint.firstseenat)})` : ""}
                                    {checkpoint.lastseenat != 0 || checkpoint.lastseenat != ''  ? ` (${alertTimeOnly(checkpoint.lastseenat)})` : ""}
                                </span>
                            </>
                        ))}

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}