import { Grid, Typography } from '@mui/material';
import React from 'react';
export const PersonnelDetails = ({ personnelData }) => {
    return (
        <div className='device_view'>
            <Grid container >
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'>Name</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {personnelData.contractorname}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml'>
                        <Typography className='device_view_text label_color rowdiv_label'>
                        {personnelData.user_type == 1 ? 'Schedule' : 'Route'}

                        </Typography>
                        <Typography className=' rowdiv_text'>
                            {(!personnelData.route_Data?.length && !personnelData.check_point_Data?.length) ? "- -" :
                                personnelData.route_Data?.length ?
                                    <div className="route_table_div">
                                        {personnelData.route_Data.map((obj, index) =>
                                            <span className="route_text_div" key={index}>{obj.routename}{'  '}</span>
                                        )}
                                    </div> : <div className="route_table_div">
                                        {personnelData.check_point_Data.map((obj, index) =>
                                            <span className="route_text_div" key={index}>{obj.check_point_name}{'  '}</span>
                                        )}
                                    </div>

                            }
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom  rowdiv_mr'>
                        <Typography className='device_view_text label_color rowdiv_label'>Mail Id</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {personnelData.email}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml'>
                        <Typography className='device_view_text label_color rowdiv_label'>Site</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {personnelData.organization_name}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'>
                        <Typography className='device_view_text label_color rowdiv_label'>Phone No</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {personnelData.phone}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml'>
                        <Typography className='device_view_text label_color rowdiv_label'>Duty Type</Typography>
                        <Typography className='rowdiv_text'>
                            {personnelData.duty_type === 0 ? "--" : personnelData.duty_type === 1 ? <span className="route_text_div">Sentry</span>
                                : personnelData.duty_type != 4 ? <span className="route_text_div">Patrol</span>
                                : <span className="route_text_div">Cleaner</span>
                                }
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv rowdiv_mr'>
                        <Typography className='device_view_text label_color rowdiv_label'>Employee Id</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {personnelData.emp_id}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv rowdiv_ml'>
                        <Typography className='device_view_text label_color rowdiv_label'>Tag </Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {personnelData.infant_name}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}