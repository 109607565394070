import React, { useEffect, useState } from "react";
import TabContext from '@mui/lab/TabContext';
import { Tables } from "../sharable/tables/Table";
import { Grid, Pagination, ThemeProvider, Typography } from "@mui/material";
import { TrackingListData, TrackingListHead } from "../../assets/data/data";
import { darkTheme, lightTheme } from "../Styles";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { subDays } from "date-fns";
import { fetchUser } from "../../redux/actions/UserActions";
import { fetchUserRole } from "../../redux/actions/UserRoleActions";
import { TrackingTab } from "./TrackingTab";
import TabPanel from '@mui/lab/TabPanel';
import { CheckPointList } from "./checkPoint/CheckPointList";
import { RouteList } from "./route/RouteList";
import { DeviceHome } from "./devices/DeviceHome";
import { PersonnelList } from "./personnel/PersonnelList";
import { MapView } from "../mapView/Map";
import { RssiEdit } from "./settings/RssiEdit";


export const TrackingList = ({ }) => {
  const [isDarkMode, setDarkMode] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(subDays(new Date(), 5));
  const [tabValue, setTabValue] = React.useState('one');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deviceTabListValue, setDeviceTabListValue] = useState('tag');

  useEffect(() => {
    // sessionStorage.setItem('trackingDeviceTab', 'tag')

    //sessionStorage.setItem('trackingDeviceTab', deviceTabListValue)


    const tabId = sessionStorage.getItem('trackingTab');
    if (tabId != '' && tabId !== null) {
      setTabValue(tabId)
    //  setDeviceTabListValue('tag');
      sessionStorage.setItem('trackingDeviceTab',deviceTabListValue)

    }


    const selectedDevice = sessionStorage.getItem('trackingDeviceTab')
    if (tabId === 'four') {
      if (selectedDevice !== '' || selectedDevice !== null || selectedDevice !== undefined) {
        setDeviceTabListValue(selectedDevice);
      }
      else {
        sessionStorage.setItem('trackingTab', 'one')

      }
    }
    // if (tabId === 'four' && selectedDevice ===null){
    //   setDeviceTabListValue('tag');
    // }
  }, [])


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (status, newValue) => {

    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  }
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);

  }
  const handleCreate = () => {
    window.location.href = "/organisation/create"
  }
  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
    // setAnchorEl(null);
    sessionStorage.setItem('trackingTab', newValue)

  }
  const handleSubTabChange = (newValue) => {
    setAnchorEl(null);
  }
  const handleTabListValues = (value) => {
    // sessionStorage.setItem('trackingTab','four');
     sessionStorage.setItem('trackingDeviceTab',value)
    setDeviceTabListValue(value)
    const tabId = sessionStorage.getItem('trackingTab');
  }
  return (
    <Layout
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      handleSubTabChange={handleSubTabChange}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"tracking"}
      activeMenuKey={"tracking"}

    >
      <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
        <Grid container spacing={1} sx={{ pt: 3, mb: 5, ml:{ lg:2,md:2,sm:0,xs:0 }}}


        //className={isDarkMode ? "chartbg_dark " : "chartbg_light "} 
        >
          <TabContext value={tabValue}>
            <TrackingTab
              anchorEl={anchorEl}
              handleMenuOpen={handleMenuOpen}
              handleMenuClose={handleMenuClose}
              isDarkMode={isDarkMode}
              handleTabChange={handleTabChange}
              tabValue={tabValue}
              deviceTabListValue={deviceTabListValue}

            />
            <TabPanel value="one" index="one" sx={{ width: "100% !important" }}>
              <CheckPointList isDarkMode={isDarkMode} />
            </TabPanel>
            <TabPanel value="two" index="two" sx={{ width: "100% !important" }}>
              <RouteList isDarkMode={isDarkMode} />
            </TabPanel>

            <TabPanel value="three" index="three" sx={{ width: "100% !important" }}>
              <PersonnelList isDarkMode={isDarkMode} />
            </TabPanel>
            <TabPanel value="four" index="four" sx={{ width: "100% !important" }}>
              <DeviceHome
                isDarkMode={isDarkMode}
                deviceTabListValue={deviceTabListValue}
                handleTabListValues={handleTabListValues}
              />

            </TabPanel>
            <TabPanel value="five" index="five" sx={{ width: "100% !important" }}>
              <MapView></MapView>
            </TabPanel>
            <TabPanel value="six" index="six" sx={{ width: "100% !important" }}>
              <RssiEdit />
            </TabPanel>
          </TabContext>
        </Grid>
      </div>
    </Layout>
  )
}