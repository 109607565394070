import { Alert, CircularProgress, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CameraFilter } from "./CameraFilter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { fetchBlock, fetchLevel } from "../../../redux/actions/SharableActions";
import { CameraListTable } from "./CameraListTable";
import { fetchSurveillanceDeviceList } from "../../../redux/actions/SurveillanceAction";
import { StyledCreateButton } from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { dateSearchConverter } from "../../convertors/TimeConvertor";
import { UnAssignedCameraListTable } from "./UnAssignedCameraListTable";
import { DeviceImportModal } from "../../Modals/DeviceImportModal";
import * as XLSX from "xlsx";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

export const CameraList = ({ selectedEndDate, selectedStartDate, isWidget, removeWidget, id }) => {
  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const LevelData = useSelector((state) => state.sharables.LayerList);
  const BlockData = useSelector((state) => state.sharables.BlockList);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageCount, setPrevPageCount] = useState(0);
  const [prevCurrentPage, setPrevCurrentPage] = useState(1);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [dataFromExcel, setDataFromExcel] = useState([]);
  const [snackMessage, setSnackMessage] = useState('');

  const itemsPerPage = 20;
  const vmsDeviceList = useSelector(
    (state) => state.surveillance.vmsDeviceList
  );

  const [sites, setSites] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  const [search, setSearch] = useState("");
  const [unassignedCameraList, setUnassignedCameraList] = useState([]);

  const [isAssigned, setIsAssigned] = useState(0);
  const [selectedSites, setSelectedSites] = useState("");
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState("");

  const [showSnack, setShowSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { open, vertical, horizontal } = showSnack;
  useEffect(() => {
    dispatch(fetchSites());
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
      const siteId = sessionStorage.getItem("user_site_id");
      // setSelectedSites(siteId)
      if (siteId != 0) {
        setSelectedSites(siteId);
      }
    }
    if (
      !LevelData.isFetching &&
      LevelData.payload &&
      LevelData.payload.success === true
    ) {
      const levelList = LevelData.payload.data.LevelList;
      setLevels(levelList);
    }
    if (
      !BlockData.isFetching &&
      BlockData.payload &&
      BlockData.payload.success === true
    ) {
      const BlockList = BlockData.payload.data.BlockList;
      setBlocks(BlockList);
    }
    if (
      !vmsDeviceList.isFetching &&
      vmsDeviceList.payload &&
      vmsDeviceList.payload.success === true
    ) {
      const tempvmsList = vmsDeviceList.payload.data;
      const tempPagination = vmsDeviceList.payload.pagination;

      if (tempvmsList.length && tempvmsList[0]?.is_assigned !== 0) {
        setCameraList(tempvmsList);
        setPageCount(tempPagination.total_pages);
      } else if (tempvmsList.length && tempvmsList[0]?.is_assigned == 0) {
        setUnassignedCameraList(tempvmsList);
        setPageCount(tempPagination.total_pages);
      } else {
        setCameraList(tempvmsList);
        setUnassignedCameraList(tempvmsList);
      }
    }
  }, [siteData, LevelData, BlockData, vmsDeviceList]);
  useEffect(() => {
    dispatch(fetchLevel(selectedSites));
    if (selectedSites && selectedLevel) {
      const data = {
        siteId: selectedSites,
        levelId: selectedLevel,
      };
      dispatch(fetchBlock(data));
    }
  }, [selectedSites, selectedLevel]);
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;

    const data = {
      site_id: selectedSites,
      is_assigned: isAssigned,

      start: start,
      limit: limit,
      searchWord: search,
      to_date: selectedEndDate != undefined ? dateSearchConverter(selectedEndDate) : "",
      from_date: selectedStartDate != undefined ? dateSearchConverter(selectedStartDate) : "",
    };
    dispatch(fetchSurveillanceDeviceList(data));
  }, [
    selectedSites,
    selectedLevel,
    selectedBlocks,
    isAssigned,
    selectedStartDate,
    selectedEndDate,
    search,
    currentPage
  ]);
  const handleAssignAssets = (value) => {
    setIsAssigned(value);
  };
  const handleSites = (value) => {
    setSelectedSites(value);
  };
  const handleLevels = (value) => {
    setSelectedLevel(value);
  };
  const handleBlocks = (value) => {
    setSelectedBlocks(value);
  };
  const singleView = (id) => {
    // <Route path='/surveillance/camera/stream' element={<Stream />} />
    // window.location.href ='/surveillance/camera/stream';
    window.location.href = "/surveillance/view";
  };
  const handleEncoder = (id) => {
    window.location.href = `/surveillance/encoder/view/${id}`;
  };
  const handlePreviousHistory = (id) => {
    window.location.href = `/surveillance/encoder/previous-history/${id}`;
  };
  const handleAddDevice = () => {
    window.location.href = "/surveillance/encoder/add";
  };
  const onChangeSearch = (value) => {
    setSearch(value);
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChooseLocation = (id) => {
    // <Route path='/surveillance/camera/stream' element={<Stream />} />
    // window.location.href ='/surveillance/camera/stream';
    window.location.href = "/surveillance/view";
  };
  const CloseImportModal = () => {
    setImportModalOpen(false);
  };
  const handleImportModal = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setImportModalOpen(false);
      return;
    }
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    // const reader = new FileReader();
    //  reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary", cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Extract headers from the first row of the Excel sheet
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        const cellValue = sheet[cellAddress] ? sheet[cellAddress].v : "";
        headers.push(cellValue);
      }
      const requiredColumns = [
        "Title",
        "Stream Id",
        "Sensor Id",

        "Sensor Name",
        "Battery Id",
        "Battery Name",
        "Distance 1",
        "Distance 2",
        "Iot Device DID"
      ];
      const missingColumns = requiredColumns.filter(
        (column) => !headers.includes(column)
      );

      if (missingColumns.length > 0) {
        let alert = `Missing columns: ${missingColumns.join(", ")}`;
        setSnackMessage(alert);
        setShowSnack({ ...showSnack, open: true });
      } else {
        const dateOptions = {
          header: 1,
          cellDates: true,
        };
        const parsedData = XLSX.utils.sheet_to_json(
          sheet,
          { defval: "" },
          dateOptions
        );

        let hasIssues = false;

        parsedData.forEach((row) => {
          if (hasIssues) return;

          if (
            row["Title"].trim() == ""

          ) {
            // let enddate = row["End Date & Time"];
            hasIssues = true;
            if (hasIssues) {
              let alert = ` Title column Cann't be empty : ${JSON.stringify(
                row
              )}`;
              setSnackMessage(alert);
              setShowSnack({ ...showSnack, open: true });
              return;
            }
          }
          if (
            row["Stream Id"].trim() == ""

          ) {
            // let enddate = row["End Date & Time"];
            hasIssues = true;
            if (hasIssues) {
              let alert = ` Stream Id column Cann't be empty : ${JSON.stringify(
                row
              )}`;
              setSnackMessage(alert);
              setShowSnack({ ...showSnack, open: true });
              return;
            }
          }


        });

        if (!hasIssues && showSnack.open == false) {
          setDataFromExcel(parsedData);
          if (parsedData.length) {
            setImportModalOpen(true);
          }
        } else {
          setImportModalOpen(false);
        }
      }
    };
  };
  const closeSnack = () => {
    setShowSnack({ ...showSnack, open: false });
  };
  const CloseSuccessImportModal = () => {
    setImportModalOpen(false);
    sessionStorage.setItem('surveillanceTab', 's1')
    // window.location.reload();

    window.location.href = '/surveillance';

  }
  const handleDeviceDelete = (id) => {
    let temp = [...dataFromExcel];
    let filteredData = [];
    temp.map((item, index) => {
      if (item["Title"] != id) {
        filteredData.push(item)
      }
    })
    setDataFromExcel(filteredData);
  }
  return (
    <>
      <Grid container>
        {
          isWidget &&
          <>
            <Grid
              item
              lg={10}
              sm={10}
              md={10}
              xs={10}
              style={{ display: "flex", justifyContent: "flex-start" }}
            // sx={{ ml: -2 }}
            > <Typography className="headersubhead light_text htl">
                Camera List
              </Typography>
            </Grid>

            <Grid
              item
              lg={2}
              sm={2}
              md={2}
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
              sx={{ ml: -2 }}
            >
              <IconButton
                aria-label="settings"
                onClick={() => {
                  removeWidget(id);
                }}
              >
                <IndeterminateCheckBoxOutlinedIcon
                  style={{ color: "#FFAC0B" }}
                />
              </IconButton>
            </Grid>
          </>
        }
        <CameraFilter
          isAssigned={isAssigned}
          handleAssignAssets={handleAssignAssets}
          selectedSites={selectedSites}
          handleSites={handleSites}
          sites={sites}
          levels={levels}
          selectedLevel={selectedLevel}
          handleLevels={handleLevels}
          blocks={blocks}
          selectedBlocks={selectedBlocks}
          handleBlocks={handleBlocks}
          search={search}
          onChangeSearch={onChangeSearch}
          handleAddDevice={handleAddDevice}
          handleImportModal={handleImportModal}
          isWidget={isWidget}
          removeWidget={removeWidget}
          id={id}
        />
        {vmsDeviceList.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <Grid item lg={12} sm={12} md={12} xs={12}>
            {isAssigned ? (
              cameraList.length && cameraList != undefined ? (
                <CameraListTable
                  cameraListData={cameraList}
                  singleView={handleEncoder}
                  pageCount={pageCount}
                  page={currentPage}
                  onChange={handlePageChange}
                  handlePreviousHistory={handlePreviousHistory}
                />
              ) : (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={6}
                  xs={6}
                  className="mt2"
                  sx={{ ml: 3, mt: 6 }}
                >
                  <Typography>No Cameras found to Show </Typography>
                </Grid>
              )
            ) : unassignedCameraList.length &&
              unassignedCameraList != undefined ? (
              <UnAssignedCameraListTable
                cameraListData={unassignedCameraList}
                singleView={handleChooseLocation}
                encoderView={handleEncoder}
                pageCount={pageCount}
                page={currentPage}
                onChange={handlePageChange}
                handlePreviousHistory={handlePreviousHistory}

              />
            ) : (
              <Grid
                item
                lg={12}
                md={12}
                sm={6}
                xs={6}
                className="mt2"
                sx={{ ml: 3, mt: 6 }}
              >
                <Typography>No Cameras found to Show </Typography>
              </Grid>
            )}
          </Grid>
        }
      </Grid>
      <DeviceImportModal
        importModalOpen={importModalOpen}
        CloseImportModal={CloseImportModal}
        dataFromExcel={dataFromExcel}
        CloseSuccessImportModal={CloseSuccessImportModal}
        handleDeviceDelete={handleDeviceDelete}
      />
      {showSnack ?
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={closeSnack}
          autoHideDuration={10000}
        //  key={vertical + horizontal}
        >
          <Alert
            onClose={closeSnack}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackMessage}
          </Alert>
        </Snackbar> : <></>
      }
    </>
  );
};
