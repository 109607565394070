import React, { useState, useEffect } from "react";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import { Tables } from "../sharable/tables/Table";
import { MenuProps } from '../Styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSites } from '../../redux/actions/UserRoleActions';
import { fetchDevice, fetchTag, fetchBridgeDevice } from '../../redux/actions/DeviceActions';
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { getDevices } from "../../services/DeviceServices";

export const Hardwares = ({ isDarkMode, removeWidget, id, type, typeLabel }) => {
    const dispatch = useDispatch();
    let hardwareTableHead = [
        { id: "name", label: "Name" },
        { id: "ip_address", label: "IP Address" },
        { id: "lastSeen", label: "Last Seen" },
    ];
    let listenerTableHead = [
        { id: "name", label: "Name" },
        { id: "deviceuuid", label: "Device GUID" },
        { id: "lastSeen", label: "Last Seen" },
    ];
    let tagTableHead = [
        { id: "infant_name", label: "Name" },
        { id: "tag_name", label: "Tag" },
        { id: "block_name", label: "Room" },
    ];

    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const siteData = useSelector(state => state.userRole.siteData);
    const deviceList = useSelector(state => state.device.deviceList);
    const deviceListBridge = useSelector(state => state.device.deviceListBridge);
    const tagList = useSelector(state => state.device.tagList);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTagPage, setCurrentTagPage] = useState(1);
    const itemsPerPage = 10;
    const [hardwareTagsData, setHardwareTagsData] = useState([]);
    const [hardwareBridgeData, setHardwareBrigesData] = useState([]);
    const [hardwareListenersData, setHardwareListenersData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [tagPageCount, setTagPageCount] = useState(0);
    const [sortMenuOpen,setSortMenuOpen] = useState(false);
    const [sortValue,setSortValue] = useState('');
    useEffect(() => {
        dispatch(fetchSites());
        
    }, []);

    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        if (type === 'listeners') {
            let data = {
                start: start,
                limit: limit,
                type: 11001,
                site_id: selectedSites,
                sort:sortValue,
                org_id:parseInt(sessionStorage.getItem('org_Id'))
            }
            dispatch(fetchDevice(data))
        }
        if (type === 'bridges') {
            fetchBridgeDevices({
                start: start,
                limit: limit,
                type: 13012,
                site_id: selectedSites,
                sort:sortValue,
                org_id:parseInt(sessionStorage.getItem('org_Id'))
            })
           // dispatch(fetchDevice(data))

        }
        if (type === 'tags') {
            const start = (currentTagPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let checkoutValue = 0;
            let transferValue = 0;
           let data ={
                start: start,
                limit: limit,
                site_id: selectedSites,
                checkout: checkoutValue,
                transfer: transferValue,
                org_id:parseInt(sessionStorage.getItem('org_Id'))
            }
             dispatch(fetchTag(data));
        }
}, [currentPage, currentTagPage, dispatch, selectedSites, type,sortValue])

    const fetchBridgeDevices = async (data) => {
        try {
            const resp = await getDevices(data);
            if (resp.data && resp.data.length > 0) {
                const temp = resp.data.map((devices) => {
                    const timestamp = devices.last_reported_time * 1000;
                    const lastSeen = new Date(timestamp).toLocaleString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    });
                    return {
                        id: devices.id,
                        name: devices.tag_name,
                        deviceuuid: devices.deviceuuid,
                        lastSeen: devices.last_reported_time == 0 || devices.last_reported_time == null ? "NA" : lastSeen,
                        ip_address: devices.ip_address,
                    }
                })
                setHardwareBrigesData(temp)
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        if (!deviceList.isFetching && deviceList.payload && deviceList.payload.success === true) {
            const tempDeviceList = deviceList.payload.data;
            const tempPaginationData = deviceList.payload.pagination;
            const temp = tempDeviceList.map((devices) => {
                const timestamp = devices.last_reported_time * 1000;
                const lastSeen = new Date(timestamp).toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                });
                return {
                    id: devices.id,
                    name: devices.tag_name,
                    deviceuuid: devices.deviceuuid,
                    lastSeen: devices.last_reported_time == 0 || devices.last_reported_time == null ? "NA" : lastSeen,
                    ip_address: devices.ip_address,
                }
            })
            setHardwareListenersData(temp)
            setPageCount(tempPaginationData.total_pages)
        }
        if (!tagList.isFetching && tagList.payload && tagList.payload.success === true) {
            const tempDeviceList = tagList.payload.data;
            const tempPaginationData = tagList.payload.pagination;
            const temp = tempDeviceList.map((devices) => {
                return {
                    infant_id: devices.infant_id,
                    infant_name: devices.infant_name,
                    tag_name: devices.tag_name,
                    asset_type: devices.asset_type === 0 ? 'Normal' : 'Invalid',
                    checkoutstatus: devices.checkoutstatus === 0 ? 'No' : 'Yes',
                    transfer_requested: devices.transfer_requested === 0 ? 'No' : 'Yes',
                    ip_address: devices.ip_address,
                    block_name: devices.block_name
                }
            })
            setHardwareTagsData(temp)
            setTagPageCount(tempPaginationData.total_pages)
        }
    }, [deviceList, tagList]);


    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp);
            const siteId = sessionStorage.getItem('user_site_id');
             if(siteId != 0){
                setSelectedSites(siteId)
            }
        }
    }, [siteData]);

    const handleSites = (value) => {
        setSelectedSites(value);
    }
    const handleSortMenu =()=>{
        setSortMenuOpen(!sortMenuOpen);
    }
    const closeSortMenu =()=>{
        setSortMenuOpen(false);
    }
    const handleSortValue =(value)=>{
        setSortValue(value);
        setSortMenuOpen(false);

    }
    return (
        <>
            <Grid container 
           // sx={{ mt: 1 }} columnSpacing={2}
            >
                <>
                <Grid container sx={{ mt: 2 }}>
                        <Grid item lg={10} md={10} sm={10} xs={10} className="mtdash2" >
                            <Typography className='headersubhead light_text htl'>  {typeLabel}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                                <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                            </IconButton>
                        </Grid>
                        <Grid item lg={6} sm={12} xs={12} md={6}
                            style={{ display: 'flex', justifyContent: 'flex-start' }}
                            sx={{mt:2}}>
                            <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
                                style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Grid item sx={{ mt: 2 }}>
                                    <Typography sx={{ textAlign: 'center' }}
                                        className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                        Sites :
                                    </Typography>

                                </Grid>
                                <Grid item lg={8} sm={9} xs={9} md={8}>
                                    <Select
                                        //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                        sx={{
                                            color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                            textAlign: 'left',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                                border: '',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: isDarkMode ? "white !important" : "#161616",
                                            }
                                        }}

                                        displayEmpty
                                        MenuProps={MenuProps}
                                        className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                        value={selectedSites}
                                        onChange={(e) => setSelectedSites(e.target.value)}
                                    >   <MenuItem value={''}>All</MenuItem>
                                        {sites.map((site, index) => (
                                            <MenuItem
                                                key={index}
                                                value={site.organizationId}>
                                                {site.organizationName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </Grid>

                            </Grid>
                        </Grid>
                      
                    </Grid>
                </>
                {/* <Grid item>
                    <Typography sx={{ textAlign: 'center' }}
                        className={isDarkMode ? "chartheading_color" : "light_text"}>
                        {typeLabel}
                    </Typography>
                </Grid> */}
                {/* <Grid item>
                    <Typography sx={{ textAlign: 'center' }}
                        className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                        Sites :
                    </Typography>
                </Grid>
                <Grid item lg={3} sm={3} xs={3} md={3}>
                    <Select
                        sx={{
                            color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 180,
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(233, 233, 233, 1)',
                                border: '',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(233, 233, 233, 1)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(233, 233, 233, 1)',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: isDarkMode ? "white !important" : "#161616",
                            }
                        }}

                        displayEmpty
                        MenuProps={MenuProps}
                        className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                        value={selectedSites}
                        onChange={(e) => handleSites(e.target.value)}
                    >   <MenuItem value={''}>All</MenuItem>
                        {sites.map((site, index) => (
                            <MenuItem
                                key={index}
                                value={site.organizationId}>
                                {site.organizationName}
                            </MenuItem>
                        ))}


                    </Select>
                </Grid>
                <Grid item xs={6} sm={6} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                        <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                    </IconButton>
                </Grid> */}
            </Grid>
            <Grid lg={12} sm={12} xs={12}>
                {
                    type === 'tags' &&
                    <Tables
                        isDarkMode={isDarkMode}
                        column={tagTableHead}
                        row={hardwareTagsData}
                        view={false}
                        buttonName={"View More"}
                        actionName={"View"}
                        emptyString={"No Tags Found"}
                    />
                }
                {
                    type === 'bridges' &&
                    <Tables
                        isDarkMode={isDarkMode}
                        column={hardwareTableHead}
                        row={hardwareBridgeData}
                        view={false}
                        buttonName={"View More"}
                        actionName={"View"}
                        closeSortMenu={closeSortMenu}
                        handleSortMenu={handleSortMenu}
                        sortMenuOpen={sortMenuOpen}
                        handleSortValue={handleSortValue}
                                                emptyString={"No Bridges Found"}

                    />
                }
                {
                    type === 'listeners' &&
                    <Tables
                        isDarkMode={isDarkMode}
                        column={listenerTableHead}
                        row={hardwareListenersData}
                        view={false}
                        buttonName={"View More"}
                        actionName={"View"}
                        closeSortMenu={closeSortMenu}
                        handleSortMenu={handleSortMenu}
                        sortMenuOpen={sortMenuOpen}
                        handleSortValue={handleSortValue}
                        emptyString={"No Listeners Found"}

                    />
                }

            </Grid>
        </>
    )
}