import { Alert, Box, CircularProgress, IconButton, Snackbar, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../styles/TablebButtonStyles";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { clearDeleteRouteStatus, deleteRoute, fetchRoute } from "../../../redux/actions/RouteActions";
import { Paginations } from "../../sharable/pagination/Pagination";
import { arrayChecker } from "../../helpers/RouteHelper";
import { DeleteModal } from "../../Modals/DeleteModal";
import dayjs from "dayjs";
import { GmtToLocalTime, routeListTimeFetch } from "../../convertors/TimeConvertor";
export const RouteListTable = ({ isDarkMode, selectedSites ,selectedType}) => {
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [routeList, setRouteList] = useState([]);
    const [deleteRouteName, setDeleteRouteName] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')
    const itemsPerPage = 20;
    const routeListData = useSelector(state => state.routes.routeList);
    const deleteRouteStatus = useSelector(state => state.routes.deletedRoute);

    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            start: start,
            limit: limit,
            site_id: selectedSites,
            route_type:selectedType
        }
        dispatch(fetchRoute(data));
    }, [selectedSites,selectedType,currentPage])

    useEffect(() => {
        if (!routeListData.isFetching && routeListData.payload && routeListData.payload.success === true) {
            const routes = routeListData.payload.data;
            const tempPaginationData = routeListData.payload.pagination;
            const tempRoutes = routes.map((item) => {
                return {

                    id: item.id,
                    routename: item.routename,
                    sitename: item.organization_name,
                    starttime: GmtToLocalTime(item.startiime),
                    endtime: GmtToLocalTime(item.endtime),

                    days: arrayChecker(item.week_days),
                    check_point_Data: item.check_point_Data,
                }
            })
            setRouteList(tempRoutes);
            setPageCount(tempPaginationData.total_pages)

        }
    }, [routeListData]);

    useEffect(() => {
        if (!deleteRouteStatus.isFetching && deleteRouteStatus.payload && deleteRouteStatus.payload.success === true) {
            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {
                start: start,
                limit: limit
            }
            dispatch(fetchRoute(data));
            setOpenDeleteModal(false);
        }
        if (!deleteRouteStatus.isFetching && deleteRouteStatus.error) {
            const error = deleteRouteStatus.errorMessage.message;
            setAlertMessage(error);
            setOpenDeleteModal(false);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearDeleteRouteStatus())

            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }
        , [deleteRouteStatus])
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleDelete = (id, name) => {
        sessionStorage.setItem('deleteRouteId', id);
        setDeleteRouteName(name);
        setOpenDeleteModal(true);
    }
    const handleDeleteModal = () => {
        setOpenDeleteModal(false);
    }
    const onDeleteConfirm = () => {
        //   const id = sessionStorage.getItem('deleteRouteId');
        const data = {
            created_user: parseInt(sessionStorage.getItem('id')),
            id: sessionStorage.getItem('deleteRouteId'),
            org_id: parseInt(sessionStorage.getItem('org_Id')),


        }
        dispatch(deleteRoute(data))
    }
    const handleEdit = (id) => {
        sessionStorage.setItem('EditRouteId', id);
        window.location.href = '/tracking/route/edit';
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (

        <div className='table_Scroll '>
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <DeleteModal
                handleDeleteModal={handleDeleteModal}
                openDeleteModal={openDeleteModal}
                ModalHead={'Delete Route'}
                ModalBody={deleteRouteName}
                onDeleteConfirm={onDeleteConfirm}
            />
            {routeListData.isFetching ?
                <Box
                    sx={{ display: 'flex', justifyContent: 'center' ,mt:10}}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Box>
                :
                routeList.length && routeList !== 'undefined' ?
                    <>
                        <Table className="table_mob" sx={{ mt: 2, mb: 2, width: "100%" }} >
                            <TableHead>
                                <TableRow>
                                    <CustomizedTableCell>Route Name</CustomizedTableCell>
                                    <CustomizedTableCell>Sites</CustomizedTableCell>
                                    <CustomizedTableCell>Check Points</CustomizedTableCell>
                                    <CustomizedTableCell>Days</CustomizedTableCell>
                                    <CustomizedTableCell>Start Time</CustomizedTableCell>
                                    <CustomizedTableCell>End Time</CustomizedTableCell>
                                    <CustomizedTableCell >Actions</CustomizedTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {routeList.map((item, index) => (
                                    <CustomizedTableRow key={index}>
                                        <CustomizedTableCell>{item.routename}</CustomizedTableCell>
                                        <CustomizedTableCell>{item.sitename}</CustomizedTableCell>
                                        <CustomizedTableCell >
                                            <div className=" route_table_div">
                                                {item.check_point_Data
                                                    .map((obj, index) =>
                                                        <span className="route_text_div" key={index}>{obj.check_point_name}{'  '}
                                                            {/* {index == 1  && index < item.chechpointtype.length - 1 && <br />} */}
                                                        </span >
                                                    )}
                                            </div>
                                        </CustomizedTableCell>
                                        <CustomizedTableCell> <span className="day_div" >{item.days}</span></CustomizedTableCell>
                                        <CustomizedTableCell>{item.starttime}</CustomizedTableCell>
                                        <CustomizedTableCell>{item.endtime}</CustomizedTableCell>
                                        <CustomizedTableCell>
                                            <IconButton
                                                onClick={() => handleEdit(item.id, item.check_point_name)}
                                            >
                                                <MdOutlineEdit color="black" /></IconButton>
                                            <IconButton
                                                onClick={() => handleDelete(item.id, item.check_point_name)}
                                            >
                                                <RiDeleteBin6Line color="#C61A1A" /></IconButton>

                                        </CustomizedTableCell>
                                    </CustomizedTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div>
                            <Paginations
                                isDarkMode={isDarkMode}
                                pageCount={pageCount}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </> : <>No Data Found</>
            }
        </div>
    )
}