import { Grid, Typography } from "@mui/material";
import React from "react";
export const CleanerSiteDetails = ({ historyData }) => {
    return (
        <div className='device_view reducepb' >
            <Grid container >
                <Grid item container sx={{ mb: 1 }}>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                            <Typography className='device_view_text label_color rowdiv_label'>Date</Typography>
                            <Typography className='device_view_text label_color rowdiv_label'>{historyData.date}</Typography>

                        </div>

                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml' >
                            <Typography className='device_view_text label_color rowdiv_label'>
                                Start Time & End Time
                            </Typography>
                            <Typography className='device_view_text content_color rowdiv_text '>
                                {`${historyData.patrol_start_time} To ${historyData.patrol_end_time}`}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                            <Typography className='device_view_text label_color rowdiv_label'>Site</Typography>
                            <Typography className='device_view_text label_color rowdiv_label'>{historyData.site}</Typography>

                        </div>

                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                            <Typography className='device_view_text label_color rowdiv_label'>
                                Level
                            </Typography>
                            <Typography className='device_view_text content_color rowdiv_text '>
                                {historyData.level}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                            <Typography className='device_view_text label_color rowdiv_label'>Block</Typography>
                            <Typography className='device_view_text label_color rowdiv_label'>{historyData.block}</Typography>

                        </div>

                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                            <Typography className='device_view_text label_color rowdiv_label'>
                                First Seen
                            </Typography>
                            <Typography className='device_view_text content_color rowdiv_text '>
                                {historyData.first_seen}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv  rowdiv_mr' style={{ paddingBottom: "20px" }} >
                            <Typography className='device_view_text label_color rowdiv_label'>Last Seen</Typography>
                            <Typography className='device_view_text label_color rowdiv_label'>
                                {historyData.last_seen}
                            </Typography>
                        </div>

                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}