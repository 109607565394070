import { CircularProgress, Grid, IconButton, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../styles/TablebButtonStyles";
import { StyledCreateButton } from "../../styles/ButtonStyles";
import { TbBrandTelegram } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { fetchOrgTelegramList } from "../../../redux/actions/OrganisationActions";
import { useSelector } from "react-redux";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Paginations } from "../../sharable/pagination/Pagination";
export const OrgTelegramList = ({ pageCount, currentPage, handlePageChange, orgTelegramList }) => {
    
    const handleCreate = () => {
        sessionStorage.setItem("edit_telegram_id", null);

        window.location.href = '/organisation/telegram/create';
    }
    const handleEdit = (id) => {
        window.location.href = `/organisation/telegram/edit/${id}`;


    }
    return (
        <Grid container>
            <Grid item lg={9} md={9} sm={4} xs={4} className="mt2" 
            sx={{ mt:{lg: 2,md:2,sm:'6% !important',xs:'6% !important' }}}>
                <Typography className="alert_text_light">List Of Sites</Typography>
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                sm={8}
                xs={8}
                className="mt2"
                sx={{ mt: 2 }}
            >
                <StyledCreateButton
                    sx={{ pt: "6% !important", pb: "6% !important" }}
                    variant="contained"
                    disableRipple
                    startIcon={<TbBrandTelegram color="white" />}
                    onClick={handleCreate}
                >
                    Add Telegram Bot
                </StyledCreateButton>
            </Grid>

            <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="mt2"
                sx={{ mb: 2, mt: 4 }}
            >
                <div className="table_Scroll ml2back">
                    <Table className="table_mob" sx={{ m: 2, width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <CustomizedTableCell sx={{ textAlign: "left" }}>
                                    Sites
                                </CustomizedTableCell>
                                <CustomizedTableCell sx={{ textAlign: "left" }}>
                                    Chat Id
                                </CustomizedTableCell>
                                <CustomizedTableCell sx={{ textAlign: "left" }}>

                                    Bot Token
                                </CustomizedTableCell>
                                <CustomizedTableCell sx={{ textAlign: "left" }}>
                                    Action

                                </CustomizedTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orgTelegramList.map((item, index) => (
                                <CustomizedTableRow key={index}>
                                    <CustomizedTableCell>
                                        {item.organization_name}
                                    </CustomizedTableCell>
                                    <CustomizedTableCell>
                                        {item.chat_id}
                                    </CustomizedTableCell>
                                    <CustomizedTableCell>
                                        {item.bot_token}
                                    </CustomizedTableCell>
                                    <CustomizedTableCell sx={{ textAlign: "left" }}>
                                        <IconButton
                                            onClick={() => handleEdit(item.id)}
                                        >
                                            <MdOutlineEdit color="black" />
                                        </IconButton>
                                        
                                    </CustomizedTableCell>
                                </CustomizedTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                {orgTelegramList.length ?
                    <div>
                        <Paginations
                            pageCount={pageCount}
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </div> : <></>
                }
            </Grid>

        </Grid>
    )
}