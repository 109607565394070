import React, { useState, useEffect } from 'react';
import { Layout } from '../../layout/Layout';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { clearUpdatePersonnelStatus, updatePersonnel, viewPersonnel } from '../../../redux/actions/PersonnelAction';
import { StyledCreateSmallButton } from '../../styles/ButtonStyles';
import { Alert, CircularProgress, FormHelperText, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { fetchSingleTag, fetchTag } from '../../../redux/actions/DeviceActions';
import { fetchSites } from '../../../redux/actions/UserRoleActions';
import { validateEmail, validatePhoneNumber } from '../../helpers/ValidationHelper';
import { DatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { GmtToUtc, timeNeglector } from '../../convertors/TimeConvertor';
export const PersonnelEdit = ({ }) => {
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch();
    const singlePersonnel = useSelector(state => state.personnel.viewPersonnel);
    const updatePersonnelStatus = useSelector(state => state.personnel.updatePersonnel);
    const siteData = useSelector(state => state.userRole.siteData);
    const [selectedStartTime, setSelectedStartTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());
    const [projectStart, setProjectStart] = useState(dayjs.utc(new Date()));
    const [projectEnd, setProjectEnd] = useState(dayjs.utc(new Date()))
    const [permitDate, setPermitDate] = useState(dayjs.utc(new Date()))
    const [permitIssuanceTime, setPermitIssuanceTime] = useState(new Date());
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [endDateLimit, setEndDateLimit] = useState(null);
    const [alertMessage, setAlertMessage] = useState('')
    const [personnelData, setPersonnelData] = useState({
        contractorname: '',
        site_id: 0,
        emp_id: '',
        email: '',
        phone: '',
        contractor_organisation_name: '',
        serial_no: "",
        insurance_date: null,
        domain: "",
        project: "",
        project_startdate: null,
        project_enddate: null,
        project_maneger: "",
        supervisor_phone: '',
        supervisor: '',
        user_type :0

    });
    const [validationErrors, setValidationErrors] = useState({
        contractorname: '',
        emp_id: '',
        site_id: '',
        phone: '',
        email: '',
        contractor_organisation_name: '',
        serial_no: "",
        insurance_date: null,
        domain: "",
        project: "",
        project_startdate: null,
        project_enddate: null,
        project_maneger: "",
        supervisor_phone: '',
        supervisor: '',
        user_type :''
    });
    useEffect(() => {
        const id = sessionStorage.getItem('EditPersonnelId')
        dispatch(viewPersonnel(id));
        dispatch(fetchSites())
    }, []);
    useEffect(() => {
        if (!singlePersonnel.isFetching && singlePersonnel.payload && singlePersonnel.payload.success === true) {
            const personnel = singlePersonnel.payload.checkpoint_details;
            setPersonnelData(personnel);
            const end_time = GmtToUtc(personnel.project_enddate);
            const start_time = GmtToUtc(personnel.project_startdate)
            const permitDate = timeNeglector(personnel.insurace_date);
            const tempstart = personnel.project_startdate != null ? dayjs(start_time) : null;
            const tempend = personnel.project_enddate != null ? dayjs(end_time) : null;
            const dateOnly = permitDate != null ? dayjs(permitDate) : null;
            //  const start_time = dayjs(startutc);

            //  const end_time = dayjs(endutc);
            setSelectedEndTime(personnel.project_enddate);
            setSelectedStartTime(personnel.project_startdate);
            setPermitIssuanceTime(personnel.insurace_date);
            setPermitDate(dateOnly)
            setSelectedSites(personnel.site_id);
            setProjectStart(tempstart);
            setProjectEnd(tempend);

        }
    }, [singlePersonnel]);
    useEffect(() => {
        if (!updatePersonnelStatus.isFetching && updatePersonnelStatus.payload &&
            updatePersonnelStatus.payload.success === true) {
            sessionStorage.setItem('trackingTab', 'three')
             window.location.href = '/tracking';
        }
        if (!updatePersonnelStatus.isFetching && updatePersonnelStatus.error) {
            setShowAlert(true);
            const error = updatePersonnelStatus.errorMessage.message;
            setAlertMessage(error);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            dispatch(clearUpdatePersonnelStatus());
            return () => clearTimeout(timeoutId);
        }
    }, [updatePersonnelStatus]);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
        }

    }, [siteData]);

    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);

    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const onHandleChange = (value, key) => {
        let temp = { ...personnelData };
        temp[key] = value;
        if (key === 'site_id') {
            setSelectedSites(value)
        }
        setPersonnelData(temp);
        if(key != 'user_type')
            {
        const valueLength = value.trim().length;
        let error = '';
        switch (key) {
            case 'contractorname':
            case 'contractor_organisation_name':
            case 'serial_no':
            case 'domain':
            case 'project':
            case 'project_maneger':
            case 'supervisor':
                error =
                    valueLength < 2 || valueLength >= 30
                        ? 'Field must be between 2 and 30 characters long'
                        : '';
                break;
            case 'emp_id':
                error =
                    valueLength < 2 || valueLength >= 30
                        ? 'Employee Id must be between 2 and 30 characters long'
                        : '';
                break;
            case 'email':
                const validEmail = validateEmail(value);
                error = validEmail ? '' : 'Invalid Email';
                break;
            case 'phone':
            case 'supervisor_phone':
                const validPhone = validatePhoneNumber(value);
                error = validPhone ? '' : 'Invalid phone';
                break;
            default:
                break;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));
    }
    }
    const handlePermitIssuanceTime = (newTime) => {

        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        setPermitIssuanceTime(formattedTime);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                insurance_date: '',
            }));
        }
    }
    const handleEndTimeChange = (newTime) => {
        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const tempDate = newTime.$d.toISOString();

        setSelectedEndTime(tempDate);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                project_enddate: '',
            }));
        }
        if (newTime < endDateLimit) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                project_enddate: 'Invalid End Date',
            }));
        }
    };
    const handleStartTimeChange = (newTime) => {
        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const tempDate = newTime.$d.toISOString();

        setSelectedStartTime(tempDate);
        setEndDateLimit(newTime)
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                project_startdate: '',
            }));
        }
    }
    const handleCrete = (e) => {
        const id = sessionStorage.getItem('EditPersonnelId')

        let data = {
            site_id: selectedSites,
            contractor_name: personnelData.contractorname,
            emp_id: personnelData.emp_id,
            phone: personnelData.phone,
            email: personnelData.email,
            organization_id: sessionStorage.getItem('org_Id'),
            organisation_name: personnelData.contractor_organisation_name,
            serial_no: personnelData.serial_no,
            insurace_date: permitIssuanceTime,
            domain: personnelData.domain,
            project: personnelData.project,
            project_startdate: selectedStartTime,
            project_enddate: selectedEndTime,
            project_maneger: personnelData.project_maneger,
            supervisor: personnelData.supervisor,
            supervisor_phone: personnelData.supervisor_phone,
            created_user: parseInt(sessionStorage.getItem('id')),
            user_type :parseInt(personnelData.user_type),

        }
        e.preventDefault();
        const isEmptyField = Object.values(data).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                contractorname: personnelData.contractorname.trim() === '' ? 'This field is mandatory' : prevErrors.contractorname,
                emp_id: personnelData.emp_id.trim() === '' ? 'This field is mandatory' : prevErrors.emp_id,
                phone: personnelData.phone ? !validatePhoneNumber(personnelData.phone) ? prevErrors.phone : '' : 'This field is mandatory',
                email: personnelData.email ? !validateEmail(personnelData.email) ? prevErrors.mail_id : '' : 'This field is mandatory',
                contractor_organisation_name: personnelData.contractor_organisation_name.trim() === '' ? 'This field is mandatory'
                    : prevErrors.contractor_organisation_name,
                site_id: selectedSites ? '' : 'This field is mandatory',
                serial_no: personnelData.serial_no.trim() === '' ? 'This field is mandatory' : prevErrors.serial_no,
                domain: personnelData.domain.trim() === '' ? 'This field is mandatory' : prevErrors.domain,
                project: personnelData.project.trim() === '' ? 'This field is mandatory' : prevErrors.project,
                project_startdate: selectedStartTime ? '' : 'This field is mandatory',
                insurance_date: permitIssuanceTime ? '' : 'This field is mandatory',
                project_enddate: selectedEndTime ? '' : 'This field is mandatory',

                project_maneger: personnelData.project_maneger.trim() === '' ? 'This field is mandatory' : prevErrors.project_maneger,
                supervisor_name: personnelData.supervisor.trim() === '' ? 'This field is mandatory' : prevErrors.supervisor,

                supervisor_phone: personnelData.supervisor_phone ?
                    !validatePhoneNumber(personnelData.supervisor_phone) ? prevErrors.supervisor_phone : ''
                    : 'This field is mandatory',

            }));

        }
        if (!isEmptyField && allClear) {
            setValidationErrors({
                contractorname: '',
                emp_id: '',
                site_id: '',
                phone: '',
                email: '',
                contractor_organisation_name: '',
                serial_no: "",
                insurance_date: '',
                domain: "",
                project: "",
                project_startdate: '',
                project_enddate: '',
                project_maneger: "",
                supervisor_phone: '',
                supervisor: ''
            });

           dispatch(updatePersonnel(id, data))
        }
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                isCreate={true}
                activeSubtab={"three"}
            >
                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handlealertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handlealertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
             {   updatePersonnelStatus.isFetching ||  singlePersonnel.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                    <Grid item lg={12} >
                        <Typography
                            className='device_view_head'>Personnel Details</Typography>
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Personnel Name</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Personnel name"
                            value={personnelData.contractorname}
                            onChange={(e) => onHandleChange(e.target.value, 'contractorname')}
                            error={!!validationErrors.contractorname}
                            helperText={validationErrors.contractorname}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Employee Id</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Employee Id'
                            value={personnelData.emp_id}
                            onChange={(e) => onHandleChange(e.target.value, 'emp_id')}
                            error={!!validationErrors.emp_id}
                            helperText={validationErrors.emp_id}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                    <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                           Type</InputLabel>
                    <Select
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                            value={personnelData.user_type}
                            onChange={(e) => onHandleChange(e.target.value,'user_type')}
                            displayEmpty
                            disabled
                            error={!!validationErrors.user_type}
                        >
                                <MenuItem value={2}>
                                    {"Cleaner"}
                                </MenuItem>
                                <MenuItem value={1}>
                                    {"Personnel"}
                                </MenuItem>
                        </Select>
                        {validationErrors.user_type && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.user_type}
                            </Typography>
                        )}
                        </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Site</InputLabel>
                        <Select
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                            value={personnelData.site_id}
                            onChange={(e) => setSelectedSites(e.target.value)}
                            displayEmpty
                            error={!!validationErrors.site_id}
                        >
                            {sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))}
                        </Select>
                        {validationErrors.site_id && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.site_id}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Phone No</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div " : "form_field_right form_field_light"}
                            placeholder='Phone Number'
                            type='phone'
                            value={personnelData.phone}
                            onChange={(e) => onHandleChange(e.target.value, 'phone')}
                            error={!!validationErrors.phone}
                            helperText={validationErrors.phone}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Mail Id</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Mail Id"
                            type='email'
                            value={personnelData.email}
                            onChange={(e) => onHandleChange(e.target.value, 'email')}
                            error={!!validationErrors.email}
                            helperText={validationErrors.email}
                        />
                    </Grid>
                    {/* <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Company Name  
                        </InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Company Name"
                            type='text'
                            value={personnelData.contractor_organisation_name}
                            onChange={(e)=>onHandleChange(e.target.value,'contractor_organisation_name')}
                            error={!!validationErrors.contractor_organisation_name}
                            helperText={validationErrors.contractor_organisation_name}
                        />
                      
                    </Grid> */}
                    <Grid item lg={12} sx={{ mt: 3, mb: 3 }}>
                        <Typography
                            className='device_view_head'>Project Details</Typography>
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Permit Serial Number</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Permit Serial Number"
                            value={personnelData.serial_no}
                            onChange={(e) => onHandleChange(e.target.value, 'serial_no')}
                            error={!!validationErrors.serial_no}
                            helperText={validationErrors.serial_no}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Permit Issuance Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker ']}>
                                <DatePicker
                                    sx={{ pt: 0, width: "85.4% !important" }}
                                    // minDateTime={dayjs()}

                                    value={permitDate}
                                    onChange={handlePermitIssuanceTime}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Domain / Unit</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Domain / Unit"
                            value={personnelData.domain}
                            onChange={(e) => onHandleChange(e.target.value, 'domain')}
                            error={!!validationErrors.domain}
                            helperText={validationErrors.domain}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Project Title</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Project Title'
                            value={personnelData.project}
                            onChange={(e) => onHandleChange(e.target.value, 'project')}
                            error={!!validationErrors.project}
                            helperText={validationErrors.project}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <Grid container>
                            <Grid item xs={10} sm={10} lg={5.4} md={5.4}>
                                <InputLabel
                                    className={isDarkMode ? "form_text " : "form_text_light "}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Start Date & Time </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} className="datetime-container" >
                                    <DemoContainer
                                        className="datetime-container"
                                        sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                        <DesktopDateTimePicker
                                            // className="timepicker"

                                            //minDateTime={dayjs()}


                                            value={projectStart}
                                            onChange={handleStartTimeChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={10} sm={10} lg={5.5} md={5.5}>
                                <InputLabel
                                    className={isDarkMode ? "form_text " : "form_text_light "}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    End Date & Time </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                        <DesktopDateTimePicker

                                            // minDateTime={dayjs()}
                                            minDateTime={endDateLimit}

                                            value={projectEnd}
                                            onChange={handleEndTimeChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Project Manager</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Project Manager'
                            value={personnelData.project_maneger}
                            onChange={(e) => onHandleChange(e.target.value, 'project_maneger')}
                            error={!!validationErrors.project_maneger}
                            helperText={validationErrors.project_maneger}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Name of Awarded Contractor</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Name of Awarded Contractor"
                            value={personnelData.contractor_organisation_name}
                            onChange={(e) => onHandleChange(e.target.value, 'contractor_organisation_name')}
                            error={!!validationErrors.organisation_name}
                            helperText={validationErrors.contractor_organisation_name}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Supervisor Name</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Supervisor Name'
                            value={personnelData.supervisor}
                            onChange={(e) => onHandleChange(e.target.value, 'supervisor')}
                            error={!!validationErrors.supervisor}
                            helperText={validationErrors.supervisor}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Supervisor Phone</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Supervisor Phone"
                            value={personnelData.supervisor_phone}
                            onChange={(e) => onHandleChange(e.target.value, 'supervisor_phone')}
                            error={!!validationErrors.supervisor_phone}
                            helperText={validationErrors.supervisor_phone}
                        />

                    </Grid>
                    <Grid item tem xs={10} sm={10} lg={10} md={10} >
                        <StyledCreateSmallButton
                            onClick={handleCrete}
                            sx={{ mt: 5, width: 150 }} variant="contained"
                            disableRipple>
                            Update
                        </StyledCreateSmallButton>
                    </Grid>
                </Grid>
}
            </Layout>
        </>
    )
}