import React, { useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import { subDays } from "date-fns";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSites } from "../../redux/actions/UserRoleActions";
import { AuditTrailFilter } from "./AuditTrailFilter";
import { fetchAuditList } from "../../redux/actions/AlertActions";
import { AuditTrailTable } from "./AuditTrailTable";
import { CircularProgress, Grid } from "@mui/material";
import { dateOnly, dateSearchConverter, utcDateTimeToLocalTime } from "../convertors/TimeConvertor";
import dayjs from "dayjs";
import { BatteryDataChangeAnalyzer, CheckPointDataChangeAnalyzer, EncoderDataChangeAnalyzer, OrganisationDataChangeAnalyzer, PersonnelDataChangeAnalyzer, RouteDataChangeAnalyzer, SensorDataChangeAnalyzer, UserDataChangeAnalyzer } from "../helpers/AuditTrailHelper";
export const AuditTrailList = () => {
    const dispatch = useDispatch();
    const siteData = useSelector(state => state.userRole.siteData);
    const auditTrailData = useSelector(state => state.alert.auditList);

    const [activeMenuItem, setActiveMenuItem] = useState("audittrail");
    const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 7));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [searchWord, setSearchWord] = useState('');
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [AuditTrailList, setAuditTrailList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20
    useEffect(() => {
        dispatch(fetchSites());
        dispatch(fetchAuditList())
    }, []);
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            to_date: dateSearchConverter(selectedEndDate),
            from_date: dateSearchConverter(selectedStartDate),
            stage: selectedType,
            limit: limit,
            start: start,
            site_id: selectedSites,

            searchword: searchWord,

        }
        dispatch(fetchAuditList(data))
    }, [selectedType, selectedSites, selectedStartDate, selectedEndDate, searchWord, currentPage]);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp);
            const siteId = sessionStorage.getItem('user_site_id');
            // setSelectedSites(siteId)
            // if (siteId != 0) {
            //     setSelectedSites(siteId)
            // }
        }

    }, [siteData]);
    useEffect(() => {
        if (!auditTrailData.isFetching && auditTrailData.payload && auditTrailData.payload.success === true) {
            const tempAudit = auditTrailData.payload.data;
            const tempPagination = auditTrailData.payload.pagination;
            const temp = [];

            tempAudit.forEach(item => {
                if ( Object.keys(item.prevdata).length === 0 && item.prevdata.constructor === Object) {
                    let data={
                        ...item,
                        msg:"",
                        creteDate: dateOnly(item.created_at),
                        createdTime: utcDateTimeToLocalTime(item.created_at)
                    }
                    temp.push(data);

                } else {
                    if(item.stage == 6){
                       const changedOrgData = OrganisationDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                       let data={
                        ...item,
                        msg:changedOrgData == undefined ? "" : changedOrgData,
                        creteDate: dateOnly(item.created_at),
                        createdTime: utcDateTimeToLocalTime(item.created_at)
                       }
                       temp.push(data);

                    }
                    if(item.stage == 2){
                        const changedCkeckpointData = CheckPointDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedCkeckpointData == undefined ? "" : changedCkeckpointData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }   
                    if(item.stage == 3){
                        const changedRouteData = RouteDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedRouteData == undefined ? "" : changedRouteData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }   
                    if(item.stage == 4){
                        const changedPersonnelData = PersonnelDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedPersonnelData == undefined ? "" : changedPersonnelData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }   
                    if(item.type == 19){
                        const changedSensorData = SensorDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedSensorData == undefined ? "" : changedSensorData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }  
                    if(item.type == 25){
                        const changedBatteryData = BatteryDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedBatteryData == undefined ? "" : changedBatteryData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }    
                    if(item.type == 21){
                        const changedEncoderData = EncoderDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedEncoderData == undefined ? "" : changedEncoderData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }   
                    if(item.type == 1){
                        const changedUserData = UserDataChangeAnalyzer(item.changedata,item.prevdata,item.message);
                        let data={
                         ...item,
                         msg:changedUserData == undefined ? "" : changedUserData,
                         creteDate: dateOnly(item.created_at),
                         createdTime: utcDateTimeToLocalTime(item.created_at)
                        }
                        temp.push(data);
 
                    }     
                                      

                }
            });
            
            // temp now contains items with non-empty changedata
            // const tempAduitList =
            //     tempAudit.map((item) => {
            //         if (JSON.stringify(item.changedData) != '{}') {
            //             // changedData is an empty object
                    
            //         return {
            //             ...item,
            //             creteDate: dateOnly(item.created_at),
            //             createdTime: utcDateTimeToLocalTime(item.created_at)
            //         }
            //     }
            //     else{
            //         alert("changed")
            //     }
            //     })
            const tempData = temp.map((item) => {
                return {
                    ...item,
                    date: dateFormat(item.creteDate),


                }
            })

            setAuditTrailList(tempData);
            setPageCount(tempPagination.total_pages)
        }
    }, [auditTrailData])
    const dateFormat = (date) => {
        let formattedDate = dayjs(date);

        return formattedDate.format("MM/DD/YYYY")
    }
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    const onSearchWordChange = (data) => {
        setSearchWord(data);
    }
    const handleSites = (value) => {
        setSelectedSites(value);
    }
    const handleSelectedType = (value) => {
        setSelectedType(value);
        setCurrentPage(1)
    }
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    }
    return (
        <Layout
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            searchWord={searchWord}
            handleEndDateChange={handleEndDateChange}
            handleStartDateChange={handleStartDateChange}
            onSearchWordChange={onSearchWordChange}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"audittrail"}
            activeMenuKey={"Audit Trail"}
            placeHolder={"Search Action Name.."}

        >
            <div style={{ marginLeft: "1%", marginRight: "2%", marginTop: "3%", }} className="">
                <AuditTrailFilter
                    selectedSites={selectedSites}
                    handleSites={handleSites}
                    sites={sites}
                    handleSelectedType={handleSelectedType}
                    selectedType={selectedType}
                />
            </div>
            {auditTrailData.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                AuditTrailList.length ?
                    <AuditTrailTable
                        AuditTrailList={AuditTrailList}
                        pageCount={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                    :
                    <Grid item lg={11.8} sm={11.8} xs={11.8} sx={{ ml: '4% !important' }}>No Data Found</Grid>
            }
        </Layout>
    )
}