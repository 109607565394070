import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import '../../dashboard/Dashboard.css';
import { Alert, CircularProgress, Grid, Snackbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearUserUpdateStaus, fetchUser, updateUser } from "../../../redux/actions/UserActions";
import { fetchRoleDropdownList, fetchSites, fetchUserRoleList } from "../../../redux/actions/UserRoleActions";
import { EditScreen } from "../../organisation/userEdit/EditScreen";
import { validateEmail, validatePassword, validatePhoneNumber } from "../../helpers/ValidationHelper";
export const UsersSubuserEdit = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.userData);
    const updateStatus = useSelector(state => state.user.updateUser);
    const siteData = useSelector(state => state.userRole.siteData);
    const roleListData = useSelector(state => state.userRole.roleDropdown);
    const [showAlert, setShowAlert] = useState(false);
    const [alerText, setAlertText] = useState("");
    const [isDarkMode, setDarkMode] = useState(false);
    // const [activeMenuItem, setActiveMenuItem] = useState("usermanagement");
    const [userId, setUserId] = useState(0);
    const [hideSite, setHideSite] = useState(false);

    const [userDetails, setUserDetails] = useState({
        username: "",
        role_id: 0,
        site_id: 0,
        email: '',
        phone_number: '',
        year_of_experience: 0,
        gender: '',
        description: ''

    });
    const [validationErrors, setValidationErrors] = useState({
        username: "",
        //  password: "",
        email: "",
        role_id: '',
        site_id: '',
        phone_number: "",
        year_of_experience: '',
        gender: "",
        description: "",
    })
    const [sites, setSites] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    useEffect(() => {
        //         sessionStorage.setItem("subUser",false)
        //         const subUser = sessionStorage.getItem("subUser");
        const id = sessionStorage.getItem("edituserssubuserId");
        let data = {
            role_id: sessionStorage.getItem('user_role_id'),
            org_id: sessionStorage.getItem('org_Id'),
            site_id: hideSite ? 0 : sessionStorage.getItem('user_site_id')

        }
        dispatch(fetchUser(id));
        dispatch(fetchSites());
        dispatch(fetchUserRoleList());
        dispatch(fetchRoleDropdownList(data));

    }, []);
    useEffect(() => {
        if (!userData.isFetching && userData.payload && userData.payload.success === true) {
            const tempUser = userData.payload.data;
            if (tempUser.profilepic !== "") {
                const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media' + tempUser.profilepic;
                setPreviewUrl(tempUrl);
            }

            setUserDetails(tempUser);
            setUserId(tempUser.id);
            if (tempUser.role == 'Admin' || tempUser.role == 'Super Admin') {
                setHideSite(true)
            }
            else {
                setHideSite(false)
            }
        }
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
        }
        if (!roleListData.isFetching && roleListData.payload && roleListData.payload.success === true) {
            const temp = roleListData.payload.list;
            const tempRoles = [];
            temp.map((item) => {
                if (item.id !== 1) {
                    tempRoles.push(item);
                }
            })
            setRoles(tempRoles);
        }
        if (!updateStatus.isFetching && updateStatus.payload && updateStatus.payload.success === true) {
            window.location.href = '/usermanagement/subuser/view'

        }
        if (!updateStatus.isFetching && updateStatus.error) {
            setShowAlert(true);
            setAlertText(updateStatus.errorMessage.message);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearUserUpdateStaus());
            }, 2000);

            return () => clearTimeout(timeoutId);
        }

    }, [userData, siteData, roleListData, updateStatus]);

    const handleActiveMenuItems = () => {
        // setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }

    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);

    }
    // const handleChange = (value, key) => {
    //     let temp = { ...userDetails };
    //     temp[key] = value;
    //     setUserDetails(temp);
    //     if (key == 'role_id') {
    //         roles.map(item => {
    //             if (value == item.id) {
    //                 if (item.role == 'Super Admin' || item.role == 'Admin') {

    //                     setHideSite(true);
    //                     let temp = { ...userDetails };
    //                     temp['role_id'] = value
    //                     temp['site_id'] = 0;
    //                     setUserDetails(temp);
    //                 }
    //                 else
    //                     setHideSite(false);
    //             }
    //         })


    //     }
    //     const strln = value.toString().trim();

    //     const valueLength = strln.length;
    //     // let error = '';
    //     // switch (key) {
    //     //     case 'username':
    //     //         // case 'license_no':


    //     //         error =
    //     //             valueLength < 2 || valueLength >= 30
    //     //                 ? 'Field must be between 2 and 30 characters long'
    //     //                 : '';
    //     //         break;
    //     //     case 'password':
    //     //         const validPass = validatePassword(value);
    //     //         if (validPass.code === false) {
    //     //             error = validPass.msg;
    //     //         }
    //     //         else {
    //     //             error = '';
    //     //         }
    //     //         break;
    //     //     case 'email':
    //     //         const validEmail = validateEmail(value);
    //     //         error = validEmail ? '' : 'Invalid Email';
    //     //         break;
    //     //     case 'phone_number':

    //     //         const validPhone = validatePhoneNumber(value);
    //     //         error = validPhone ? '' : 'Invalid phone';
    //     //         break;
    //     //     case 'description':
    //     //         error =
    //     //             valueLength < 10 || valueLength >= 250
    //     //                 ? 'Field must be between 10 and 250 characters long'
    //     //                 : '';
    //     //         break;
    //     //     case 'site_id':
    //     //         if (!hideSite) {
    //     //             error = value !== '' ? '' : 'This field is mandatory'
    //     //         }
    //     //         else
    //     //             error = ''
    //     //         break; case 'role_id':
    //     //         error = value !== '' ? '' : 'This field is mandatory'
    //     //         break;
    //     //     case 'gender':
    //     //         error = value !== '' ? '' : 'This field is mandatory'
    //     //         break;
    //     //     case 'year_of_experience':
    //     //         error = (value === '') ? 'This field is mandatory' : (value >= 0 ? '' : 'Minimum limit reached')
    //     //         break;
    //     //     default:
    //     //         break;
    //     // }
    //     let error = ''; // Declare the error variable outside the switch statement

    //     switch (key) {
    //         case 'username':
    //             // Define valueLength before this switch block if not defined elsewhere
    //             error = valueLength < 2 || valueLength >= 30
    //                 ? 'Field must be between 2 and 30 characters long'
    //                 : '';
    //             break;

    //         case 'password':
    //             const validPass = validatePassword(value); // Define validPass in this case
    //             error = validPass.code === false ? validPass.msg : '';
    //             break;

    //         case 'email':
    //             const validEmail = validateEmail(value); // Define validEmail in this case
    //             error = validEmail ? '' : 'Invalid Email';
    //             break;

    //         case 'phone_number':
    //             const validPhone = validatePhoneNumber(value); // Define validPhone in this case
    //             error = validPhone ? '' : 'Invalid phone';
    //             break;

    //         case 'description':
    //             error = valueLength < 10 || valueLength >= 250
    //                 ? 'Field must be between 10 and 250 characters long'
    //                 : '';
    //             break;

    //         case 'site_id':
    //             if (!hideSite) {
    //                 error = value !== '' ? '' : 'This field is mandatory';
    //             } else {
    //                 error = '';
    //             }
    //             break;

    //         case 'role_id':
    //             error = value !== '' ? '' : 'This field is mandatory';
    //             break;

    //         case 'gender':
    //             error = value !== '' ? '' : 'This field is mandatory';
    //             break;

    //         case 'year_of_experience':
    //             error = value === '' ? 'This field is mandatory' : (value >= 0 ? '' : 'Minimum limit reached');
    //             break;

    //         default:
    //             break;
    //     }


    //     setValidationErrors((prevErrors) => ({
    //         ...prevErrors,
    //         [key]: error,
    //     }));
    // }
    const handleChange = (value, key) => {
        let temp = { ...userDetails };
        temp[key] = value;
        setUserDetails(temp);

        if (key === 'role_id') {
            roles.forEach(item => {
                if (value === item.id) {
                    if (item.role === 'Super Admin' || item.role === 'Admin') {
                        setHideSite(true);
                        temp['role_id'] = value;
                        temp['site_id'] = 0;
                        setUserDetails(temp);
                    } else {
                        setHideSite(false);
                    }
                }
            });
        }

        const strln = value.toString().trim();
        const valueLength = strln.length;

        let error = '';

        switch (key) {
            case 'username':
                error = valueLength < 2 || valueLength >= 30
                    ? 'Field must be between 2 and 30 characters long'
                    : '';
                break;

            case 'password':
                const validPass = validatePassword(value);
                error = validPass.code === false ? validPass.msg : '';
                break;

            case 'email':
                const validEmail = validateEmail(value);
                error = validEmail ? '' : 'Invalid Email';
                break;

            case 'phone_number':
                const validPhone = validatePhoneNumber(value);
                error = validPhone ? '' : 'Invalid phone';
                break;

            case 'description':
                error = valueLength < 10 || valueLength >= 250
                    ? 'Field must be between 10 and 250 characters long'
                    : '';
                break;

            case 'site_id':
                error = !hideSite && value === '' ? 'This field is mandatory' : '';
                break;

            case 'role_id':
                error = value === '' ? 'This field is mandatory' : '';
                break;

            case 'gender':
                error = value === '' ? 'This field is mandatory' : '';
                break;

            case 'year_of_experience':
                error = value === '' ? 'This field is mandatory' : (value >= 0 ? '' : 'Minimum limit reached');
                break;

            default:
                break;
        }

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [key]: error,
        }));
    }
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file)); // Create a preview URL for the selected file
        // Do something with the selected file, such as uploading it
    }
    const handleUpdate = (e) => {
        let data = {
            username: userDetails.username,
            organisation_id: parseInt(sessionStorage.getItem('org_Id')),
            email: userDetails.email,
            role_id: userDetails.role_id,
            site_id: userDetails.site_id,
            phone_number: userDetails.phone_number,
            year_of_experience: parseInt(userDetails.year_of_experience),
            gender: userDetails.gender,
            description: userDetails.description,
            parent_id: userDetails.parent_reference_id,
            created_user: parseInt(sessionStorage.getItem('id')),

        }
        e.preventDefault();
        const isEmptyField = Object.values(data).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null || value === "" || value === NaN;
        });
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (isEmptyField) {

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                username: userDetails.username.trim() === '' ? 'This field is mandatory' : prevErrors.username,
                phone_number: userDetails.phone_number ? !validatePhoneNumber(userDetails.phone) ? prevErrors.phone_number : '' : 'This field is mandatory',
                email: userDetails.email ? !validateEmail(userDetails.email) ? prevErrors.email : '' : 'This field is mandatory',
                description: userDetails.description.trim() === '' ? 'This field is mandatory' : prevErrors.description,
                gender: userDetails.gender.trim() === '' ? 'This field is mandatory' : prevErrors.gender,
                // password: userDetails.password.trim() === '' ? 'This field is mandatory' : prevErrors.password,
                role_id: userDetails.role_id === '' ? 'This field is mandatory' : prevErrors.role_id,
                site_id: userDetails.site_id === '' ? 'This field is mandatory' : prevErrors.site_id,
                year_of_experience: userDetails.year_of_experience === ''
                    ? 'This field is mandatory'
                    : userDetails.year_of_experience < 0
                        ? 'Minimum limit reached'
                        : prevErrors.year_of_experience
            }));

        }


        if (!isEmptyField && allClear) {
            setValidationErrors({
                username: "",
                // user_id: '',
                password: "",
                email: "",
                role_id: '',
                site_id: '',
                phone_number: "",
                year_of_experience: '',
                gender: "",
                description: "",
            })

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('username', data.username);
            formData.append('organisation_id', data.organisation_id);
            // formData.append('password', data.password);
            formData.append('email', data.email);
            formData.append('role_id', data.role_id);
            formData.append('site_id', data.site_id);
            formData.append('phone_number', data.phone_number);
            formData.append('year_of_experience', data.year_of_experience);
            formData.append('gender', data.gender);
            formData.append('description', data.description);
            formData.append('parent_id', data.parent_id);
            formData.append('created_user', data.created_user);

            dispatch(updateUser(userId, formData));
        }
    }
    const handlealertClose = () => {
        setShowAlert(false);
    }
    return (
        <Layout

            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"usermanagement"}
            activeMenuKey={"usermanagement"}

            isCreate={true}
        >
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alerText}
                </Alert>
            </Snackbar>
            {userData.isFetching || updateStatus.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                    <Grid item lg={12} >
                        <Typography
                            className='device_view_head'>Edit User</Typography>

                    </Grid>

                    <EditScreen
                        userDetails={userDetails}
                        handleChange={handleChange}
                        roles={roles}
                        sites={sites}
                        handleUpdate={handleUpdate}
                        isDarkMode={isDarkMode}
                        previewUrl={previewUrl}
                        handleFileInputChange={handleFileInputChange}
                        validationErrors={validationErrors}
                        hideSite={hideSite}

                    />

                </Grid>
            }
        </Layout>
    )
}