import React, { useEffect, useRef, useState } from 'react';
import { Sidebar } from '../sharable/sidebar/Sidebar';
import { Header } from '../sharable/header/Header';
import { Alert, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserRole } from '../../redux/actions/UserRoleActions';
import { fetchUser } from '../../redux/actions/UserActions';
import { clearfetchRefreshToken, fetchLoggedUserInfo, fetchRefreshToken, userLogout } from '../../redux/actions/AuthenticationActions';
import { LogoutModal } from '../Modals/LogoutModal';
import { socket } from '../../services/SocketService';

export const Layout = ({ children, isDarkMode, handleDarkmode, activeMenuItem, navigation,
    selectedEndDate, selectedStartDate, handleActiveMenuItems, activeMenuKey, handleEndDateChange,
    handleStartDateChange, placeHolder, isCreate, activeSubtab, searchWord, onSearchWordChange,isSurveillacePrev,
    isClenerHistory
}) => {
    const intervalIdRef = useRef(null);
    const [userName, setUserName] = useState('');
    const [user_Pic,setUser_Pic] = useState('');
    const [roleData, setRoleData] = useState([]);
    const [openLogOutModal,setOpenLogOutModal] = useState(false);
    const userDetails = useSelector(state => state.login.loggedUser);
    const refreshTokenData = useSelector(state => state.login.refreshToken);
    const logoutInfo = useSelector(state => state.login.logout);

    const roleDetails = useSelector(state => state.userRole.roleData);
    const [intervalId, setIntervalId] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')
    
    const dispatch = useDispatch();
    useEffect(() => {
        if (activeMenuItem != "tracking") {
            sessionStorage.setItem('trackingTab', 'one')
        }
        if (activeMenuItem != "organisation") {
            sessionStorage.setItem('organisationTab', 'org_details')
        }
        // const role_id = sessionStorage.getItem('role_id');
        // const id = sessionStorage.getItem('id');
      //  dispatch(fetchLoggedUserInfo(id));
       
    //    const temp_id = { id: parseInt(role_id) };
     //   dispatch(fetchUserRole(role_id));
        let temppath =sessionStorage.getItem('user_pro_pic')
        if(temppath !== ""){
            const tempUrl=process.env.REACT_APP_API_BASE_URL +'/media'+temppath; 
            setUser_Pic(tempUrl);
        }
        let roleData = sessionStorage.getItem("per_feature")

        const roleArray = JSON.parse(roleData);
        setRoleData(roleArray);
        let tempname = sessionStorage.getItem("user_name")

        setUserName(tempname);
        // const id = setInterval(callGetRefreshedToken, 60000 );
        // setIntervalId(id);
        const id = setInterval(callGetRefreshedToken, 3000000); // Call every 60 seconds
        intervalIdRef.current = id;
    
        return () => clearInterval(intervalIdRef.current); 
    }, []);

    useEffect(()=>{
        if (!refreshTokenData.isFetching && refreshTokenData.payload && refreshTokenData.payload.success === true) {
            const tokenDatas = refreshTokenData.payload.data;
            sessionStorage.setItem('token', tokenDatas.accessToken);
            sessionStorage.setItem('refreshToken', tokenDatas.refreshToken);

        }
        if (!refreshTokenData.isFetching && refreshTokenData.error) {
            const error = refreshTokenData.errorMessage.message;
            setAlertMessage(error);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
              setShowAlert(false);
              logoutFromAll();
              dispatch(clearfetchRefreshToken());
      
            }, 2000);
            return () => clearTimeout(timeoutId);
          }
        if (!logoutInfo.isFetching && logoutInfo.payload && logoutInfo.payload.success === true) {
//             setOpenLogOutModal(false);
//             sessionStorage.setItem("organisation_type_data",null);
//             sessionStorage.removeItem('token');
//             sessionStorage.removeItem('refreshToken');
//             sessionStorage.removeItem('id');
//             sessionStorage.removeItem('role_id');
//             sessionStorage.removeItem('username');
//             sessionStorage.removeItem('username');
//             sessionStorage.removeItem('org_Id');
//             sessionStorage.removeItem('user_pro_pic');
//             sessionStorage.removeItem('per_feature')
//             sessionStorage.removeItem("user_site_id")
//             sessionStorage.removeItem("user_name");
//             sessionStorage.removeItem("org_logo");
//             sessionStorage.removeItem("is_wisilica");
//             sessionStorage.removeItem("alert_stage");
//             sessionStorage.removeItem("alert_type");
//             sessionStorage.removeItem("organisationTab");
//             sessionStorage.removeItem("alertViewId");
//             sessionStorage.removeItem("surveillanceTab");
//             sessionStorage.removeItem("organisation_type_data");
//             sessionStorage.removeItem("trackingTab");
//             sessionStorage.removeItem('alert_stage');
// window.location.href = "/"
//logoutFromAll();
        }
    },[refreshTokenData,logoutInfo])
    const callGetRefreshedToken =()=>{
        let data={
            refreshtoken:sessionStorage.getItem('refreshToken')
        }
        dispatch(fetchRefreshToken(data))
    }
    const logoutFromAll=()=>{
        setOpenLogOutModal(false);
        sessionStorage.setItem("organisation_type_data",null);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('id');
        sessionStorage.removeItem('role_id');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('org_Id');
        sessionStorage.removeItem('user_pro_pic');
        sessionStorage.removeItem('per_feature')
        sessionStorage.removeItem("user_site_id")
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("org_logo");
        sessionStorage.removeItem("is_wisilica");
        sessionStorage.removeItem("alert_stage");
        sessionStorage.removeItem("alert_type");
        sessionStorage.removeItem("organisationTab");
        sessionStorage.removeItem("alertViewId");
        sessionStorage.removeItem("surveillanceTab");
        sessionStorage.removeItem("organisation_type_data");
        sessionStorage.removeItem("trackingTab");
        sessionStorage.removeItem('alert_stage');
window.location.href = "/"
    }
    useEffect(() => {
        if (activeMenuItem != "tracking") {
            sessionStorage.setItem('trackingTab', 'one')
        }


    }, [activeMenuItem]);
    // useEffect(() => {
    //     if (!userDetails.isFetching && userDetails.payload && userDetails.payload.success === true) {
    //         const name = userDetails.payload.data.username;
    //         const temp_user_site_id = userDetails.payload.data.site_id;
    //         //sessionStorage.setItem("user_site_id",temp_user_site_id)
           
    //     }
    // }, [userDetails]);
    // useEffect(() => {
    //     if (!roleDetails.isFetching && roleDetails.payload && roleDetails.payload.success === true) {
    //         const roleData = roleDetails.payload.Role_details.role_feature[0].features;
    //         const roleArray = JSON.parse(roleData);
    //       //  setRoleData(roleArray)
    //     }
    // }, [roleDetails])
    const onLogoutModalClose =() =>{
        setOpenLogOutModal(false);
    }
    const onLogOutConform =()=>{
        let data={
            refreshtoken:sessionStorage.getItem('refreshToken')
        }
        dispatch(userLogout(data))     
        
        logoutFromAll()    
    }
    const openLogoutodal =()=>{
        handleActiveMenuItems('logout')
        setOpenLogOutModal(true);
        sessionStorage.removeItem('socketAuthToken');
       
        socket.disconnect();
    }
    return (

        <Grid
            container spacing={0}
            columnSpacing={0}
            className={isDarkMode ? "back_bg zeromr" : "white_bg"}
        >
            <Grid
                item xs={12}
                sm={12}
                md={12}
                lg={2} >
                <Sidebar isDarkMode={isDarkMode}
                    handleActiveMenuItems={handleActiveMenuItems}
                    activeMenuItem={activeMenuItem}
                    roleData={roleData} 
                    openLogoutodal={openLogoutodal}
                />
            </Grid>
            <Grid
                item xs={12} md={12}
                className={isDarkMode ? "grybg" : "white_bg"}
                style={{ marginBottom: "5%", display: 'flex', flexDirection: 'column', flex: 1, width: '100%', height: '100%', }}>
                <div style={{ marginLeft: "-1%" }}>
                    <Header
                    isClenerHistory={isClenerHistory}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        handleEndDateChange={handleEndDateChange}
                        handleStartDateChange={handleStartDateChange}
                        isDarkMode={isDarkMode}
                        handleDarkmode={handleDarkmode}
                        userName={userName}
                        roleData={roleData}
                        activeMenuItem={activeMenuKey}
                        placeHolder={placeHolder}
                        isCreate={isCreate}
                        navigation={navigation}
                        activeSubtab={activeSubtab}
                        searchWord={searchWord}
                        onSearchWordChange={onSearchWordChange}
                        user_Pic={user_Pic}
                        isSurveillacePrev={isSurveillacePrev}
                    />
                </div>
                <div style={{ marginLeft: "4%", marginRight: "3%" }} className={isDarkMode ? "grybg" : "white_bg"}>
                {showAlert ?
        <Alert variant="outlined" severity="error">{alertMessage}</Alert> : <></>
      }
                    <LogoutModal 
                    onLogOutConform={onLogOutConform}
                    openLogOutModal={openLogOutModal}
                    onLogoutModalClose={onLogoutModalClose}
                    />
                    {children}
                </div>
            </Grid>
        </Grid>


    )
}
