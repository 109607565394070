import { Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { MenuProps } from "../../Styles";
import { LightBorderBlackButton } from "../../styles/ButtonStyles";
import { TbFileExport } from "react-icons/tb";

export const MonthYearFilter = ({
  sites,
  selectedSite,
  handleSelectedSite,
  handleSelectedYear,
  years,
  selectedYear,
  months,
  handleSelectedMonth,
  selectedMonth,
  isSurveillance,
  isBattery,
  selectedEncoder,
  handleEncoder,
  encoder,
  batteryDrop,
  handleBattery,
  selectedBattery,
  handleExport
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item lg={3} sm={12} xs={12} md={3}>
        <Grid
          container
        //className="mt2"
        >
          <Grid item>
            <Typography
              sx={{ textAlign: "center", mt: 2 }}
              className={"headersubhead aligncenter light_text"}
            >
              Sites :
            </Typography>
          </Grid>
          <Grid item lg={8} sm={10} xs={9} md={9}>
            <Select
              //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
              sx={{
                color: "#161616",
                ml: 1,
                minWidth: 100,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                  border: "",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#161616",
                },
              }}
              displayEmpty
              MenuProps={MenuProps}
              className={"headerSelect select_light"}
              value={selectedSite}
              onChange={(e) => handleSelectedSite(e.target.value)}
            >
              <MenuItem value={""}>All</MenuItem>
              {sites ? (
                sites.map((site, index) => (
                  <MenuItem key={index} value={site.organizationId}>
                    {site.organizationName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}>No Data Found</MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={2.4} sm={12} xs={12} md={2.4}>
        <Grid
          container
        //className="mt2"
        >
          <Grid item>
            <Typography
              sx={{ textAlign: "center", mt: 2 }}
              className={"headersubhead aligncenter light_text"}
            >
              Year :
            </Typography>
          </Grid>
          <Grid item lg={8} sm={9} xs={9} md={8}>
            <Select
              sx={{
                color: "#161616",
                ml: 1,
                minWidth: 100,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                ".MuiSvgIcon-root": {
                  fill: "#161616",
                },
              }}
              displayEmpty
              MenuProps={MenuProps}
              className="headerSelect select_light"
              value={selectedYear}
              onChange={(e) => handleSelectedYear(e.target.value)}
            >
              {years.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={2.6} sm={12} xs={12} md={2.6}>
        <Grid
          container
        //className="mt2"
        >
          <Grid item>
            <Typography
              sx={{ textAlign: "center", mt: 2 }}
              className={"headersubhead aligncenter light_text"}
            >
              Month :
            </Typography>
          </Grid>
          <Grid item lg={8} sm={8.5} xs={8.5} md={8}>
            <Select
              sx={{
                color: "#161616",
                ml: 1,
                minWidth: 100,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                ".MuiSvgIcon-root": {
                  fill: "#161616",
                },
              }}
              displayEmpty
              MenuProps={MenuProps}
              className="headerSelect select_light"
              value={selectedMonth}
              onChange={(e) => handleSelectedMonth(e.target.value)}
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      {isSurveillance &&
        <Grid item lg={3.9} sm={12} xs={12} md={3.9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid
            container
          //className="mt2"
          >
            <Grid item>
              <Typography
                sx={{ textAlign: "center", mt: 2 }}
                className={"headersubhead aligncenter light_text"}
              >
                Stream Id :
              </Typography>
            </Grid>
            <Grid item lg={8} sm={7.5} xs={7.5} md={8}>
              <Select
                sx={{
                  color: "#161616",
                  ml: 1,
                  minWidth: 100,
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  ".MuiSvgIcon-root": {
                    fill: "#161616",
                  },
                }}
                displayEmpty
                MenuProps={MenuProps}
                className="headerSelect select_light"
                value={selectedEncoder}
                onChange={(e) => handleEncoder(e.target.value)}
              >
                {encoder.length > 0 ?

                  encoder.map((item, index) => (
                    <MenuItem key={index} value={item.machine_id}>
                      {item.title}
                    </MenuItem>

                  ))

                  : <MenuItem>
                    No Streams Found
                  </MenuItem>
                }
              </Select>
            </Grid>
          </Grid>
        </Grid>
      }
      {isBattery &&
        <Grid item lg={3.9} sm={12} xs={12} md={3.9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid
            container
          //className="mt2"
          >
            <Grid item>
              <Typography
                sx={{ textAlign: "center", mt: 2 }}
                className={"headersubhead aligncenter light_text"}
              >
                Battery Id :
              </Typography>
            </Grid>
            <Grid item lg={8} sm={7.5} xs={7.5} md={8}
            >
              <Select
                sx={{
                  color: "#161616",
                  ml: 1,
                  minWidth: 100,
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  ".MuiSvgIcon-root": {
                    fill: "#161616",
                  },
                }}
                displayEmpty
                MenuProps={MenuProps}
                className="headerSelect select_light"
                value={selectedBattery}
                onChange={(e) => handleBattery(e.target.value)}
              >{batteryDrop.length > 0 ?
                batteryDrop.map((item, index) => (
                  <MenuItem key={index} value={item.battery_id}>
                    {item.battery_name}
                  </MenuItem>
                ))

                : <MenuItem>
                  No Battery Found
                </MenuItem>
                }
              </Select>
            </Grid>
          </Grid>
        </Grid>
      }
      {isSurveillance || isBattery ?
       <Grid item lg={12} sm={12} xs={12} md={12} sx={{ mb: 3, mt: 3 }} 
       style={{ display: 'flex', justifyContent: {lg:'flex-end',md:'flex-end',sm:'left',xs:'left'} }}>
       <LightBorderBlackButton
        // ref={trackingviolationButtonRef}
         disableRipple
         startIcon={<TbFileExport />}
         onClick={ handleExport}
       // onClick={handleExportMenu}
       >
         Export
       </LightBorderBlackButton>
     </Grid>
:
<Grid item lg={3.9} sm={12} xs={12} md={3.9} 
style={{ display: 'flex', justifyContent: {lg:'flex-end',md:'flex-end',sm:'left',xs:'left'} }}>
<LightBorderBlackButton
        // ref={trackingviolationButtonRef}
         disableRipple
         startIcon={<TbFileExport />}
        // onClick={() => handleExport("trackingViolationReport")}
        onClick={handleExport}
       >
         Export
       </LightBorderBlackButton>
        </Grid>
      }
    </Grid>
  );
};
