import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  CustomizedTableCell,
  CustomizedTableRow,
} from "../../styles/TablebButtonStyles";
import { LightBorderButton } from "../../styles/ButtonStyles";
import { IoEyeOutline } from "react-icons/io5";
import { Paginations } from "../../sharable/pagination/Pagination";
export const UnAssignedCameraListTable = ({
  cameraListData,
  singleView,
  encoderView,
  onChange,
  page,
  pageCount,
  handlePreviousHistory
}) => {
  return (
    <Grid Container>
      <Grid item lg={11.5} md={11.5} sm={11.5}>
        <div className="table_Scroll">
          <Table className="table_mob" sx={{ m: 2, width: "100%" }}>
            <TableHead>
              <TableRow>
                <CustomizedTableCell>Stream Id</CustomizedTableCell>
                <CustomizedTableCell>Encoder Name</CustomizedTableCell>
                <CustomizedTableCell>Status</CustomizedTableCell>
                <CustomizedTableCell>Assigned</CustomizedTableCell>
                <CustomizedTableCell>Previous History </CustomizedTableCell>
                <CustomizedTableCell>Choose Location</CustomizedTableCell>
                <CustomizedTableCell>Action</CustomizedTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {cameraListData.map((item, index) => (
                <CustomizedTableRow key={index}>
                                    <CustomizedTableCell>{item.gatewayData?.stream}</CustomizedTableCell>

                  <CustomizedTableCell>{item.title}</CustomizedTableCell>
                  <CustomizedTableCell>
                    {item.gatewayData?.online == false ? (
                      <div className="alert_stage_box alert_critical">
                        <span className="alert_stage_text critical_text">
                          Offline{" "}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* {item.status === 3 ? (
                      <div className="alert_stage_box alert_medium">
                        <span className="alert_stage_text medium_text">
                          {" "}
                          Archive
                        </span>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    {item.gatewayData?.online == true ? (
                      <div className="">
                        <span className="alert_stage_text valid_text">
                          Online{" "}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    {item.is_assigned == 0 ? (
                      <div className="alert_stage_box alert_critical">
                        <span className="alert_stage_text critical_text">
                          No{" "}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* {item.status === 3 ? (
                      <div className="alert_stage_box alert_medium">
                        <span className="alert_stage_text medium_text">
                          {" "}
                          Archive
                        </span>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    {item.is_assigned == 1 ? (
                      <div className="">
                        <span className="alert_stage_text valid_text">
                          Yes{" "}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    <LightBorderButton
                    //  disabled={true}
                       onClick={() => { handlePreviousHistory(item.id) }}
                    >
                      View history
                    </LightBorderButton>
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    <LightBorderButton
                      disabled={false}
                      onClick={() => { singleView(item.id) }}
                    >
                      Choose Location
                    </LightBorderButton>
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    <IconButton
                       onClick={() => encoderView(item.id)}
                    >
                      <IoEyeOutline color="#161616" />
                    </IconButton>
                  </CustomizedTableCell>
                </CustomizedTableRow>
              ))}
            </TableBody>
          </Table>
          <div>
            <Paginations
              pageCount={pageCount}
              page={page}
              onChange={onChange}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
