import React, { useEffect, useState } from 'react'
import { Layout } from '../../layout/Layout'
import { Alert, Box, Chip, CircularProgress, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, Stack, TextField } from '@mui/material';
import { StyledCreateSmallButton } from '../../styles/ButtonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { clearUpdateCheckpointStatus, fetchCheckPoint, fetchCheckPointList, fetchCheckPointType, updateCheckPoint } from '../../../redux/actions/CheckPointActions';
export const CheckPointEdit = () => {
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");
    const [checkPoint, setCheckPoint] = useState({
        checkpoint_type: "",
        check_point_name: "",
        check_point_type_name: "",
        tag_name: [],
        organization_name: '',
        level_name: "",
        site_id: "",
        tag_id: [],
        level_id: "",
        block_id: '',
        block_name: "",
        duration: 0

    });
    const [selectedCheckPoint, setSelectedChekpoint] = useState([])
    const [validationErrors, setValidationErrors] = useState({
        checkPointName: '',
        checkPointTypeId: '',
        duration: ''

    });
    const [checkPointType, setCheckPointType] = useState([]);
    const dispatch = useDispatch();
    const singleCheckPoint = useSelector(state => state.checkpoints.singleCheckPoint);
    const CheckPointTypeData = useSelector(state => state.checkpoints.checkPointType);
    const updateCheckPointStatus = useSelector(state => state.checkpoints.updateCheckPoint);
    const [showAlert, setShowAlert] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        const id = sessionStorage.getItem('checkPointId');
        dispatch(fetchCheckPoint(id));
        dispatch(fetchCheckPointType());
    }, [])
    useEffect(() => {
        if (!singleCheckPoint.isFetching && singleCheckPoint.payload && singleCheckPoint.payload.success === true) {
            const temp = singleCheckPoint.payload.checkpoint_details;
            const templisteners = temp.tag_id.map((item) => parseInt(item))
            let tempCheckPoint = { ...checkPoint }
            tempCheckPoint["tag_id"] = templisteners;
            setCheckPoint(temp, tempCheckPoint);

            setSelectedChekpoint(temp.tag_Data)
        }
        if (!CheckPointTypeData.isFetching && CheckPointTypeData.payload && CheckPointTypeData.payload.success === true) {
            const checkPointType = CheckPointTypeData.payload.data;
            setCheckPointType(checkPointType)
        }
        if (!updateCheckPointStatus.isFetching && updateCheckPointStatus.payload && updateCheckPointStatus.payload.success === true) {

            // dispatch(fetchCheckPointList());
            // navigate(-1,{ state: { refresh: true } })
            setIsUpdating(false);
            window.location.href = '/tracking';

        }
        if (!updateCheckPointStatus.isFetching && updateCheckPointStatus.error) {
            const error = updateCheckPointStatus.errorMessage.message;
            setAlertMessage(error);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                setIsUpdating(false);
                dispatch(clearUpdateCheckpointStatus())

            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [singleCheckPoint, CheckPointTypeData, updateCheckPointStatus])
    const handleChange = (value, key) => {
        let temp = { ...checkPoint };
        temp[key] = value;
        setCheckPoint(temp);
        if (key === 'check_point_name') {
            const valueLength = value.trim().length;
            const nameLengthError =
                valueLength < 2 || valueLength >= 30
                    ? 'Checkpoint name must be between 2 and 30 characters long'
                    : '';
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                checkPointName: nameLengthError,
            }));
        }

    }
    const handleUpdate = (e) => {
        const id = sessionStorage.getItem('checkPointId');
        let data = {
            check_point_name: checkPoint.check_point_name,
            checkpoint_type: checkPoint.checkpoint_type,
            created_user: parseInt(sessionStorage.getItem('id')),
            duration: parseInt(checkPoint.duration)


        };
        e.preventDefault();
        const isEmptyField = Object.values(data).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });

        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });

        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                checkPointName: checkPoint.check_point_name.trim() === '' ? 'This field is mandatory' : '',
            }))
        }
        if (allClear && !isEmptyField) {
            dispatch(updateCheckPoint(id, data));
        };
        setIsUpdating(true)
    }
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                activeSubtab={"one"}

                isCreate={true}
            >
                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handlealertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handlealertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {updateCheckPointStatus.isFetching || singleCheckPoint.isFetching ?
                    <Box
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Box>
                    :
                    <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                        {/* {isUpdating  ?
                        <Grid container >
                            <Grid item container lg={12} md={12} sm={12} sx={{ pt: '20%' }} style={{ justifyContent: 'center' }}>
                                <CircularProgress className="progress" color="warning" />

                            </Grid>
                        </Grid>
                        : */}
                        <>
                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text" : "form_text_light"}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Name Of Check Point</InputLabel>
                                <TextField
                                    className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                                    onChange={(e) => handleChange(e.target.value, 'check_point_name')}
                                    value={checkPoint.check_point_name}
                                    error={!!validationErrors.checkPointName}
                                    helperText={validationErrors.checkPointName}
                                >{checkPoint.check_point_name}

                                </TextField>

                            </Grid>
                            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                                <InputLabel
                                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                    Sites</InputLabel>
                                <Select
                                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                    value={checkPoint.site_id}
                                    disabled
                                    sx={{ backgroundColor: '#d3d3d361' }}

                                >
                                    <MenuItem
                                        value={checkPoint.site_id}>
                                        {checkPoint.organization_name}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text" : "form_text_light"}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Level</InputLabel>
                                <Select className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                    value={checkPoint.level_id}
                                    disabled
                                    sx={{ backgroundColor: '#d3d3d361' }}
                                >
                                    <MenuItem
                                        value={checkPoint.level_id}>
                                        {checkPoint.level_name}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Block</InputLabel>
                                <Select
                                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                    value={checkPoint.block_id}
                                    disabled
                                    sx={{ backgroundColor: '#d3d3d361' }}

                                >
                                    <MenuItem
                                        value={checkPoint.block_id}>
                                        {checkPoint.block_name}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text" : "form_text_light"}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Check Point Type</InputLabel>
                                <Select
                                    className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                    value={checkPoint.checkpoint_type}
                                    onChange={(e) => handleChange(e.target.value, 'checkpoint_type')}

                                >
                                    {checkPointType.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.id}>
                                            {item.point_type_name}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </Grid>

                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                    Bridge/Listener</InputLabel>
                                <Select
                                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                    value={checkPoint.tag_id}
                                    disabled
                                    sx={{ backgroundColor: '#d3d3d361' }}
                                    displayEmpty
                                    renderValue={(selected) =>
                                        <Stack gap={1} direction="row" flexWrap="wrap">


                                            {selectedCheckPoint.length && selectedCheckPoint !== undefined ?
                                                selected?.map(key => <Chip
                                                    key={key}
                                                    label={selectedCheckPoint.find(opt => opt.id == key) ?
                                                        selectedCheckPoint.find(opt => opt.id == key).tag_name : ""}
                                                // onDelete={() =>
                                                //   handleChange(
                                                //     checkPoint.tag_id.filter((item) => item != key),"listenerId"
                                                //     )
                                                // }
                                                // deleteIcon={
                                                //     <CancelIcon
                                                //         onMouseDown={(event) => event.stopPropagation()}
                                                //     />
                                                // }
                                                />)
                                                : <></>
                                            }
                                        </Stack>
                                    }
                                >
                                    {!selectedCheckPoint.length == 0 ? selectedCheckPoint.map((listener, index) => (
                                        <MenuItem
                                            key={index}
                                            value={listener.id}>
                                            {listener.tag_name}
                                        </MenuItem>
                                    )) :
                                        <MenuItem value="">No listener available</MenuItem>
                                    }
                                    {/* <MenuItem
                                        value={checkPoint.tag_id}>
                                        {checkPoint.tag_name}
                                    </MenuItem> */}
                                </Select>

                            </Grid>

                            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text" : "form_text_light"}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 1, }}>
                                    Duration (in minute)</InputLabel>
                                {checkPoint.checkpoint_type == 1 || checkPoint.checkpoint_type == 2 ?
                                    <></> :
                                    checkPoint.checkpoint_type == 3 ?
                                        <FormHelperText>checkpoint type is "all," it affects cleaners only; duration must be greater than 0.</FormHelperText>
                                        :
                                        <FormHelperText>duration must be greater than 0.</FormHelperText>

                                }
                                <TextField
                                    className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                                    placeholder="Duration in minute"
                                    value={checkPoint.checkpoint_type == 1 || checkPoint.checkpoint_type == 2 ? 0 : checkPoint.duration}
                                    onChange={(e) => handleChange(e.target.value, 'duration')}
                                    error={!!validationErrors.duration}
                                    helperText={validationErrors.duration}
                                    type="number"
                                    disabled={checkPoint.checkpoint_type == 1 || checkPoint.checkpoint_type == 2 ? true : false}

                                />
                            </Grid>
                            <Grid item container>
                                <StyledCreateSmallButton
                                    onClick={handleUpdate}
                                    sx={{ mt: 5, width: 150 }} variant="contained"
                                    disableRipple>
                                    Update
                                </StyledCreateSmallButton>
                            </Grid>
                        </>
                        {/* } */}
                    </Grid>
                }
            </Layout>
        </>
    )
}