import React, { useEffect, useState } from 'react';
import { Layout } from '../layout/Layout';
import { subDays } from "date-fns";
import { CircularProgress, Grid, Select, Typography } from '@mui/material';
import { MenuProps } from '../Styles';
import { AlertFilter } from './AlertFilter';
import { AlertTable } from '../sharable/tables/AlertTable';
import { useDispatch } from 'react-redux';
import { fetchAlertList } from '../../redux/actions/AlertActions';
import { AlertData } from '../../assets/data/data';
import {
    GmtToLocalClockTime, alertTimeOnly, convertMinutesToTime, dateNeglector, dateOnly, dateSearchConverter, extractDatefromTimeStamp, importDateTimeConverter,
    sensorDatefieldConvertorDateOnly,
    timeOnly, timeStampToTime,
    utcDateTimeToLocalDateTime
} from '../convertors/TimeConvertor';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

export const AlertList = () => {
    const dispatch = useDispatch();
    const alerListData = useSelector(state => state.alert.alertList);
    const [alertList, setAlertList] = useState([]);
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("alert");
    const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 20));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [selectedSite, setSelectedSite] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedStage, setSelectedStage] = useState('');
    const [searchWord, setSearchWord] = useState('');
    const [isSentry, setIsSentry] = useState(false);
    const [isPatrol, setIsPatrol] = useState(false);
    const [isCleaner, setIsCleaner] = useState(false);

    const [isSensor, setIsSensor] = useState(false);
    const [isVa, setIsVa] = useState(false);

    const [isDevice, setIsDevice] = useState(false)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const itemsPerPage = 20;
    useEffect(() => {
        const stage_of_alert = sessionStorage.getItem('alert_stage')
        if (stage_of_alert !== null && stage_of_alert !== null) {
            setSelectedType(stage_of_alert);
        }
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            to_date: dateSearchConverter(selectedEndDate),
            from_date: dateSearchConverter(selectedStartDate),
            type: stage_of_alert !== null && stage_of_alert !== null ? stage_of_alert : 'patrol',
            limit: limit,
            start: start
        }
        //  dispatch(fetchAlertList(data));
    }, [])
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            to_date: dateSearchConverter(selectedEndDate),
            from_date: dateSearchConverter(selectedStartDate),
            type: selectedType,
            stage: selectedStage,
            site_id: selectedSite,
            searchword: searchWord,
            start: start,
            limit: limit
        }
        if (selectedType != "") {
            dispatch(fetchAlertList(data));
        }
        if (selectedType == 'patrol') {
            setIsPatrol(true);
        }
        else {
            setIsPatrol(false);
        }
        if (selectedType == 'sentry') {
            setIsSentry(true);
        }
        else {
            setIsSentry(false);
        }
        if (selectedType == 'device') {
            setIsDevice(true);
        }
        else {
            setIsDevice(false);
        }
        if (selectedType == 'sensor') {
            setIsSensor(true);
        }
        else {
            setIsSensor(false);
        }
        if (selectedType == 'camera') {
            setIsVa(true)
        }
        if (selectedType == 'cleaner') {
            setIsCleaner(true)
        }
        else {
            setIsVa(false)

        }
    }, [selectedStage, selectedType, selectedSite, selectedEndDate, selectedStartDate, searchWord, currentPage]);
    const dateFormat = (date) => {
        let formattedDate = dayjs(date);
        return formattedDate.format("MM/DD/YYYY")
    }
    useEffect(() => {
        if (!alerListData.isFetching && alerListData.payload && alerListData.payload.success === true) {
            const temp = alerListData.payload.data;
            const tempPagination = alerListData.payload.pagination;
            const templist = temp.map((item) => {
                return {
                    id: item.id,
                    //   date:item.duty_type === 0 ? : item.date,
                    tag_name: item.duty_type === 0 ? item.infantName : item.infant_name,
                    violationtype: item.alert_type,
                    site: item.site_name,
                    violation: item.message,
                    datedata: item.duty_type == 0 ? dateOnly(item.timestamp) : dateOnly(item.date),
                    isVaDate: item.duty_type == 4 ? extractDatefromTimeStamp(item.timestamp) : 0,
                    trackDate: item.duty_type == 1 || item.duty_type == 2 ? sensorDatefieldConvertorDateOnly(item.created_at) : "",

                    duty_type: item.duty_type,
                    start_time: item.sentry_starttime == 0 || item.sentryreportid == 0 ? 0 : alertTimeOnly(item.sentry_starttime),
                    end_time: item.sentry_endtime == 0 || item.sentryreportid == 0 ? 0 : alertTimeOnly(item.sentry_endtime),
                    time: item.duty_type === 0 ? timeOnly(item.timestamp) : 0,
                    contractorname: item.contractorname,
                    assetName: item.infantName,
                    listenerName: item.listenerName,
                    sensor_name: item.duty_type == 5 ? item.sensor.sensor_name : "",
                    camera_name: item.duty_type == 4 ? item.camera_details ? item.camera_details?.title : "NA" : "",
                    isVatime: item.duty_type == 4 ? alertTimeOnly(item.timestamp) : 0,
                    tag: item.duty_type == 1 || item.duty_type == 2 ? item.infant_name : "",
                    patrol_end_time: item.duty_type == 2 || item.duty_type == 6 && item.RouteData && item.RouteData.routeendtime ? GmtToLocalClockTime(item.RouteData?.routeendtime) : "NA",
                    patrol_start_time: item.duty_type == 2 || item.duty_type == 6 && item.RouteData && item.RouteData.routestarttime ? GmtToLocalClockTime(item.RouteData?.routestarttime) : "NA",


                }
            });
            const tempData = templist.map((item) => {
                return {
                    ...item,
                    date: dateFormat(item.datedata),
                    //trackDate:dateFormat(item.trackDate)

                }
            })
            setAlertList(tempData);
            setPageCount(tempPagination.total_pages)
        }
    }, [alerListData])
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleEndDateChange = (date) => {


        setSelectedEndDate(date);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);

    }
    const handleSelectedSite = (site) => {
        setSelectedSite(site);
    }
    const handleSelectedType = (data) => {
        setSelectedType(data);
    }
    const handleSelectedStage = (data) => {
        setSelectedStage(data);
    }
    const handleSingleAlertView = (id, type) => {
        sessionStorage.setItem("alertViewId", id);
        sessionStorage.setItem("alert_type", type);
        // sessionStorage.setItem("alert_type", type);
        sessionStorage.setItem('alert_stage', selectedType);
        window.location.href = `/alert/view/${type}/${id}`;
    }
    const onSearchWordChange = (data) => {
        setSearchWord(data);
    }
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    }
    return (
        <Layout
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            searchWord={searchWord}
            handleEndDateChange={handleEndDateChange}
            handleStartDateChange={handleStartDateChange}
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"alert"}
            activeMenuKey={"Alerts"}
            placeHolder={"Search Violation"}
            onSearchWordChange={onSearchWordChange}

        >
            <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
                {/* <Grid container sx={{ mt: 5, ml: 3 }}>
                    <Grid item lg={2} sm={12} xs={12} md={2}>
                        <Typography sx={{ textAlign: 'left', mt: 2 }}
                            className={isDarkMode ? "headersubhead  chartheading_color" : "headersubhead  light_text"}>
                            List Of Users
                        </Typography>
                    </Grid>
                </Grid> */}
                <AlertFilter
                    isDarkMode={isDarkMode}
                    selectedSite={selectedSite}
                    selectedType={selectedType}
                    selectedStage={selectedStage}
                    handleSelectedSite={handleSelectedSite}
                    handleSelectedType={handleSelectedType}
                    handleSelectedStage={handleSelectedStage}
                />
                {alerListData.isFetching ?
                    <Grid item lg={12} md={12} sm={12} xs={12} container
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Grid>
                    :
                    <AlertTable
                        isDarkMode={isDarkMode}
                        tableData={alertList}
                        handleView={handleSingleAlertView}
                        isPatrol={isPatrol}
                        isSentry={isSentry}
                        isDevice={isDevice}
                        isSensor={isSensor}
                        isVa={isVa}
                        isCleaner={isCleaner}
                        pageCount={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}

                    />

                }
            </div>
        </Layout>

    )
}