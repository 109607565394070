import { API } from "./Api";
const alert='/alert';
const dashboard ='/dashboard'
const report = '/report'
export const fetchAlertListData = async (data) => {
    const start = data && data.start !== undefined ? data.start : 0;
    const limit = data && data.limit !== undefined ? data.limit : 20;
   // const start=data.start;
   // const limit=data.limit;
   const site_id = data && data.site_id !== undefined ? data.site_id :'';
    const type = data && data.type !== undefined ? data.type : 'patrol';
    const searchword= data && data.searchword !== undefined ? data.searchword : '';
    const stage = data && data.stage !== undefined ? data.stage : '';
    const from_date = data && data.from_date !== undefined ? data.from_date : '';
    const to_date = data && data.to_date !== undefined ? data.to_date : '';
    const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
    const is_download = data && data.is_download !== undefined ? data.is_download : false;

   const response = await API.get(`${alert}/${type}?start=${start}&limit=${limit}&to_date=${to_date}&from_date=${from_date}&search=${searchword}&site_id=${site_id}&stage=${stage}&org_id=${org_id}&is_download=${is_download}`);
   return response.data;
}
export const getSinglePatrolDetails = async (id) => {
   const response = await API.get(`${alert}/patrol/${id}`);
   return response.data;
}
export const getSingleSentryDetails = async (id) => {
    const response = await API.get(`${alert}/sentry/${id}`);
    return response.data;
 }
 export const getSingleDeviceDetails = async (id) => {
   const response = await API.get(`${alert}/device/${id}`);
   return response.data;
}
export const getSingleSensorDetails = async (id) => {
   const response = await API.get(`${alert}/sensor/${id}`);
   return response.data;
}
export const getSingleCameraDetails = async (id) => {
  const response = await API.get(`${alert}/camera/${id}`);
  return response.data;
}
export const getSingleCleanerDetails = async (id) => {
  const response = await API.get(`${alert}/cleaner/${id}`);
  return response.data;
}
 export const assignAlertDetails = async (body) => { 
   const response = await API.post(`${alert}/verify`,body);
   return response.data;
}
export const fetchGuageData = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));

   const searchword= data && data.searchword !== undefined ? data.searchword : '';
   const from_date = data && data.from_date !== undefined ? data.from_date : '';
   const to_date = data && data.to_date !== undefined ? data.to_date : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
   const type = sessionStorage.getItem('organisation_type_data') == null ? 2 : parseInt(sessionStorage.getItem('organisation_type_data'))

  const response = await API.get(`${dashboard}?&to_date=${to_date}&from_date=${from_date}&search=${searchword}&org_id=${org_id}&site_id=${site_id}&type=${type}`);
  return response.data;
}
export const fetchAlertTrendDetails = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));

   const duty_type= data && data.duty_type !== undefined ? data.duty_type : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
   const timestamp = Intl.DateTimeFormat().resolvedOptions().timeZone;
 //{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
  const response = await API.get(`${dashboard}/alerttrends?duty_type=${duty_type}&org_id=${org_id}&site_id=${site_id}&timestamp=${timestamp}`);
  return response.data;


}

export const getAditTrailListData = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
   const searchword= data && data.searchword !== undefined ? data.searchword : '';
   const stage= data && data.stage !== undefined ? data.stage : '';
   const from_date = data && data.from_date !== undefined ? data.from_date : '';
   const to_date = data && data.to_date !== undefined ? data.to_date : '';
   const start = data && data.start !== undefined ? data.start : 0;
   const limit = data && data.limit !== undefined ? data.limit : 20;
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
   const role_id = sessionStorage.getItem('role_id');
 //{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
  const response = await API.get(`audittrail?start=${start}&limit=${limit}&org_id=${org_id}&search=${searchword}&from_date=${from_date}&to_date=${to_date}&stage=${stage}&site_id=${site_id}&role_id=${role_id}`);
  return response.data;
}
export const getTrackingReportDetails = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
   const month= data && data.month !== undefined ? data.month : '';
   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   const year= data && data.year !== undefined ? data.year : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
  
 //{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
  const response = await API.get(`${report}/tracking?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
  return response.data;


}
export const getClenerAlertDetails = async (data) => {
  const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
  const month= data && data.month !== undefined ? data.month : '';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const year= data && data.year !== undefined ? data.year : '';
  const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
 
//{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
 const response = await API.get(`${report}/cleaner?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
 return response.data;


}
export const getTopPersonnelAlertDetails = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
   const month= data && data.month !== undefined ? data.month : '';
   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

   const year= data && data.year !== undefined ? data.year : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
 //{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
  const response = await API.get(`${report}/personnel?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
  return response.data;


}
export const getTimeBasedReportDetails = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
   const month= data && data.month !== undefined ? data.month : '';
   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

   const year= data && data.year !== undefined ? data.year : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
 //{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
  const response = await API.get(`${report}/trackingbasedtime?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
  return response.data;


}
export const getTagBatteryAlertsDetails = async (data) => {
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
   const month= data && data.month !== undefined ? data.month : '';
   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

   const year= data && data.year !== undefined ? data.year : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
 //{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
  const response = await API.get(`${report}/tagreport?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
  return response.data;


}
export const getSensorReportDetails = async (data) => {
  const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
  const month= data && data.month !== undefined ? data.month : '';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const year= data && data.year !== undefined ? data.year : '';
  const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
//{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
 const response = await API.get(`${report}/sensorreport?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
 return response.data;


}
export const getVaAlertDetails = async (data) => {
  const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
  const month= data && data.month !== undefined ? data.month : '';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const year= data && data.year !== undefined ? data.year : '';
  const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
//{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
 const response = await API.get(`${report}/vaalert?year=${year}&month=${month}&org_id=${org_id}&site_id=${site_id}&timezone=${timezone}`);
 return response.data;


}
export const getSurveillancePerformanceDetails = async (data) => {
  const month= data && data.month !== undefined ? data.month : '';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const year= data && data.year !== undefined ? data.year : '';
  const machine_id = data && data.machine_id !== undefined ? data.machine_id : '';
//{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
 const response = await API.get(`${report}/survillence?year=${year}&month=${month}&machine_id=${machine_id}&timezone=${timezone}`);
 return response.data;


}
export const getEncoderDropdownDeatils = async (data) => {
  const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
  const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
//{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
 const response = await API.get(`/videosoft/encoderdropdown?site_id=${site_id}&org_id=${org_id}`)
 return response.data;


}
export const getBatteryPerformanceDetails = async (data) => {
  const month= data && data.month !== undefined ? data.month : '';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const year= data && data.year !== undefined ? data.year : '';
  const battery_id = data && data.battery_id !== undefined ? data.battery_id : '';
//{{api_url}}/{{api_version}}/dashboard/duty_type?org_id=1
const response = await API.get(`${report}/batteryperformance?year=${year}&month=${month}&battery_id=${battery_id}&timezone=${timezone}`)


// const response = await API.get(`${report}/batteryperformance?year=${year}&month=${month}&battery_id=${battery_id}`)
 return response.data;


}