import { Alert, CircularProgress, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cleardeleteEncoderStatus, deleteEncoder, getSingleEncoder } from "../../../redux/actions/SurveillanceAction";
import { Layout } from "../../layout/Layout";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { DeleteModal } from "../../Modals/DeleteModal";
export const ViewEncoder = ({ }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const EncoderDetails = useSelector(
    (state) => state.surveillance.SingleEncoder
  );
  const DeleteEncoderData = useSelector(
    (state) => state.surveillance.deleteEncoder
  );
  const [encoderData, setEncoderData] = useState({});
  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [deleteEncoderName, setDeleteEncoderName] = useState("");
  const [deleteSensorId, setDeleteSensorId] = useState(0);
  const [subStream, setSubStream] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  useEffect(() => {
    if (id != null) {
      dispatch(getSingleEncoder(id));
    }
  }, [id]);
  useEffect(() => {
    if (
      !EncoderDetails.isFetching &&
      EncoderDetails.payload &&
      EncoderDetails.payload.success === true
    ) {
      const tempEncoder = EncoderDetails.payload.data;
      const tempSubStream = EncoderDetails.payload.data.substream;
      // const tempPagination = EncoderDetails.payload.pagination;
      setEncoderData(tempEncoder);
      setSubStream(tempSubStream);
    }
  }, [EncoderDetails]);
  useEffect(() => {
    if (!DeleteEncoderData.isFetching && DeleteEncoderData.payload && DeleteEncoderData.payload.success === true) {

      setOpenDeleteModal(false);
      sessionStorage.setItem('surveillanceTab', "s1")

      window.location.href = '/surveillance';
    }
    if (!DeleteEncoderData.isFetching && DeleteEncoderData.error) {
      const error = DeleteEncoderData.errorMessage.message;
      setAlertMessage(error);
      setOpenDeleteModal(false);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(cleardeleteEncoderStatus())

      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }
    , [DeleteEncoderData])
  const handleDelete = (id, name) => {
    setDeleteEncoderName(name);
    setOpenDeleteModal(true);
    //  setDeleteSensorId(id);
  }



  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal)
  }
  const onDeleteConfirm = () => {
    const data = {
      id: id,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
    }
    dispatch(deleteEncoder(data));
  }
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
  };
  const handleEdit = (id) => {
    window.location.href = `/surveillance/encoder/edit/${id}`;
  }
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container>
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handlealertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handlealertClose}
              severity="error"
              variant="outlined"
              sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

            >
              {alertMessage}
            </Alert>
          </Snackbar>
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            openDeleteModal={openDeleteModal}
            ModalHead={'Delete Encoder'}
            ModalBody={deleteEncoder}
            onDeleteConfirm={onDeleteConfirm}
          />

          <Grid item lg={6} md={6} xs={12} sm={12} sx={{ mb: 2 }}>
            <Typography className="device_view_head">
              {encoderData.title}
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
            sx={{ mb: 2 }}
          >
            <IconButton
              onClick={() => handleEdit(encoderData.id)}
            >
              <MdOutlineEdit color="black" />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(encoderData.id)}
            >
              <RiDeleteBin6Line color="#C61A1A" />
            </IconButton>
          </Grid>
        </Grid>
        {EncoderDetails.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <>

            <div className="device_view">
              <Grid container>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="rowdiv borderbottom rowdiv_mr"
                  // style={{ paddingBottom: "32px" }}
                  >
                    <Typography className="device_view_text label_color rowdiv_label">
                      Title
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text">
                      {encoderData.title}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Sensor Name
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.SensorData?.sensor_name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="rowdiv borderbottom rowdiv_mr"
                  // style={{ paddingBottom: "32px" }}
                  >
                    <Typography className="device_view_text label_color rowdiv_label">
                      Battery Name
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text">
                      {encoderData.batteryData?.battery_name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Status
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData?.gatewayData?.online == false ? (
                        <div className="alert_stage_box alert_critical">
                          <span className="alert_stage_text critical_text">
                            Offline{' '}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {encoderData?.gatewayData?.online == true ? (
                        <div className="">
                          <span className="alert_stage_text valid_text">
                            Online{" "}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="rowdiv borderbottom rowdiv_mr"
                  // style={{ paddingBottom: "32px" }}
                  >
                    <Typography className="device_view_text label_color rowdiv_label">
                      Gateway Name
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text">
                      {encoderData.gatewayData?.title}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Stream Id
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.gatewayData?.stream}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="rowdiv borderbottom rowdiv_mr"
                  // style={{ paddingBottom: "32px" }}
                  >
                    <Typography className="device_view_text label_color rowdiv_label">
                      Camera Id
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text">
                      {encoderData.machine_id}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Battery Id
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.batteryData?.battery_id}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="rowdiv borderbottom rowdiv_mr"
                  // style={{ paddingBottom: "32px" }}
                  >
                    <Typography className="device_view_text label_color rowdiv_label">
                      Site
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text">
                      {encoderData.organization_name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Level
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.level_name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_mr">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Block
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.block_name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="rowdiv borderbottom rowdiv_ml"
                  // style={{ paddingBottom: "32px" }}
                  >
                    <Typography className="device_view_text label_color rowdiv_label">
                      Distance 1
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text">
                      {encoderData.SensorData?.distance1}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv  rowdiv_mr">
                    <Typography className="device_view_text label_color rowdiv_label">
                      Distance 2
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.SensorData?.distance2}
                    </Typography>
                  </div>
                </Grid>
                <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv  rowdiv_mr">
                    <Typography className="device_view_text label_color rowdiv_label">
                      IOT Device Type
                    </Typography>
                    <Typography className="device_view_text content_color rowdiv_text ">
                      {encoderData.IotData}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            {subStream.length ?
              <>
                <Grid item lg={6} md={6} xs={12} sm={12} sx={{ mb: 2, mt: 3 }}>
                  <Typography className="device_view_head">
                    {" Sub Stream Detailes "}
                  </Typography>
                </Grid>
                <div className="device_view">
                  <Grid container>
                    <Grid item sx={{ pb: 7, display: 'flex', flexDirection: 'column', }}
                      lg={6} md={6} sm={12} xs={12} >

                      <Typography className="device_view_text label_color rowdiv_label">
                        Sub Stream Id
                      </Typography>
                      {subStream?.map((item) => (
                        <Typography className="device_view_text content_color rowdiv_text"
                          sx={{ ml: 2, mt: 3 }}>
                          {item.stream}
                        </Typography>
                      ))
                      }
                    </Grid>

                    <Grid item sx={{ pb: 7, display: 'flex', flexDirection: 'column', }}
                      lg={6} md={6} sm={12} xs={12}>
                        <Typography className="device_view_text label_color rowdiv_label">
                          Stream Type
                        </Typography>
                        {subStream?.map((item) => (

                          <Typography className="device_view_text content_color rowdiv_text "
                          sx={{ ml: 2, mt: 3 }}>
                            {item.type == 1 ? "Office Stream" : "Gate Stream"}
                          </Typography>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </>
              : <></>
            }
          </>
        }

      </div>
    </Layout >
  );
};
