import { Alert, Box, Button, CircularProgress,IconButton, Snackbar, Table, TableBody, TableHead, TableRow, Tooltip,
     Typography } from "@mui/material";
import React from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../styles/TablebButtonStyles";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiTag } from "react-icons/fi";
import '../../sharable/tables/Tables.css';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearDeleteCheckpointStatus, deleteCheckPoint, fetchCheckPointList } from "../../../redux/actions/CheckPointActions";
import { useState } from "react";
import { Paginations } from "../../sharable/pagination/Pagination";
import Modal from '@mui/material/Modal';
import { assignListener, unAssignListener } from "../../../redux/actions/ListenerActions";
import { DeleteModal } from "../../Modals/DeleteModal";
import { BorderButton, ConfirmButton } from "../../styles/ButtonStyles";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { ListenerAssignModal } from "../../Modals/ListenerAssignModal";
export const CheckPointListTable = ({ isDarkMode, selectedSites,selectedType }) => {
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')
    const checkPointListData = useSelector(state => state.checkpoints.checkPointList);
    const deleteCheckPointStatus = useSelector(state => state.checkpoints.deleteCheckPoint);
    const unAssignListenerStatus = useSelector(state => state.listeners.unAssign);
    const assignListenerStatus = useSelector(state => state.listeners.assign);
    const { refresh } = useSelector((state) => state.checkpoints.checkPointList);
    const [deleteCheckPointName, setDeleteCheckPointName] = useState('');
    const [CheckPointTableData, setCheckPointTableData] = useState([]);
    const [assignTag, setAssignTag] = useState(1)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openModal, setOpenModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTagItem, setSelectedTagItem] = useState({});
    const [selectedListener, setSelectedListener] = useState('')
    const itemsPerPage = 20;
    const [showListnerStatus,setShowListenerStatus] = useState(false);
    const[listenerStatus,setLisnertStatus] = useState("");
    useEffect(() => {
        if (!checkPointListData.isFetching && checkPointListData.payload && checkPointListData.payload.success === true) {
            const checkPoints = checkPointListData.payload.data;
            const tempPaginationData = checkPointListData.payload.pagination;

            const transformData = (input) => {
                return input.map((item) => {
                    const newItem = { ...item };
                    if (newItem.checkpoint_type === 3) {
                        newItem.check_point_type_name = ['Sentry', 'Patrol','Cleaner'];
                    } else if (newItem.checkpoint_type === 1) {
                        newItem.check_point_type_name = ['Sentry'];
                    }
                    else if (newItem.checkpoint_type === 2) {
                        newItem.check_point_type_name = ['Patrol'];
                    }
                    else if (newItem.checkpoint_type === 4) {
                        newItem.check_point_type_name = ['Cleaner'];
                    }
                    return newItem;
                });
            };
            const transformedData = transformData(checkPoints);
            setPageCount(tempPaginationData.total_pages)
            setCheckPointTableData(transformedData)
        }
    }, [checkPointListData])
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            start: start,
            limit: limit,
            site_id: selectedSites,
            type:selectedType,
        }
        dispatch(fetchCheckPointList(data));
    }, [selectedSites,selectedType,currentPage]);
    useEffect(() => {
        if (!unAssignListenerStatus.isFetching && unAssignListenerStatus.payload && unAssignListenerStatus.payload.success === true) {
            const msg = unAssignListenerStatus.payload.message;
            setLisnertStatus(msg);
            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {
                start: start,
                limit: limit
            }
            dispatch(fetchCheckPointList(data));
            setOpenModal(false);
        }
        if (!assignListenerStatus.isFetching && assignListenerStatus.payload && assignListenerStatus.payload.success === true) {
            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {
                start: start,
                limit: limit
            }
            dispatch(fetchCheckPointList(data));
            setOpenModal(false);
        }

    }, [unAssignListenerStatus, assignListenerStatus])
    useEffect(() => {
        if (!deleteCheckPointStatus.isFetching && deleteCheckPointStatus.payload && deleteCheckPointStatus.payload.success == true) {
            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {
                start: start,
                limit: limit
            }
            dispatch(fetchCheckPointList(data));
            setOpenDeleteModal(false);
        }
        if (!deleteCheckPointStatus.isFetching && deleteCheckPointStatus.error) {
            const error = deleteCheckPointStatus.errorMessage.message;
            setAlertMessage(error);
            setOpenDeleteModal(false);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearDeleteCheckpointStatus())
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [deleteCheckPointStatus])
    // useEffect(() => {
    //     const start = (currentPage - 1) * itemsPerPage;
    //     const limit = itemsPerPage;
    //     let data = {
    //         start: start,
    //         limit: limit
    //     }
    //     dispatch(fetchCheckPointList(data));
    // }, [currentPage]);
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleListener = (item) => {
        setAssignTag(item.tag_assign)
        setSelectedListener(item.tag_name)
        setSelectedTagItem(item)
        if (assignTag == 1) {
            setOpenModal(true);
        }
        else {
            setOpenModal(true)
        }
    }
    const handleModalClose = () => setOpenModal(false);
    const onConform = () => {
        let data = {
            check_point_id: selectedTagItem.id,
            tag_id: selectedTagItem.tag_id
        }
        if (assignTag == 1) {
            dispatch(unAssignListener(data))
        }
        else {
            dispatch(assignListener(data));
        }

    }
    const onReject = () => {
        setOpenModal(false);
    }
    const handleEdit = (id) => {
        sessionStorage.setItem('checkPointId', id);
        window.location.href = '/tracking/checkpoint/edit';
    }
    const handleDelete = (id, name) => {
        sessionStorage.setItem('deletecheckPointId', id);
        setDeleteCheckPointName(name);
        setOpenDeleteModal(true);
    }
    const handleDeleteModal = () => {
        setOpenDeleteModal(false);
    }
    const onDeleteConfirm = () => {
        const data={
            created_user: parseInt(sessionStorage.getItem('id')),
            id :sessionStorage.getItem('deletecheckPointId'),
            org_id : parseInt(sessionStorage.getItem('org_Id')),


        }
        dispatch(deleteCheckPoint(data));
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <div className='table_Scroll '>
           
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <ListenerAssignModal
                openModal={openModal}
                handleModalClose={handleModalClose}
                assignTag={assignTag}
                selectedListener={selectedListener}
                onConform={onConform}
                onReject={onReject}

            />
            <DeleteModal
                handleDeleteModal={handleDeleteModal}
                openDeleteModal={openDeleteModal}
                ModalHead={'Delete CheckPoint'}
                ModalBody={deleteCheckPointName}
                onDeleteConfirm={onDeleteConfirm}
            />

          { checkPointListData.isFetching  ? 
          <Box
          sx={{ display: 'flex', justifyContent: 'center',mt:10 }}>
             <CircularProgress sx={{ color: '#FFAC0B' }} />
         </Box>
         :
          CheckPointTableData !== 'undefined' && CheckPointTableData.length ?
                <>
                    <Table className="table_mob" sx={{ mt: 2, mb: 2, width: "100%" }} >
                        <TableHead>
                            <TableRow>
                                <CustomizedTableCell>Check Points</CustomizedTableCell>
                                <CustomizedTableCell>Check Point Type</CustomizedTableCell>
                                <CustomizedTableCell>Sites</CustomizedTableCell>
                                <CustomizedTableCell>Block</CustomizedTableCell>
                                <CustomizedTableCell>Level</CustomizedTableCell>
                                <CustomizedTableCell>Bridge/Listener</CustomizedTableCell>
                                <CustomizedTableCell >Actions</CustomizedTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {CheckPointTableData.map((item, index) =>
                                <CustomizedTableRow key={index}>
                                    <CustomizedTableCell>{item.check_point_name}</CustomizedTableCell>
                                    <CustomizedTableCell >
                                        <div className="tableCellStyle person_route_table_div">
                                            {item.check_point_type_name
                                                .map((obj, index) =>
                                                    <span className="table_text_div" key={index}>{obj}{'  '}
                                                        {/* {index == 1  && index < item.chechpointtype.length - 1 && <br />} */}
                                                    </span >
                                                )}
                                        </div>
                                    </CustomizedTableCell>
                                    <CustomizedTableCell>{item.organization_name}</CustomizedTableCell>
                                    <CustomizedTableCell>{item.block_name}</CustomizedTableCell>
                                    <CustomizedTableCell>{item.level_name}</CustomizedTableCell>
                                    <CustomizedTableCell>{item.tag_name}</CustomizedTableCell>
                                    <CustomizedTableCell >
                                        <Tooltip title={item.tag_assign === 1 ? 'Unassign Listener' : 'Assign Listener'}>
                                            <IconButton onClick={() => handleListener(item)}><FiTag color="#C61A1A" /></IconButton>
                                        </Tooltip>
                                        <IconButton onClick={() => handleEdit(item.id)}><MdOutlineEdit color="black" /></IconButton>
                                        <IconButton onClick={() => handleDelete(item.id, item.check_point_name)}><RiDeleteBin6Line color="#C61A1A" /></IconButton>
                                    </CustomizedTableCell>
                                </CustomizedTableRow>
                            )}
                        </TableBody>

                    </Table>
                    <div>
                        <Paginations
                            isDarkMode={isDarkMode}
                            pageCount={pageCount}
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </div>
                </> : <>No data found</>
            
                
            }
        </div>
    )
}