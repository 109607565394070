export const GetMarkerDetails =(data)=>{
    let markerList = [];
    const convertUtcToLocalTime=(lastseenat)=>{
        const dateObject = new Date(lastseenat);
        const localTime = dateObject.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        })
        return localTime
    }

    for (let index = 0; index < data.length; index++) {
        const tag = data[index];

        const markerIndex = markerList.findIndex((e) => { return e.listenerId === tag.tag?.listenerId });
        
        const tagInfo = {
            contractorname: tag?.contractorname,
            checkpoint: tag.tag?.listenerName,
            lastseenat: convertUtcToLocalTime(tag.tag?.timestamp * 1000),
            userType: tag?.user_type,

        }
        if (markerIndex === -1) {
            markerList.push({
                listenerId: tag.tag?.listenerId,
                listenerName: tag.tag?.listenerName,
                tags: [tagInfo],
                latitude: tag.tag?.latitude,
                longitude: tag.tag?.longitude,
                checkpoint: tag.tag?.listenerName,
                contractorname: tag?.contractorname,

            })
        } else {
            markerList[markerIndex]['tags'].push(tagInfo)
        }
    }
    return markerList;
} 