import React, { useEffect, useState } from 'react';

export const Stream = ({camera,auth,videoUrl}) => {
  const [streamData,setStreamData] = useState({});
  const[baseUrl,setBaseUrl]=useState("");
  const[videoSoftAuth,setVideoSoftAuth]=useState("");

  
// let videoUrl = process.env.REACT_APP_VIDEO_SOFT
  useEffect(() => {
    if(camera != null){
      setStreamData(camera)
      setBaseUrl(videoUrl);
      setVideoSoftAuth(auth)
    }
  }, [camera]);

  return (
    <div>
      { streamData  && 
      <img src={`${baseUrl}machineid=${streamData?.machine_id}&streamid=${streamData?.stream_id}&auth=${videoSoftAuth}`} 
      //alt="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
      alt={`${baseUrl}machineid=${streamData.machine_id}&ampstreamid=${streamData.stream_id}&auth=${videoSoftAuth}`}
    // alt="Live Stream Not Available"
     width="100%" height="100%" class="shrinkToFit"></img>
    }
    {/* <img src="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
      alt="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
       width="100%" height="100%" class="shrinkToFit"></img> */}
    </div>
  );
};