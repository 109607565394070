import { IconButton, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../../styles/TablebButtonStyles";
import { IoEyeOutline } from "react-icons/io5";
export const CleanerHistoryTable = ({ row }) => {
    const handleView =(id)=>{
        window.location.href= `/tracking/cleaner/history/view/${id}`
    }
    return (
        <div className='table_Scroll ml2back'>
            <Table className="table_mob" sx={{ width: "100%" }} >
                <TableHead>
                    <TableRow>
                        <CustomizedTableCell>Date</CustomizedTableCell>

                        <CustomizedTableCell>Start Time & End Time</CustomizedTableCell>
                        <CustomizedTableCell>First Seen</CustomizedTableCell>
                        <CustomizedTableCell>Last Seen</CustomizedTableCell>
                        <CustomizedTableCell>Site</CustomizedTableCell>
                        <CustomizedTableCell>Route</CustomizedTableCell>

                        <CustomizedTableCell>Checkpoints</CustomizedTableCell>
                        <CustomizedTableCell>View</CustomizedTableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {row.map((item, index) => (
                        <CustomizedTableRow key={index}>
                            <CustomizedTableCell >{item.date}</CustomizedTableCell>
                            
                            <CustomizedTableCell>
                                {item.patrol_start_time} To {item.patrol_end_time}
                            </CustomizedTableCell>
                            <CustomizedTableCell  >{item.first_seen}</CustomizedTableCell>
                            <CustomizedTableCell  >{item.last_seen}</CustomizedTableCell>
                            <CustomizedTableCell  >{item.site}</CustomizedTableCell>
                            <CustomizedTableCell  >
                                <div className="cleaner_route_div">
                                <span className="route_text_div" >{item.routename}</span>
                                </div>
                            </CustomizedTableCell>
                            <CustomizedTableCell  >
                                <div className="person_route_table_div">
                                    {item.checkpoints.map((obj, index) =>
                                        <span className="route_text_div" key={index}>{obj.checkpoint}{'  '}</span>
                                    )}
                                </div>

                            </CustomizedTableCell>
                            <CustomizedTableCell>
                                <IconButton
                                    onClick={() => handleView(item.id)}
                                >
                                    <IoEyeOutline color="#161616" />
                                </IconButton>
                            </CustomizedTableCell>

                        </CustomizedTableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}