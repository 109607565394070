import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { MdOutlineAddToPhotos } from "react-icons/md";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  cleareditEncoderStatus,
  editEncoder,
  fetchBatteryDropdown,
  fetchCameraDropdown,
  fetchIotDeviceList,
  fetchSensorDropdown,
  getSingleEncoder,
} from "../../../redux/actions/SurveillanceAction";
import { StyledCreateSmallButton, YellowBorderButton } from "../../styles/ButtonStyles";
export const EditEncoder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const EncoderDetails = useSelector(
    (state) => state.surveillance.SingleEncoder
  );
  const cameraList = useSelector((state) => state.surveillance.cameraDropdown);
  const sensorList = useSelector((state) => state.surveillance.sensorDropdown);
  const IotList = useSelector((state) => state.surveillance.IotList);

  const batteryList = useSelector(
    (state) => state.surveillance.batteryDropdown
  );
  const editEncoderStatus = useSelector(
    (state) => state.surveillance.editEncoder
  );
  const [iotDrop, setIotDrop] = useState([]);
  const [selectedIot, setSelectedIot] = useState(0);
  const [subStreamCount, setSubStreamCount] = useState(1);
  const [cameraDrop, setCameraDrop] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(0);
  const [sensorDrop, setSensorDrop] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(0);
  const [batteryDrop, setBatteryDrop] = useState([]);
  const [selectedBattery, setSelectedBattery] = useState(0);
  const [encoderData, setEncoderData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')
  const [selectedStream, setSelectedStream] = useState("");
  const [increment, setIncrement] = useState(1);

  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    machine_id: "",
    sensor_id: "",
    gateway_id: "",
  });
  const [substream, setSubStream] = useState([{ stream: '', type: 1 }]);

  useEffect(() => {
    if (id != null) {
      dispatch(getSingleEncoder(id));
    }
    let data = {
      is_assigned: "",
      orgId: parseInt(sessionStorage.getItem("org_Id")),
    };

    dispatch(fetchCameraDropdown(data));
    dispatch(fetchSensorDropdown(data));
    dispatch(fetchBatteryDropdown(data));
    dispatch(fetchIotDeviceList())

  }, [id]);
  useEffect(() => {
    if (
      !EncoderDetails.isFetching &&
      EncoderDetails.payload &&
      EncoderDetails.payload.success === true
    ) {
      const tempEncoder = EncoderDetails.payload.data;
      const tempEncoderstrem = EncoderDetails.payload.data.substream;

      // const tempPagination = EncoderDetails.payload.pagination;
      setEncoderData(tempEncoder);
      if (tempEncoderstrem.length) {
        setSubStream(tempEncoderstrem);
        setIncrement(tempEncoderstrem?.length + 1)

      }
      else {
        setSubStream((prevSubstream) => {
          const newSubstream = [...prevSubstream];
          if (newSubstream.length > 0) {
            newSubstream[newSubstream.length - 1].stream = tempEncoder.gatewayData?.machine_id + '-' + String(increment).padStart(2, '0');
          }
          return newSubstream;
        });
        setIncrement((prev) => prev + 1);
      }
      setSelectedBattery(tempEncoder.batteryData?.id);
      setSelectedSensor(tempEncoder.SensorData?.id);
      setSelectedCamera(tempEncoder.gatewayData?.id);
      setSelectedIot(tempEncoder.iot_device_id)
      setSelectedStream(tempEncoder.gatewayData?.machine_id)
    }
    if (
      !editEncoderStatus.isFetching &&
      editEncoderStatus.payload &&
      editEncoderStatus.payload.success === true
    ) {
      sessionStorage.setItem("surveillanceTab", "s1");

      window.location.href = "/surveillance";

      //setBatteryDrop(tempBatteryList);
    }
    if (!editEncoderStatus.isFetching && editEncoderStatus.error) {
      setShowAlert(true);
      const error = editEncoderStatus.errorMessage.message;
      setAlertMessage(error);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      dispatch(cleareditEncoderStatus());
      return () => clearTimeout(timeoutId);
    }
  }, [EncoderDetails, editEncoderStatus]);
  useEffect(() => {
    // if (selectedBattery != 0) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     battery_id: "",
    //   }));
    // }
    if (selectedCamera != 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        gateway_id: "",
      }));
    }
    if (selectedSensor != 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        sensor_id: "",
      }));
    }
  }, [selectedCamera, selectedSensor]);
  useEffect(() => {
    if (
      !cameraList.isFetching &&
      cameraList.payload &&
      cameraList.payload.success === true
    ) {
      const tempList = cameraList.payload.list;
      const tempCamera = [];

      tempList.forEach((item) => {
        if (item.is_assigned == 0) {
          tempCamera.push(item);
        }
        if (item.is_assigned == 1 && item.id == selectedCamera) {
          tempCamera.push(item);
        }
      });
      setCameraDrop(tempCamera);
    }
    if (
      !sensorList.isFetching &&
      sensorList.payload &&
      sensorList.payload.success === true
    ) {
      const tempsensorList = sensorList.payload.list;
      const temSensor = [];
      tempsensorList.forEach((item) => {
        if (item.is_assigned == 0) {
          temSensor.push(item);
        }
        if (item.is_assigned == 1 && item.id == selectedSensor) {
          temSensor.push(item);
        }
      });
      setSensorDrop(temSensor);
    }
    if (
      !batteryList.isFetching &&
      batteryList.payload &&
      batteryList.payload.success === true
    ) {
      const tempBatteryList = batteryList.payload.list;
      const tempBattery = [];
      tempBatteryList.forEach((item) => {
        if (item.is_assigned == 0) {
          tempBattery.push(item);
        }
        if (item.is_assigned == 1 && item.id == selectedBattery) {
          tempBattery.push(item);
        }
      });
      setBatteryDrop(tempBattery);
    }
    if (
      !IotList.isFetching &&
      IotList.payload &&
      IotList.payload.success === true
    ) {
      const tempiotList = IotList.payload.list;

      setIotDrop(tempiotList);
    }
  }, [cameraList, sensorList, batteryList, IotList]);
  const onChange = (value, key) => {
    let temp = { ...encoderData };
    temp[key] = value;
    setEncoderData(temp);
    const strln = value.toString().trim();
    const valueLength = strln.length;
    let error = "";
    switch (key) {
      case "title":
        // case 'license_no':

        error =
          valueLength < 3 || valueLength >= 30
            ? "Field must be between 2 and 30 characters long"
            : "";
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };
  const handleChangeSubStream = (value, index, subkey) => {
    setSubStream((prevSubstream) => {
      const updatedSubstream = [...prevSubstream]; // Create a new array

      // Ensure the object exists at the specified index
      if (!updatedSubstream[index]) {
        updatedSubstream[index] = { stream: selectedStream ? `${selectedStream}-${String(increment).padStart(2, '0')}` : '', type: 1 }; // Initialize if undefined
      }

      // Update the relevant property
      // if (subkey === "stream") {
      //   updatedSubstream[index] = { ...updatedSubstream[index], stream: value }; // Update stream
      // } else 
      if (subkey === "type") {
        updatedSubstream[index] = { ...updatedSubstream[index], type: value }; // Update type
      }
      return updatedSubstream; // Return updated array
    });
  };
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleEdit = (e) => {
    let data = {
      title: encoderData.title,
      machine_id: cameraDrop?.find((obj) => obj.id === selectedCamera)
        ?.machine_id,
      sensor_id: selectedSensor,
      battery_id: selectedBattery,
      gateway_id: selectedCamera,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
      iot_device_id: selectedIot,
      substream: substream
    };
    e.preventDefault();
    const isEmptyField = Object.values(data).some((value) => {
      return (
        (typeof value === "string" && value.trim() === "") ||
        value === undefined ||
        value === null
      );
    });
    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        title:
          data.title.trim() === ""
            ? "This field is mandatory"
            : prevErrors.title,
        sensor_id: selectedSensor ? "" : "This field is mandatory",
        // battery_id: selectedBattery ? "" : "This field is mandatory",
        gateway_id: selectedCamera ? "" : "This field is mandatory",
      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        title: "",
        machine_id: "",
        sensor_id: "",
        // battery_id: "",
        gateway_id: "",
      });
      dispatch(editEncoder(id, data));
    }
  };
  const handlealertClose = () => {
    setShowAlert(false)
  }
  const handleAddSubStream = () => {
    const newStream = selectedStream ? `${selectedStream}-${String(increment).padStart(2, '0')}` : '';

    setSubStream((prevSubstream) => [
      ...prevSubstream,
      { stream: newStream, type: 1 } // Add new substream
    ]);
    setIncrement((prev) => prev + 1);
  };
  const handleRemoveStream = (id) => {
    const filteredItem = substream.filter((item, index) => index != id
    )
    // setIncrement((prev) => prev - 1);

    setSubStream(filteredItem)
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handlealertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handlealertClose}
            severity="error"
            variant="outlined"
            sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {editEncoderStatus.isFetching || EncoderDetails.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <Grid container>
            <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2 }}>
              <Typography
                sx={{ textAlign: "left" }}
                className={"headersubhead light_text"}
              >
                Edit Encoder
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3, mr: 2 }}>
              <InputLabel
                className={"form_text_light"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                Title
              </InputLabel>
              <TextField
                className={"form_field form_field_light"}
                placeholder="Title"
                value={encoderData.title}
                onChange={(e) => onChange(e.target.value, "title")}
                error={!!validationErrors.title}
                helperText={validationErrors.title}
              />
            </Grid>
            <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3 }}>
              <InputLabel
                className={"form_text_light form_text_mob"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                Camera Id
              </InputLabel>
              <Select
                className={"form_field_right form_field_light"}
                value={selectedCamera}
                onChange={(e) => setSelectedCamera(e.target.value)}
                displayEmpty
                disabled
                renderValue={(selected) => {
                  if (selected == 0) {
                    return <span style={{ color: "#a2a2a2" }}>Camera Id</span>;
                  }
                  const selectedCamera = cameraDrop.find(
                    (cam) => cam.id === selected
                  );
                  return selectedCamera ? selectedCamera.machine_id : "";
                }}
              >
                {cameraDrop.length > 0 ? (
                  cameraDrop.map((camera, index) => (
                    <MenuItem key={index} value={camera.id}>
                      {camera.machine_id}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Camera Found</MenuItem>
                )}
              </Select>
              {validationErrors?.gateway_id && (
                <Typography
                  sx={{ color: "#d32f2f", ml: 2, mt: 1 }}
                  className={" errormargin errorStyle"}
                >
                  {validationErrors?.gateway_id}
                </Typography>
              )}
            </Grid>

            <Grid item lg={12} sm={12} xs={12} md={12}
              sx={{ justifyContent: 'left', textAlign: 'left', display: 'flex', ml: 2, mt: 2 }}>
              <Typography
                sx={{ textAlign: "left" }}
                className={"headersubhead light_text"}
              >
                Add Sub Stream Details
              </Typography>
            </Grid>
            <Grid item lg={11.3} xs={12} sm={12} md={11.3}
              className=""
              sx={{ ml: 2, mt: 2, display: 'flex', border: '1px solid #E9E9E9 !important', p: '2% 1% !important' }}>
              <Grid container>
                <Grid item xs={10} sm={10} lg={5.8} md={6} sx={{ mt: 3, mr: { lg: 2, md: 2, sm: 0, xs: 0 } }}>
                  <InputLabel className={"form_text_light"} sx={{ color: "black", ml: 0, mb: 2 }}>
                    Sub Stream Id
                  </InputLabel>
                  {
                    // [...Array(subStreamCount)].map((_, index) => (
                    substream.length ?
                      substream.map((item, index) => (
                        <TextField
                          key={index}
                          placeholder="Sub Stream Id"
                          className="form_field form_field_light"
                          value={item.stream}
                          onChange={(e) => handleChangeSubStream(e.target.value, index, "stream")}
                          // error={!!validationErrors.stream}
                          // helperText={validationErrors.stream}
                          fullWidth
                        />
                      )) :
                      <TextField
                        // key={index}
                        placeholder={selectedCamera ? cameraDrop?.find((obj) => obj.id === selectedCamera)?.stream : "Sub Stream Id"}
                        className={"form_field form_field_light"}
                        value={substream[0]?.stream}

                      // value={encoderData.substream[index]?.stream || ""}
                      //   onChange={(e) => handleChangeSubStream(e.target.value, index, "stream")}
                      // error={!!validationErrors.title}
                      // helperText={validationErrors.title}
                      />
                    // ))
                  }
                </Grid>

                <Grid item xs={10} sm={10} lg={5.8} md={6} sx={{ mt: 3 }}>
                  <InputLabel className={"form_text_light form_text_mob"} sx={{ color: "black", ml: 0, mb: 2 }}>
                    Stream Type
                  </InputLabel>
                  {
                    // [...Array(subStreamCount)].map((_, index) => (

                    substream.length ?
                      substream.map((item, index) => (
                        <div style={{ display: "flex", flexDirection: 'row' }}>

                          <Select
                            key={index}
                            className="form_field_right form_field_light"
                            value={item.type}
                            onChange={(e) => handleChangeSubStream(e.target.value, index, "type")}
                            displayEmpty
                            fullWidth
                          >
                            <MenuItem value={1}>Office Stream</MenuItem>
                            <MenuItem value={2}>Gate Stream</MenuItem>
                          </Select>
                          <IconButton
                            className={substream.length == 1 ? "hidebutton" : ""}
                            disabled={substream[0].stream == "" || substream.length == 1 ? true : false}
                            sx={{ mt: index == 0 ? 0 : 0 }}
                            onClick={() => { handleRemoveStream(index) }}
                          >
                            <CancelOutlinedIcon sx={{ color: '#C61A1A' }} />
                          </IconButton>
                        </div>
                      )) :
                      <Select
                        // key={index}
                        className={"form_field_right form_field_light"}
                        value={substream[0]?.type || ""}
                        onChange={(e) => handleChangeSubStream(e.target.value, 0, "type")}
                        displayEmpty
                      >
                        <MenuItem value={1}>Office Stream</MenuItem>
                        <MenuItem value={2}>Gate Stream</MenuItem>
                      </Select>
                    // ))
                  }
                  {/* {validationErrors?.gateway_id && (
                        <Typography sx={{ color: "#d32f2f", ml: 2, mt: 1 }} className={"errormargin errorStyle"}>
                          {validationErrors?.gateway_id}
                        </Typography>
                      )} */}
                </Grid>

                <Grid item xs={10} sm={10} lg={11.8} md={11.8} sx={{ mt: 3, display: 'flex', justifyContent: 'right', }}>
                  <YellowBorderButton
                    disabled={selectedCamera ? false : true}

                    startIcon={<MdOutlineAddToPhotos color="#FFAC0B" />}
                    onClick={handleAddSubStream}
                  >
                    Add camera view
                  </YellowBorderButton>
                </Grid>
              </Grid>
              {/* </div> */}





            </Grid>

















            <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 2, mr: 2 }}>
              <InputLabel
                className={"form_text_light"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                Sensor Name
              </InputLabel>
              <Select
                className={"form_field form_field_light noml"}
                value={selectedSensor}
                onChange={(e) => setSelectedSensor(e.target.value)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected == 0) {
                    return <span style={{ color: "#a2a2a2" }}>Sensor Name</span>;
                  }
                  const selectedSensor = sensorDrop.find(
                    (site) => site.id === selected
                  );
                  return selectedSensor ? selectedSensor.sensor_name : "";
                }}
              >
                {sensorDrop.length > 0 ? (
                  sensorDrop.map((sensor, index) => (
                    <MenuItem key={index} value={sensor.id}>
                      {sensor.sensor_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Data Found</MenuItem>
                )}
              </Select>
              {validationErrors?.sensor_id && (
                <Typography
                  sx={{ color: "#d32f2f", ml: 2, mt: 1 }}
                  className={" errormargin errorStyle"}
                >
                  {validationErrors?.sensor_id}
                </Typography>
              )}
            </Grid>
            <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3 }}>
              <InputLabel
                className={"form_text_light form_text_mob"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                Battery Name
              </InputLabel>
              <Select
                className={"form_field_right form_field_light"}
                value={selectedBattery}
                onChange={(e) => setSelectedBattery(e.target.value)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected == "") {
                    return <span style={{ color: "#a2a2a2" }}>Battery</span>;
                  }
                  const selectedBattery = batteryDrop.find(
                    (battery) => battery.id === selected
                  );
                  return selectedBattery ? selectedBattery.battery_name : "";
                }}
              >
                {batteryDrop.length > 0 ? (
                  batteryDrop.map((battery, index) => (
                    <MenuItem key={index} value={battery.id}>
                      {battery.battery_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Data Found</MenuItem>
                )}
              </Select>
              {/* {validationErrors?.battery_id && (
              <Typography
                sx={{ color: "#d32f2f", ml: 2, mt: 1 }}
                className={" errormargin errorStyle"}
              >
                {validationErrors?.battery_id}
              </Typography>
            )} */}
            </Grid>
            <Grid item xs={10} sm={10} lg={5.5} md={5.5}
              sx={{ mt: 3, ml: 2, display: 'flex', justifyContent: 'left', flexDirection: 'column' }} >
              <InputLabel
                className={"form_text_light"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                IOT Device Type
              </InputLabel>
              <Select
                className={"form_field form_field_light noml"}
                value={selectedIot}
                onChange={(e) => setSelectedIot(e.target.value)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected == 0) {
                    return <span style={{ color: "#a2a2a2" }}>Select IOT device type</span>;
                  }
                  const selectediot = iotDrop.find(
                    (iot) => iot.id === selected
                  );
                  return selectediot ? `${selectediot.did}-${selectediot.device_name}` : "";
                }}
              >
                {iotDrop.length > 0 ? (
                  iotDrop.map((iot, index) => (
                    <MenuItem key={index} value={iot.id}>
                      {`${iot.did}-${iot.device_name}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Data Found</MenuItem>
                )}
              </Select>

            </Grid>
            <Grid item tem xs={10} sm={10} lg={10} md={10} sx={{ ml: 3 }}>
              <StyledCreateSmallButton
                onClick={handleEdit}
                sx={{ mt: 5, width: 150 }}
                variant="contained"
                disableRipple
              >
                Update
              </StyledCreateSmallButton>
            </Grid>
          </Grid>
        }
      </div>
    </Layout>
  );
};
