import { Alert, Box, CircularProgress, FormHelperText, Grid, InputLabel, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearRssiLevelUpdatestatus, getRssiLevel, updateRssiLevel } from "../../../redux/actions/DeviceActions";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
export const RssiEdit = () => {
  const dispatch = useDispatch();
  const rssiUpdateStatus = useSelector(state => state.device.rssi);
  const rssidata = useSelector(state => state.device.rssiPrev)
  const [orgId, setOrgId] = useState("")
  const [rssi, setRssi] = useState({
    rssi: "",
    chat_id: "",
    bot_token: "",

  });
  const [validationErrors, setValidationErrors] = useState({
    chat_id: "",
    bot_token: "",
    rssi: "",
  })
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertmessage] = useState(false);
  useEffect(() => {
    const id = sessionStorage.getItem('org_Id');
    setOrgId(id);
    if (id !== null) {
      dispatch(getRssiLevel(id))
    }
  }, [])
  useEffect(() => {
    if (!rssidata.isFetching && rssidata.payload && rssidata.payload.success === true) {
      const temp = rssidata.payload.organization_details;
      setRssi(temp);
    }
    if (!rssiUpdateStatus.isFetching && rssiUpdateStatus.payload && rssiUpdateStatus.payload.success === true) {
      setShowAlert(true);
      const msg = rssiUpdateStatus.payload.message;
      setAlertmessage(msg);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearRssiLevelUpdatestatus());
        dispatch(getRssiLevel(orgId))
        // sessionStorage.setItem('trackingTab', 'six')
        //     window.location.href = '/tracking';
      }, 2000);
      return () => clearTimeout(timeoutId);

    }
    if (!rssiUpdateStatus.isFetching && rssiUpdateStatus.error) {
      setShowAlert(true);
      setIsError(true);
      const msg = rssiUpdateStatus.payload.message;
      setAlertmessage(msg);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setIsError(false);
        dispatch(clearRssiLevelUpdatestatus());
        dispatch(getRssiLevel(orgId))


      }, 2000);
      return () => clearTimeout(timeoutId);
    }

  }, [rssiUpdateStatus, rssidata])
  const handleChange = (value, key) => {
    let temp = { ...rssi };
    temp[key] = value;
    setRssi(temp);
    const valueLength = value.trim().length;
    let error = "";
    switch (key) {

      // case "chat_id":
      // case "bot_token":
      //   error =
      //     valueLength < 2 || valueLength >= 50
      //       ? "Field must be between 2 and 50 characters long"
      //       : "";
      //   break;
      case "rssi":
        // error =
        //   valueLength < 1
        //     ? "Field must not be empty"
        //     : "";
        error = valueLength < 1 || parseFloat(value) >= 0 ? "RSSI must be a negative number" : "";
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  }
  const onUpdate = (e) => {
    let data = {
      org_id: parseInt(sessionStorage.getItem('org_Id')),
      rssi: parseInt(rssi.rssi),
      chat_id: rssi.chat_id,
      bot_token: rssi.bot_token,
    };
    let tempData = {
      rssi: rssi.rssi,
      chat_id: rssi.chat_id,
      bot_token: rssi.bot_token,
    };
    e.preventDefault();
    // const isEmptySiteId = selectedSiteId == 
    const isEmptyField = Object.values(tempData).some((value) => {
      return (
        (typeof value === "string" && value === "") ||
        value === undefined ||
        value === null ||
        value === "" ||
        value === NaN
      );
    });
    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
         ...prevErrors,
        // chat_id:
        //   rssi?.chat_id.trim() === ""
        //     ? ""
        //     : prevErrors.chat_id,
        // bot_token:
        //   rssi.bot_token.trim() === ""
        //     ? ""
        //     : prevErrors.bot_token,
        rssi: rssi.rssi === ""
          ? "This field is mandatory"
          : prevErrors.rssi,
      }))
    }
    if (allClear) {
      setValidationErrors({
        chat_id: "",
        bot_token: "",
        rssi: ""

      });
      dispatch(updateRssiLevel(data))
    }
  };
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <>
      <Grid container>
        {/* <Grid item lg={12} md={12} sm={12} xs={12} className="mtdash2">
          <Typography className="headersubhead light_text htl">RSSI</Typography>
        </Grid> */}

        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handlealertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handlealertClose}
            severity={isError ? "error" : "success"}
            variant="outlined"
            sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : '#5bdb5b73' }}

          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {rssiUpdateStatus.isFetching || rssidata.isFetching ?
          <Box
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Box>
          :
          <>
            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }} container>
              <InputLabel
                className={"form_text_light"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                RSSI
              </InputLabel>
              <TextField
                className={"form_field form_field_light"}
                placeholder="RSSI"
                value={rssi.rssi}
                type="number"
                onChange={(e) => handleChange(e.target.value, 'rssi')}
                error={!!validationErrors.rssi}
                helperText={validationErrors.rssi}

              />
              <FormHelperText>RSSI Indicate that the value is for the distance sensing logic.</FormHelperText>
            </Grid>
            <Grid container>
              <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                <InputLabel
                  className={"form_text_light form_text_mob"}
                  sx={{ color: "black", mb: 2 }}
                >
                  Chat Id
                </InputLabel>
                <TextField
                  className={"form_field_right form_field_light"}
                  placeholder="Telegram Chat Id"
                  onChange={(e) => handleChange(e.target.value, "chat_id")}
                  value={rssi.chat_id}
                  error={!!validationErrors.chat_id}
                  helperText={validationErrors.chat_id}
                  type="text"
                //   autoComplete="off" // Disable autocomplete
                //   id="org_name"
                //   name={`org_name${Math.random().toString(36).substr(2, 9)}`}
                />
              </Grid>
              <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                <InputLabel
                  className={"form_text_light form_text_mob"}
                  sx={{ color: "black", mb: 2 }}
                >
                  Bot Token
                </InputLabel>
                <TextField
                  className={"form_field_right form_field_light"}
                  placeholder="Telegram bot token"
                  onChange={(e) => handleChange(e.target.value, "bot_token")}
                  value={rssi.bot_token}
                  error={!!validationErrors.bot_token}
                  helperText={validationErrors.bot_token}
                  type="text"
                //   autoComplete="off" // Disable autocomplete
                //   id="org_name"
                //   name={`org_name${Math.random().toString(36).substr(2, 9)}`}
                />
              </Grid>
            </Grid>

            <Grid item xs={10} sm={10} lg={10} md={10} sx={{ mt: 3 }}>
              <StyledCreateSmallButton
                onClick={onUpdate}
                sx={{ mt: 5, width: 150 }}
                variant="contained"
                disableRipple
              >
                Update
              </StyledCreateSmallButton>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
};
