import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCleanerSingleHisory } from '../../../../../redux/actions/PersonnelAction';
import { useSelector } from 'react-redux';
import { Layout } from '../../../../layout/Layout';
import { Grid, Typography } from '@mui/material';
import { alertTimeOnly, dateOnly, GmtToLocalClockTime } from '../../../../convertors/TimeConvertor';
import dayjs from 'dayjs';
import { CleanerSiteDetails } from './CleanerSiteDetails';
import { CleanerDetails } from './CleanerDetails';
import { CleanerRouteDetails } from './CleanerRouteDetails';
export const CleanerHistoryView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const singleHistoryData = useSelector(state => state.personnel.cleanerSingleHistory);
    const [historyData, setHistoryData] = useState({});
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");
    useEffect(() => {
        if (id != null) {
            dispatch(fetchCleanerSingleHisory(id));
        }
    }, [id])
    const dateFormat = (date) => {
        let formattedDate = dayjs(date);
        return formattedDate.format("MM/DD/YYYY")
    }
    useEffect(() => {
        if (!singleHistoryData.isFetching && singleHistoryData.payload && singleHistoryData.payload.success === true) {
            let item = singleHistoryData.payload.data;

            const templist = {
                id: item.id,
                datedata: dateOnly(item.date),
                first_seen: item.startat == 0 || item.startat == '' ? 0 : alertTimeOnly(item.startat),
                last_seen: item.endat == 0 || item.endat == '' ? 0 : alertTimeOnly(item.endat),
                site: item.site_name,
                 level: item.level_name,
                block: item.block_name,
                patrol_end_time: item.routeendtime ? GmtToLocalClockTime(item.routeendtime) : "NA",
                patrol_start_time: item.routestarttime ? GmtToLocalClockTime(item.routestarttime) : "NA",
                routename: item.routename,
                contractorname:item.contractorname,
                empid:item.empid,
                infant_name:item.infant_name,
                phone:item.phone,
                email:item.email,
                checkpoints: item.checkpoints,
                role:"Cleaner"

            }

            const tempData = {
                ...templist,
                date: dateFormat(templist.datedata),
                //trackDate:dateFormat(item.trackDate)

            }

            setHistoryData(tempData)
        }
    }, [singleHistoryData]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    return (
        <Layout

            activeMenuItem={"tracking"}
            activeMenuKey={"tracking"}
            isCreate={true}
            activeSubtab={"three"}
            handleActiveMenuItems={handleActiveMenuItems}

        // searchWord={searchWord}
        // onSearchWordChange={onSearchWordChange}
        >
            <div
                style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", '@media (max-width: 600px)': { marginLeft: "0% !important" } }}
                className=""
            >
                <Grid container
                    sx={{ mt: 5, ml: { lg: '3% !important', md: '3% !important', sm: '-1% !important', xs: '-1% !important' } }} spacing={2}>
                    <Grid item lg={11} md={11} sm={12} xs={12} container>
                        <Grid item lg={10} sm={12} xs={12} md={10}>
                            <Typography
                                sx={{ textAlign: "left", mt: 2 }}
                                className={"headersubhead  light_text"}
                            >
                            Site Details
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                        <CleanerSiteDetails
                            historyData={historyData}
                        />
                    </Grid>
                    <Grid item lg={11} md={11} sm={12} xs={12} container>
                        <Grid item lg={10} sm={12} xs={12} md={10}>
                            <Typography
                                sx={{ textAlign: "left", mt: 2 }}
                                className={"headersubhead  light_text"}
                            >
                            User Details
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                        <CleanerDetails
                            historyData={historyData}
                        />
                    </Grid>
                    <Grid item lg={11} md={11} sm={12} xs={12} container>
                        <Grid item lg={10} sm={12} xs={12} md={10}>
                            <Typography
                                sx={{ textAlign: "left", mt: 2 }}
                                className={"headersubhead  light_text"}
                            >
                            Route Details
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                        <CleanerRouteDetails
                            historyData={historyData}
                        />
                    </Grid>

                    
                </Grid>
            </div>
        </Layout>
    )
}