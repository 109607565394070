import { Alert, Grid, IconButton, InputLabel, Snackbar, TextField, Typography } from '@mui/material';
import React from 'react';
import { PlainActiveButton, PlainButton, StyledCreateButton } from '../styles/ButtonStyles';
import { StyledGreyButton } from '../Styles';
import { IoMailOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { useState } from 'react';
import { addContactUs, clearaddContactUsStatus } from '../../redux/actions/UserRoleActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { validateEmail } from '../helpers/ValidationHelper';

export const ContactUs = ({ }) => {
    const dispatch = useDispatch();
    const contactUsStatus = useSelector(state => state.userRole.contactUs);

    const [contactUsDetails, setContactUsDetails] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [validationErrors, setValidationErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alertMessage, setAlertmessage] = useState(false);
    useEffect(() => {
        if (!contactUsStatus.isFetching && contactUsStatus.payload && contactUsStatus.payload.success === true) {
            setShowAlert(true);
            const msg = contactUsStatus.payload.message;
            setContactUsDetails({
                name: '',
                email: '',
                subject: '',
                message: ''
            })
            setAlertmessage(msg);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearaddContactUsStatus());

                // sessionStorage.setItem('trackingTab', 'six')
                //     window.location.href = '/tracking';
            }, 2000);

        }
        if (!contactUsStatus.isFetching && contactUsStatus.error === true) {
            const message = contactUsStatus.errorMessage.message;
            setAlertmessage(message)

            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearaddContactUsStatus());
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [contactUsStatus])
    const handleChange = (value, key) => {
        let temp = { ...contactUsDetails };
        temp[key] = value;
        setContactUsDetails(temp);
        const valueLength = value.trim().length;
        let error = '';
        switch (key) {
            case 'name':
            case 'subject':


                error =
                    valueLength < 2 || valueLength >= 30
                        ? 'Field must be between 2 and 30 characters long'
                        : '';
                break;
            // case 'password':
            //     const validPass = validatePassword(value);
            //     if (validPass.code === false) {
            //         error = validPass.msg;
            //     }
            //     else {
            //         error = '';
            //     }
            //     break;
            case 'email':
                const validEmail = validateEmail(value);
                error = validEmail ? '' : 'Invalid Email';
                break;
          
            case 'message':
                error =
                    valueLength < 10 || valueLength >= 250
                        ? 'Field must be between 10 and 250 characters long'
                        : '';
                break;

            default:
                break;
        }

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));
    }
    const handleLogin = () => {
        window.location.href = '/'
    }
    const handleMoreOn = () => {
        window.location.href = '/more';
    }
    const handleContactUs = (e) => {
        let data = {
            name: contactUsDetails.name,
            email: contactUsDetails.email,
            subject: contactUsDetails.subject,
            message: contactUsDetails.message
        }
        e.preventDefault();
        const isEmptyField = Object.values(data).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null || value === "" || value === NaN;
        });
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (isEmptyField) {

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                name: data.name.trim() === '' ? 'This field is mandatory' : prevErrors.name,
                email: data.email ? !validateEmail(data.email) ? prevErrors.email : '' : 'This field is mandatory',
                message: data.message.trim() === '' ? 'This field is mandatory' : prevErrors.message,
                subject: data.subject.trim() === '' ? 'This field is mandatory' : prevErrors.subject,

            }));

        }

        if (!isEmptyField && allClear) {
        dispatch(addContactUs(data));
        }
    }
    const handlealertClose = () => {
        setShowAlert(false);
    }
    return (
        <div style={{ height: "100%", marginBottom: "0px !important " }}>
            <Grid item lg={12} md={12} sm={12} xs={12} className="contactus_fisrtdiv">
                <Grid container>
                    <Grid item lg={7.5} md={7.5} sm={12} xs={12}>
                        <img
                            src={require("../../assets/img/loginlogo.png")}
                            alt="login logo"
                            className="moreonlogo"
                        />
                    </Grid>

                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <PlainButton 
                        
                        onClick={handleMoreOn}>More on VIDSafe</PlainButton>
                        <PlainActiveButton>Contact US</PlainActiveButton>
                        <PlainButton onClick={handleLogin}>Login </PlainButton>
                    </Grid>
                    <Grid item lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}>
                        <div className="moremaindiv">
                            <Typography className="getintouch">
                                Get In Touch
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ marginLeft: "5%", marginRight: "3%", paddingLeft: "5%", display: "flex", justifyContent: "center" }} 
            className={"white_bg contactusdiv" }>


                <div className='contactformdiv'>
                    <Grid container style={{ display: "flex", justifyContent: "center", }} >
                        <Grid item xs={12} sm={12} lg={12} md={12} sx={{ mt: 3 }}>
                            <Snackbar
                                open={showAlert}
                                autoHideDuration={6000}
                                onClose={handlealertClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Alert
                                    onClose={handlealertClose}
                                    severity={isError ? "error" : "success"}
                                    variant="outlined"
                                    sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor:  isError ? '#FCDFDF' : '#5bdb5b73' }}
                                >
                                    {alertMessage}
                                </Alert>
                            </Snackbar>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }} >
                            <InputLabel
                                className={"formGetIn"}
                                sx={{ color: "black", ml: 0, mb: 2, }}>
                                Name</InputLabel>
                            <TextField
                                className={"form_field form_field_light"}
                                placeholder="Name"
                                value={contactUsDetails.name}
                                onChange={(e) => handleChange(e.target.value, 'name')}
                            error={!!validationErrors.name}
                            helperText={validationErrors.name}
                            />

                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>

                            <InputLabel
                                className={"formGetIn form_text_mob"}
                                sx={{ color: "black", mb: 2, }}>
                                Mail Id</InputLabel>
                            <TextField
                                className={"form_field_right form_field_light"}

                                placeholder='example@.gmail.com'
                                value={contactUsDetails.email}
                                onChange={(e) => handleChange(e.target.value, 'email')}
                            error={!!validationErrors.email}
                            helperText={validationErrors.email}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={"formGetIn"}
                                sx={{ color: "black", ml: 0, mb: 2, }}>
                                Subject</InputLabel>
                            <TextField
                                className={"form_field form_field_light"}
                                placeholder="Your Subject"

                                value={contactUsDetails.subject}
                                onChange={(e) => handleChange(e.target.value, 'subject')}
                                
                            error={!!validationErrors.subject}
                            helperText={validationErrors.subject}
                            />

                        </Grid>
                        <Grid item xs={10} sm={10} md={6} lg={6} sx={{ mt: 3 }}>

                            <InputLabel
                                className={"formGetIn form_text_mob"}
                                sx={{ color: "black", mb: 2, }}>
                                Your Message</InputLabel>
                            <TextField
                                className={"form_field_right form_field_light"}
                                placeholder='Your Message'
                                value={contactUsDetails.message}
                                onChange={(e) => handleChange(e.target.value, 'message')}
                                 error={!!validationErrors.message}
                            helperText={validationErrors.message}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Grid container item lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
            >
                <div className='btndiv'>
                    <StyledGreyButton
                        className="full_width"
                        variant="contained"
                        onClick={handleContactUs}
                        disableRipple>Submit</StyledGreyButton>
                </div>

            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="contactus_footer" sx={{ mt: "5%" }}>
                <Grid container>
                    <Grid item
                        container
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                        <Grid item lg={4}
                            md={4}
                            sm={4}
                            xs={4} style={{ display: "flex", justifyContent: "right", }}
                        >
                            <IconButton className='footerbtn'>
                                <IoMailOutline color="#D5D5D5" fontSize={"30px"} />
                            </IconButton>
                        </Grid>
                        <Grid item
                            lg={7}
                            md={7}
                            sm={7}
                            xs={7}
                            sx={{ ml: "2%" }}
                        // style={{ display: "flex", justifyContent: "left", }}
                        >
                            <Typography className="contactfooterhead">
                                Mail us on
                            </Typography>
                            <Typography className="contactfootertext">
                                jasling@apx-technologies.com
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item
                        container
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                        <Grid item lg={4}
                            md={4}
                            sm={4}
                            xs={4} style={{ display: "flex", justifyContent: "right", }}
                        >
                            <IconButton className='footerbtn'>
                                <FiPhone color="#D5D5D5" fontSize={"30px"} />
                            </IconButton>
                        </Grid>
                        <Grid item
                            lg={7}
                            md={7}
                            sm={7}
                            xs={7}
                            sx={{ ml: "2%" }}
                        // style={{ display: "flex", justifyContent: "left", }}
                        >
                            <Typography className="contactfooterhead">
                                Call us on
                            </Typography>
                            <Typography className="contactfootertext">
                                +65 96676657
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item
                        container
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                        <Grid item lg={3}
                            md={3}
                            sm={4}
                            xs={4} style={{ display: "flex", justifyContent: "right", }}
                        >
                            <IconButton className='footerbtn'>
                                <IoLocationOutline color="#D5D5D5" fontSize={"30px"} />
                            </IconButton>
                        </Grid>
                        <Grid item
                            lg={8}
                            md={8}
                            sm={7}
                            xs={7}
                            sx={{ ml: "2%" }}
                        // style={{ display: "flex", justifyContent: "left", }}
                        >
                            <Typography className="contactfooterhead">
                                Our Address
                            </Typography>
                            <Typography className="contactfootertext">
                                APX Technologies Pte Ltd
                            </Typography>
                            <Typography className="contactfootertext">
                                Vertex, 33 Ubi Ave 3, #08-63, Singapore 408868
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: "2%", mb: "2%" }}
                style={{ display: "flex", justifyContent: "center" }}

            >
                <Typography className='copytright'>
                    APX Technologies Pte Ltd All rights reserved. 2024
                </Typography>
            </Grid>

        </div>
    )
}