import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { fetchLevel } from "../../../redux/actions/SharableActions";
import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { ViewMap } from "../camera/view/ViewMap";
import { ViewFilter } from "../camera/view/ViewFilter";
import { MenuProps } from "../../Styles";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import PropTypes from 'prop-types';
export const MapHome = ({ isWidget, columnSize, removeWidget, id }) => {
  // const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");

  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const LevelData = useSelector((state) => state.sharables.LayerList);
  // const BlockData = useSelector((state) => state.sharables.BlockList);
  // const mapData = useSelector(
  //   (state) => state.surveillance.mapData
  // );
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState("");
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  // const [blocks, setBlocks] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  // const [isDrag, setIsDrag] = useState(false);
  // const [search, setSearch] = useState("")
  useEffect(() => {
    dispatch(fetchSites());
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
      setSelectedSites(temp[0]?.organizationId)
    }


  }, [siteData]);
  useEffect(() => {
    if (
      !LevelData.isFetching &&
      LevelData.payload &&
      LevelData.payload.success === true
    ) {
      const levelList = LevelData.payload.data.LevelList;
      setLevels(levelList);
      setSelectedLevel(levelList[0]?.layerId);
    }
  }, [LevelData])
  useEffect(() => {
    dispatch(fetchLevel(selectedSites));

  }, [selectedSites]);
  // const handleActiveMenuItems = (menuItems) => {
  //   setActiveMenuItem(menuItems);
  // };

  const handleSites = (value) => {
    setSelectedSites(value);

  };
  const handleLevels = (value) => {
    setSelectedLevel(value);
  };
  const handleBlocks = (value) => {
    setSelectedBlocks(value);
  };
  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };
  // const handleAdd = () => {
  //   setIsDrag(!isDrag);
  // };
  // const handleSearch = (word) => {
  //   setSearch(word);
  // }
  return (

    <Grid container sx={{ mt: 2 }}>

      {!isWidget && <ViewFilter
        selectedSites={selectedSites}
        handleSites={handleSites}
        sites={sites}
        levels={levels}
        selectedLevel={selectedLevel}
        handleLevels={handleLevels}
        // blocks={blocks}
        selectedBlocks={selectedBlocks}
        handleBlocks={handleBlocks}
        handleSelectedStatus={handleSelectedStatus}
        selectedStatus={selectedStatus}
      />}
      {isWidget &&
        < Grid container >
          <Grid item lg={9} sm={9} xs={9} md={9} className="mt2"
            sx={{ ml: columnSize == 12 ? 2 : 3, mb: 2 }} >
            <Typography className='headersubhead light_text htl'>Surveillance Map </Typography>
          </Grid>
          <Grid item xs={2} sm={2} lg={2} md={2} sx={{ mt: '0% !important' }}
            style={{ display: 'flex', justifyContent: 'flex-end', }}>
            <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
              <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
            </IconButton>
          </Grid>
          <Grid item lg={columnSize == 12 ? 5 : 12} sm={12} xs={12} md={columnSize == 12 ? 5 : 12}
            style={{ display: 'flex' }}
            sx={{ ml: columnSize == 12 ? 0 : 3 }}>
            <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
              style={{ display: 'flex' }}
              sx={{ ml: columnSize == 12 ? 2 : 0 }}
            >
              <Grid item sx={{ mt: 2 }}>
                <Typography sx={{ textAlign: 'center' }}
                  className={"headersubhead light_text"}>
                  Sites :
                </Typography>

              </Grid>
              <Grid item lg={8} sm={9} xs={9} md={8}>
                <Select
                  sx={{
                    color: "#161616", ml: 1, minWidth: 100,
                    textAlign: 'left',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                      border: '',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "#161616",
                    }
                  }}

                  displayEmpty
                  MenuProps={MenuProps}
                  className={"headerSelect select_light"}
                  value={selectedSites}
                  onChange={(e) => { handleSites(e.target.value, 'site') }}
                >
                  {sites.map((site, index) => (
                    <MenuItem
                      key={index}
                      value={site.organizationId}>
                      {site.organizationName}
                    </MenuItem>
                  ))}

                </Select>
              </Grid>

            </Grid>
          </Grid>
          <Grid item lg={columnSize == 12 ? 5 : 12} sm={12} xs={12} md={columnSize == 12 ? 5 : 12}
            style={{ display: 'flex' }}
            sx={{ ml: columnSize == 12 ? 0 : 3 }}>
            <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
              style={{ display: 'flex' }}
              sx={{ ml: columnSize == 12 ? 2 : 0 }}
            >
              <Grid item sx={{ mt: 2 }}>
                <Typography sx={{ textAlign: 'center' }}
                  className={"headersubhead light_text"}>
                  Level :
                </Typography>

              </Grid>
              <Grid item lg={8} sm={8.5} xs={8.5} md={8}>
                <Select
                  sx={{
                    color: "#161616", ml: 1, minWidth: 100,
                    textAlign: 'left',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                      border: '',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "#161616",
                    }
                  }}

                  displayEmpty
                  MenuProps={MenuProps}
                  className={"headerSelect select_light"}
                  value={selectedLevel}
                  onChange={(e) => { handleLevels(e.target.value, 'level') }}
                >
                  {levels.map((level, index) => (
                    <MenuItem
                      key={index}
                      value={level.layerId}>
                      {level.layerName}
                    </MenuItem>
                  ))}


                </Select>
              </Grid>

            </Grid>
          </Grid>
          {/* <Grid item xs={2} sm={2} lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
              <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
            </IconButton>
          </Grid> */}
        </Grid>
      }
      {/* <Grid item lg={8} md={8} xs={8} sm={8} >
              <div className="dragablecam_list">
                {mapUrl && mapType === 'vidsafe' &&
                  <MapContainer maxZoom={21} scrollWheelZoom={false} minZoom={1}
                    dragging={true}
                    bounds={[[-326.5, -35], [90.99, -540.85]]}
                    whenReady={(map) => {
                      onMapReady(map)
                    }}
                  >
                    <ImageOverlay crossOrigin="anonymous" url={mapUrl} bounds={[[-326.5, -35], [50.99, -640.85]]}>
                      {markers.map((item, i) => {
                        return <DraggableMarker center={item.position} camera={item} updateMarkerList={(position, marker) => { updateMarkerList(position, marker) }} removeFromMap={(marker) => { removeFromMap(marker) }} />
                      })}
                    </ImageOverlay>
                  </MapContainer>
                }
                {mapDetails && mapType === 'wise' &&
                  <MapContainer zoom={19}
                    minZoom={mapDetails.minZoom}
                    maxZoom={mapDetails.maxZoom}
                    scrollWheelZoom={false}
                    bounds={mapBounds}
                    whenReady={(map) => {
                      setMap(map)
                      onMapReady(map)
                    }}>
                    <TileLayer
                      tms={true}
                      noWrap={true}
                      url={mapUrl}
                      zoom={19}
                      minZoom={mapDetails.minZoom}
                      maxZoom={mapDetails.maxZoom}
                    />
                    {markers.map((item, i) => {
                      return <DraggableMarker center={item.position} camera={item} updateMarkerList={(position, marker) => { updateMarkerList(position, marker) }} removeFromMap={(marker) => { removeFromMap(marker) }} />
                    })}
                  </MapContainer>
                }
      
              </div>
            </Grid> */}
      {levels.length > 0 ?
        <ViewMap selectedSites={selectedSites}
          selectedLevel={selectedLevel}
        // search={search} 
        />
        :
        <Grid
          item
          lg={11.8}
          sm={11.8}
          xs={11.8}
          sx={{ mt: "6% !important", display: 'flex', justifyContent: 'center' }}
        >
          No Maps Found
        </Grid>
      }
    </Grid>
  )
}
MapHome.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  isWidget: PropTypes.bool.isRequired,

  removeWidget: PropTypes.func.isRequired,
  columnSize: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,

};