import { all, put, takeLatest } from "redux-saga/effects";
import { alertActionType, assignAlertError, assignAlertSuccess, fetchAlertListError, fetchAlertListSuccess, 
    fetchAlertTrendError, fetchAlertTrendSuccess, fetchAuditListError, fetchAuditListSuccess, 
    fetchBatteryPerformanceError, 
    fetchBatteryPerformanceSuccess, 
    fetchCleanerAlertError, 
    fetchCleanerAlertSuccess, 
    fetchDashboardGuagesError,fetchDashboardGuagesSuccess, fetchEncoderDropDownError, 
    fetchEncoderDropDownSuccess, fetchSensorReportError, fetchSensorReportSuccess, 
    fetchSingleCameraAlertError, fetchSingleCameraAlertSuccess, fetchSingleCleanerAlertError, fetchSingleCleanerAlertSuccess, fetchSingleDeviceAlertError,
    fetchSingleDeviceAlertSuccess, fetchSinglePatrolError, fetchSinglePatrolSuccess,
    fetchSinglesensorAlertError, 
    fetchSinglesensorAlertSuccess, fetchSingleSentryError,fetchSingleSentrySuccess, fetchSurveillancePerformanceError, fetchSurveillancePerformanceSuccess, fetchTagBatteryAlertError, fetchTagBatteryAlertSuccess, fetchTimeBasedReportError, 
    fetchTimeBasedReportSuccess,fetchTopPersonnelAlertError,fetchTopPersonnelAlertSuccess,fetchTrackingReportError, 
    fetchTrackingReportSuccess, 
    fetchVaAlertSuccess} from "../actions/AlertActions";
import { assignAlertDetails, fetchAlertListData, fetchAlertTrendDetails, fetchGuageData, getAditTrailListData,
     getBatteryPerformanceDetails,
     getClenerAlertDetails,
     getEncoderDropdownDeatils,
     getSensorReportDetails,
     getSingleCameraDetails,
     getSingleCleanerDetails,
     getSingleDeviceDetails,getSinglePatrolDetails, getSingleSensorDetails, 
     getSingleSentryDetails, 
     getSurveillancePerformanceDetails, 
     getTagBatteryAlertsDetails, 
     getTimeBasedReportDetails, 
     getTopPersonnelAlertDetails, 
     getTrackingReportDetails,
     getVaAlertDetails} from "../../services/AlertServices";
       //fetch   report cleaner alert
       function* _getCleanerAlert(action) {

        try {
            const singleCleaner = yield getClenerAlertDetails(action.data);
            yield put(fetchCleanerAlertSuccess(singleCleaner));
        } catch (e) {
            yield put(fetchCleanerAlertError("error"));
        }
    }
    function* getCleanerAlert() {
        yield takeLatest(alertActionType.FETCH_CLEANER_ALERT, _getCleanerAlert)
    } 
      //fetch   single cleaner alert
      function* _getSingleCleanerAlert(action) {

        try {
            const singleCleaner = yield getSingleCleanerDetails(action.id);
            yield put(fetchSingleCleanerAlertSuccess(singleCleaner));
        } catch (e) {
            yield put(fetchSingleCleanerAlertError("error"));
        }
    }
    function* getSingleCleanerAlert() {
        yield takeLatest(alertActionType.FETCH_SINGLE_CLEANER_ALERT, _getSingleCleanerAlert)
    }     
     //fetch   single camera alert
           function* _getSingleCameraAlert(action) {

            try {
                const trackingReport = yield getSingleCameraDetails(action.id);
                yield put(fetchSingleCameraAlertSuccess(trackingReport));
            } catch (e) {
                yield put(fetchSingleCameraAlertError("error"));
            }
        }
        function* getSingleCameraAlert() {
            yield takeLatest(alertActionType.FETCH_SINGLE_CAMERA_ALERT, _getSingleCameraAlert)
        }
        //fetch   batteryperfomance
        function* _getBatteryPerformance(action) {

            try {
                const trackingReport = yield getBatteryPerformanceDetails(action.data);
                yield put(fetchBatteryPerformanceSuccess(trackingReport));
            } catch (e) {
                yield put(fetchBatteryPerformanceError("error"));
            }
        }
        function* getBatteryPerformance() {
            yield takeLatest(alertActionType.FETCH_BATTERY_PERFORMANCE, _getBatteryPerformance)
        }
      //fetch   encoderdropdown
      function* _getEncoderDropdown(action) {

        try {
            const trackingReport = yield getEncoderDropdownDeatils(action.data);
            yield put(fetchEncoderDropDownSuccess(trackingReport));
        } catch (e) {
            yield put(fetchEncoderDropDownError("error"));
        }
    }
    function* getEncoderDropdown() {
        yield takeLatest(alertActionType.FETCH_ENCODER_DROPDOWN, _getEncoderDropdown)
    }
      //fetch   surveillancePerformance
 function* _getSurveillancePerformance(action) {

    try {
        const trackingReport = yield getSurveillancePerformanceDetails(action.data);
        yield put(fetchSurveillancePerformanceSuccess(trackingReport));
    } catch (e) {
        yield put(fetchSurveillancePerformanceError("error"));
    }
}
function* getSurveillancePerformance() {
    yield takeLatest(alertActionType.FETCH_SURVEILLANCE_PERFORMANCE, _getSurveillancePerformance)
}
 //fetch   va alert
 function* _getVaAlert(action) {

    try {
        const trackingReport = yield getVaAlertDetails(action.data);
        yield put(fetchVaAlertSuccess(trackingReport));
    } catch (e) {
        yield put(fetchVaAlertSuccess("error"));
    }
}
function* getVaAlert() {
    yield takeLatest(alertActionType.FETCH_VA_ALERT, _getVaAlert)
}
                   //fetch  sensort report
function* _getSensorReport(action) {

    try {
        const trackingReport = yield getSensorReportDetails(action.data);
        yield put(fetchSensorReportSuccess(trackingReport));
    } catch (e) {
        yield put(fetchSensorReportError("error"));
    }
}
function* getSensorReport() {
    yield takeLatest(alertActionType.FETCH_SENSOR_REPORT, _getSensorReport)
}
                 //fetch  tagbalttery report
function* _getTagBatteryAlerts(action) {

    try {
        const trackingReport = yield getTagBatteryAlertsDetails(action.data);
        yield put(fetchTagBatteryAlertSuccess(trackingReport));
    } catch (e) {
        yield put(fetchTagBatteryAlertError("error"));
    }
}
function* getTagBatteryAlerts() {
    yield takeLatest(alertActionType.FETCH_TAG_BATTERY_ALERT, _getTagBatteryAlerts)
}
     //fetch  time based report
function* _getTimeBaesdReport(action) {

    try {
        const trackingReport = yield getTimeBasedReportDetails(action.data);
        yield put(fetchTimeBasedReportSuccess(trackingReport));
    } catch (e) {
        yield put(fetchTimeBasedReportError("error"));
    }
}
function* getTimeBaesdReport() {
    yield takeLatest(alertActionType.FETCH_TIME_BASED_REPORT, _getTimeBaesdReport)
}
          //fetch  top personnel report
function* _getTopPersonnelAlerts(action) {

    try {
        const trackingReport = yield getTopPersonnelAlertDetails(action.data);
        yield put(fetchTopPersonnelAlertSuccess(trackingReport));
    } catch (e) {
        yield put(fetchTopPersonnelAlertError("error"));
    }
}
function* getTopPersonnelAlerts() {
    yield takeLatest(alertActionType.FETCH_TOP_PERSONNEL_ALERTS, _getTopPersonnelAlerts)
}
     //fetch  tracking report
function* _getTrackingReport(action) {

    try {
        const trackingReport = yield getTrackingReportDetails(action.data);
        yield put(fetchTrackingReportSuccess(trackingReport));
    } catch (e) {
        yield put(fetchTrackingReportError("error"));
    }
}
function* getTrackingReport() {
    yield takeLatest(alertActionType.FETCH_TRACKING_REPORT, _getTrackingReport)
}
 
//fetch single sensor
function* _getSingleSensorAlert(action) {

    try {
        const singleSensorInfo = yield getSingleSensorDetails(action.id);
        yield put(fetchSinglesensorAlertSuccess(singleSensorInfo));
    } catch (e) {
        yield put(fetchSinglesensorAlertError("error"));
    }
}
function* getSingleSensorAlert() {
    yield takeLatest(alertActionType.FETCH_SINGLE_SENSOR_ALERT, _getSingleSensorAlert)
}
 
//fetch guages
function* _getAuditList(action) {

    try {
        const auditListInfo = yield getAditTrailListData(action.data);
        yield put(fetchAuditListSuccess(auditListInfo));
    } catch (e) {
        yield put(fetchAuditListError("error"));
    }
}
function* getAuditList() {
    yield takeLatest(alertActionType.FETCH_AUDITTRAIL_LIST, _getAuditList)
}
     //fetch guages
function* _getAlertTrend(action) {

    try {
        const trendInfo = yield fetchAlertTrendDetails(action.data);
        yield put(fetchAlertTrendSuccess(trendInfo));
    } catch (e) {
        yield put(fetchAlertTrendError("error"));
    }
}
function* getAlertTrend() {
    yield takeLatest(alertActionType.FETCH_ALERT_TREND, _getAlertTrend)
}
     //fetch guages
function* _getGuageValues(action) {

    try {
        const guageInfo = yield fetchGuageData(action.data);
        yield put(fetchDashboardGuagesSuccess(guageInfo));
    } catch (e) {
        yield put(fetchDashboardGuagesError("error"));
    }
}
function* getGuageValues() {
    yield takeLatest(alertActionType.FETCH_DASHBOARD_GUAGES, _getGuageValues)
}
//assign alert
function* _UpdateAlert(action) {

    try {
        const alertInfo = yield assignAlertDetails(action.data);
        yield put(assignAlertSuccess(alertInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(assignAlertError( error.response.data));
        }else{
            yield put(assignAlertError( error));
        }
    }
}
function* UpdateAlert() {
    yield takeLatest(alertActionType.ASSIGN_ALERT, _UpdateAlert)
}
//fetch single device
function* _getDevice(action) {

    try {
        const sentryInfo = yield getSingleDeviceDetails(action.id);
        yield put(fetchSingleDeviceAlertSuccess(sentryInfo));
    } catch (e) {
        yield put(fetchSingleDeviceAlertError("error"));
    }
}
function* getDevice() {
    yield takeLatest(alertActionType.FETCH_SINGLE_DEVICE_ALERT, _getDevice)
}
//fetch single sentry
function* _getSentry(action) {

    try {
        const sentryInfo = yield getSingleSentryDetails(action.id);
        yield put(fetchSingleSentrySuccess(sentryInfo));
    } catch (e) {
        yield put(fetchSingleSentryError("error"));
    }
}
function* getSentry() {
    yield takeLatest(alertActionType.FETCH_SINGLE_SENTRY, _getSentry)
}
//fetch single patrol
function* _getPatrol(action) {

    try {
        const patrolInfo = yield getSinglePatrolDetails(action.id);
        yield put(fetchSinglePatrolSuccess(patrolInfo));
    } catch (e) {
        yield put(fetchSinglePatrolError("error"));
    }
}
function* getPatrol() {
    yield takeLatest(alertActionType.FETCH_SINGLE_PATROL, _getPatrol)
}
//fetch alert list
function* _getAlertList(action) {

    try {
        const alertInfo = yield fetchAlertListData(action.data);
        yield put(fetchAlertListSuccess(alertInfo));
    } catch (e) {
        yield put(fetchAlertListError("error"));
    }
}
function* getAlertList() {
    yield takeLatest(alertActionType.FETCH_ALERT_LIST, _getAlertList)
}
export function* AlertSaga() {
    yield all([getAlertList(),getPatrol(),getSentry(),UpdateAlert(),getDevice(),getGuageValues(),getAlertTrend(),
    getAuditList(),getSingleSensorAlert(),getTrackingReport(),getTopPersonnelAlerts(),getTimeBaesdReport(),
    getTagBatteryAlerts(),getSensorReport(),getVaAlert(),getSurveillancePerformance(),getEncoderDropdown(),
    getBatteryPerformance(),getSingleCameraAlert(),getSingleCleanerAlert(),getCleanerAlert()])
}