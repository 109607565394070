import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { viewPersonnel } from '../../../../redux/actions/PersonnelAction';
import { Layout } from '../../../layout/Layout';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { dateOnly } from '../../../convertors/TimeConvertor';
import { PersonnelDetails } from './PersonnelDetails';
import { LocationDetails } from './LocationDetails';
import { ProjectDetails } from './ProjectDetails';
import dayjs from 'dayjs';
import { YellowBorderButton } from '../../../styles/ButtonStyles';
import { PiClockCounterClockwise } from "react-icons/pi";

export const PersonnelView = () => {
    const dispatch = useDispatch();
    const singlePersonnel = useSelector(state => state.personnel.viewPersonnel);
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");
    const [personnelDates, setPersonnelDates] = useState({
        permitDate: null,
        startDate: null,
        startDate: null
    })
    const [personnelData, setPersonnelData] = useState({});

    useEffect(() => {
        const id = sessionStorage.getItem('personnelViewId');
        dispatch(viewPersonnel(id))
    }, [])
    const dateFormat = (date) => {
        let formattedDate = dayjs(date);

        return formattedDate.format("MM/DD/YYYY")
    }
    useEffect(() => {
        if (!singlePersonnel.isFetching && singlePersonnel.payload && singlePersonnel.payload.success === true) {
            const personnel = singlePersonnel.payload.checkpoint_details;

            setPersonnelData(personnel);
            const tempStart = dateOnly(personnel.project_startdate);
            const tempEnd = dateOnly(personnel.project_enddate);
            const temppermit = dateOnly(personnel.insurace_date);
            const formattedStartDate = dateFormat(tempStart);
            const formattedEndDate = dateFormat(tempEnd);
            const formattedPermittedDate = dateFormat(temppermit);
            setPersonnelDates(prevPersonnelDates => ({
                ...prevPersonnelDates,
                permitDate: formattedPermittedDate,
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }));
        }
    }, [singlePersonnel]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const handleHistoryView =(id)=>{
       window.location.href= `/tracking/cleaner/history/${id}`
    }
    return (
        <Layout
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"tracking"}
            activeMenuKey={"tracking"}
            isCreate={true}
            activeSubtab={"three"}

        >
            {singlePersonnel.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} columnSpacing={3} sx={{
                    mt: 3, pb: 5, ml: '3% !important',
                    '@media (max-width: 600px)': { ml: '0% !important' }
                }}
                    className={isDarkMode ? "table_dark " : "table_light "} >
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                        <Typography
                            className='device_view_head'>
                            {personnelData.user_type == 1 ? 'Personnel Details' : 'Cleaner Details'}
                        </Typography>
                    </Grid>
                    {personnelData.user_type == 2 ?
                        <Grid item lg={7} md={7} sm={6} xs={6}   sx={{ display: 'flex', justifyContent: 'right'}}>
                            <YellowBorderButton
                            onClick={()=>handleHistoryView(personnelData.id)}
                            startIcon={<PiClockCounterClockwise color=" #FFAC0B"/>}
                                >Cleaner History
                            </YellowBorderButton>
                        </Grid>
                        : <></>
                    }
                    <Grid item lg={11} sx={{ mt: 2 }}>
                        <PersonnelDetails
                            personnelData={personnelData}
                        />

                    </Grid>
                    <Grid item lg={12} sx={{ mt: 3 }}>
                        <Typography
                            className='device_view_head'>Project Details</Typography>
                    </Grid>
                    <Grid item lg={11} sx={{ mt: 2 }}>
                        <ProjectDetails
                            personnelData={personnelData}
                            personnelDates={personnelDates}
                        />
                    </Grid>
                    {/* <Grid item lg={11} sx={{ mt: 2 }}>
                    <div className='device_view'>
                        <Grid container >
                        </Grid>
                    </div>
                </Grid> */}
                    <Grid item lg={12} >
                        <Typography
                            className='device_view_head'>Location</Typography>

                    </Grid>
                    <Grid item lg={11} sx={{ mt: 2 }}>
                        <LocationDetails
                            personnelData={personnelData}
                        />
                    </Grid>

                </Grid>
            }
        </Layout>
    )
}