import { Alert, Checkbox, CircularProgress, Grid, IconButton, Snackbar, Table, TableBody, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomizedTableCell, CustomizedTableRow } from '../../styles/TablebButtonStyles';
import { useDispatch } from 'react-redux';
import {
    clearDeletePersonnelStatus, clearUnassignPersonnelStatus, clearbulkDeletePersonnelStatus, clearsendTelegramMsgPersonnelStatus, deletePersonnel, deletebulkPersonnel,
    fetchPersonnelList,
    sendTelegramMsgPersonnel,
    unassignPersonnel
} from '../../../redux/actions/PersonnelAction';
import { useSelector } from 'react-redux';
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DeleteButton, LightBorderButton, YellowBorderButton } from '../../styles/ButtonStyles';
import { DeleteModal } from '../../Modals/DeleteModal';
import { Paginations } from '../../sharable/pagination/Pagination';
import { FiTag } from "react-icons/fi";
import { TbBrandTelegram } from "react-icons/tb";
import { iconStyle } from '../../styles/ModalStyle';
import { PersonnelUnAssignModal } from '../../Modals/PersonelUnassignModal';
import SyncIcon from '@mui/icons-material/Sync';
import { TelegramMessagingModal } from '../../Modals/TelegramMessagingModal';

export const PersonnelListTable = ({ isDarkMode, selectedSites, selectedType, searchWord, isWidget,
    handleRefresh }) => {
    const [personnelList, setPersonnelList] = useState([])
    const [deletePersonnelName, setDeletePersonnelName] = useState('');
    const [deletePersonnelId, setDeletePersonnelId] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isBulkDelete, setIsBulkDelete] = useState(false);
    const dispatch = useDispatch();
    const personnelData = useSelector(state => state.personnel.personnelList);
    const unAssignedStatus = useSelector(state => state.personnel.unassignPersonnel);
    const messagingStatus = useSelector(state => state.personnel.telegramMsg);

    const deletionStatus = useSelector(state => state.personnel.deletedPerson);
    const bulkDeletionStatus = useSelector(state => state.personnel.bulkDeletedPersonnels);
    const [unAssignPersonnelName, setUnAssignPersonnelName] = useState('');
    const [unAssignPersonnelId, setUnAssignPersonnelId] = useState(0);
    const [unAssignTagId, setunAssignTagId] = useState(0);
    const [unAssignOrgId, setunAssignOrgId] = useState(0);
    const [openMessagingModal, setOpenMessegingModal] = useState(false);
    const [openUnassignModal, setOpenUnassignModal] = useState(false);
    const [unAssignshowAlert, setUnAssignShowAlert] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')
    const [cleanerName, setCleanerName] = useState('');
    const [cleanerId, setCleanerId] = useState(0);
    const [msgForCleaner, setMsgForCleaner] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [selectedDelete, setSelectedDelete] = useState([]);
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            start: start,
            limit: limit,
            site_id: selectedSites,
            searchWord: searchWord,
            user_type: selectedType
        }
        dispatch(fetchPersonnelList(data));
    }, [selectedSites, searchWord, selectedType])
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            start: start,
            limit: limit
        }
        dispatch(fetchPersonnelList(data));
    }, [currentPage]);
    useEffect(() => {
        if (!personnelData.isFetching && personnelData.payload && personnelData.payload.success === true) {
            const personnel = personnelData.payload.data;
            const tempPagination = personnelData.payload.pagination;

            const temp = personnel.map((person) => {
                //   const timestamp = person.traceData?.contactendtime * 1000;
                //  if(person.lastseenat !== 0)
                //  const timestamp = person.lastseenat * 1000;
                let lastseendate = "NA";
                let lastseentime = "NA";

                if (person.lastseenat !== 0) {
                    const timestamp = person.lastseenat * 1000;
                    const dateObject = new Date(timestamp);

                    // Get the date part
                    lastseendate = dateObject.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    });

                    // Get the time part
                    lastseentime = dateObject.toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    });
                }

                return {
                    id: person.id,
                    name: person.contractorname,
                    emp_id: person.emp_id,
                    tag_id: person.tag_id > 0 ? person.infant_name : "NA",
                    route: person.routes,
                    duty_type: person.duty_type,
                    tag_assigned: person.tag_assigned,
                    route_Data: person.route_Data,
                    sentry_points: person.sentry_points,
                    checkpoint_Data: person.checkpoint_Data,
                    isDelete: false,
                    infant_id: person.tag_id,
                    // tag_assigned: person.tag_assigned,
                    active_checkpoint: person.traceData !== null ? person.traceData.checkpointinfo.checkpointname : "NA",
                    last_seen_date: person.lastseenat !== 0 ? lastseendate : "NA",
                    last_seen_time: person.lastseenat !== 0 ? lastseentime : "NA",
                    organization_id: person.organization_id,
                    location: person.listener

                }
            });
            setPersonnelList(temp);
            setPageCount(tempPagination.total_pages);
        }
        if (!deletionStatus.isFetching && deletionStatus.payload && deletionStatus.payload.success === true) {

            setIsBulkDelete(false);
            sessionStorage.setItem('trackingTab', 'three')
            // window.location.reload();

            window.location.href = '/tracking';
        }
        if (!deletionStatus.isFetching && deletionStatus.error) {
            setIsBulkDelete(false);
            setShowAlert(true);
            const error = deletionStatus.errorMessage.message;
            setAlertMessage(error);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            dispatch(clearDeletePersonnelStatus());
            return () => clearTimeout(timeoutId);
        }
        if (!bulkDeletionStatus.isFetching && bulkDeletionStatus.payload && bulkDeletionStatus.payload.success === true) {

            setIsBulkDelete(false);
            sessionStorage.setItem('trackingTab', 'three')
            // window.location.reload();

            window.location.href = '/tracking';
        }
        if (!bulkDeletionStatus.isFetching && bulkDeletionStatus.error) {
            setIsBulkDelete(false);
            setShowAlert(true);
            const error = bulkDeletionStatus.errorMessage.message;
            setAlertMessage(error);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            dispatch(clearbulkDeletePersonnelStatus());
            return () => clearTimeout(timeoutId);
        }
        if (!unAssignedStatus.isFetching && unAssignedStatus.payload && unAssignedStatus.payload.success === true) {

            setOpenDeleteModal(false);
            sessionStorage.setItem('trackingTab', 'three')
            // window.location.reload();

            window.location.href = '/tracking';
        }
        if (!unAssignedStatus.isFetching && unAssignedStatus.error) {
            setOpenDeleteModal(false);
            setUnAssignShowAlert(true);
            const error = unAssignedStatus.errorMessage.message;
            setAlertMessage(error);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearUnassignPersonnelStatus());

            }, 2000);
            return () => clearTimeout(timeoutId);

        }
        if (!messagingStatus.isFetching && messagingStatus.payload && messagingStatus.payload.success === true) {

            setOpenMessegingModal(false);
            setMsgForCleaner('');
            setIsError(false);

            setShowAlert(true);
            const success = messagingStatus.payload.message;
            setAlertMessage(success);

            const timeoutId = setTimeout(() => {
                setShowAlert(false);

            }, 2000);
            dispatch(clearsendTelegramMsgPersonnelStatus());
            return () => clearTimeout(timeoutId);
        }
        if (!messagingStatus.isFetching && messagingStatus.error) {

            setOpenMessegingModal(false);
            setMsgForCleaner('');
            setShowAlert(true);
            setIsError(true);

            const error = messagingStatus.errorMessage.message;
            setAlertMessage(error);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                setIsError(false);

            }, 2000);
            dispatch(clearsendTelegramMsgPersonnelStatus());
            return () => clearTimeout(timeoutId);

        }
    }, [personnelData, deletionStatus, bulkDeletionStatus, unAssignedStatus, messagingStatus]);
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleMessagingModal = () => {
        setOpenMessegingModal(!openMessagingModal);
        setMsgForCleaner('');
    };
    const handleMessaging = (name, id) => {
        setOpenMessegingModal(true);
        setCleanerName(name);
        setCleanerId(id);
    }
    const handleView = (id) => {
        sessionStorage.setItem('personnelViewId', id);

        window.location.href = '/tracking/personnel/view'
    }
    const handleEdit = (id) => {
        sessionStorage.setItem('EditPersonnelId', id);
        window.location.href = '/tracking/personnel/edit';
    }
    const handleDelete = (id, name) => {
        setDeletePersonnelId(id);
        setDeletePersonnelName(name);
        setOpenDeleteModal(true);
    }
    const handleDeleteModal = () => {
        setOpenDeleteModal(false);
    }
    const onDeleteConfirm = () => {
        const id = deletePersonnelId;
        dispatch(deletePersonnel(id))
    }
    const onBulkDeleteConfirm = () => {

        if (selectedDelete.length == 1) {
            const id = selectedDelete[0];
            const data = {
                id: id,
                //  count: selectedDelete.length,
                created_user: parseInt(sessionStorage.getItem('id')),
                org_id: parseInt(sessionStorage.getItem('org_Id')),

            };
            dispatch(deletePersonnel(data))


        }
        else {
            const data = {
                contractor_id: selectedDelete,
                count: selectedDelete.length,
                created_user: parseInt(sessionStorage.getItem('id')),
                org_id: parseInt(sessionStorage.getItem('org_Id')),

            };
            dispatch(deletebulkPersonnel(data))
        }
    }
    const handleAssign = (id) => {
        sessionStorage.setItem('perssonelAssignId', id);
        setIsAssigned(true);
        window.location.href = '/tracking/personnel/assign';
    }
    const handleCheckboxChange = (id) => {
        const isSelected = selectedDelete.includes(id);

        // Update the selected features based on the current state
        if (isSelected) {
            setSelectedDelete(selectedDelete.filter(item => item !== id));
        } else {
            setSelectedDelete([...selectedDelete, id]);
        }


    };
    const handleBulkDelete = () => {
        setIsBulkDelete(false);
    }
    const handleUnAssign = (id, name, tag, assign, org_id) => {
        if (assign > 0) {
            setUnAssignPersonnelName(name);
            setUnAssignPersonnelId(id);
            setunAssignTagId(tag);
            setunAssignOrgId(org_id);
            setOpenUnassignModal(true);
        }
        else {
            setSnackBarOpen(true);
        }
    }
    const handleSnackClose = () => {
        setSnackBarOpen(false)
    }
    const handleUnAssignmodal = () => {
        setOpenUnassignModal(false);

    }
    const onUnAssignConfirm = () => {

        const data = {
            id: unAssignPersonnelId,
            tag_id: unAssignTagId,
            date_time: new Date().toISOString(),
            created_user: parseInt(sessionStorage.getItem('id')),
            organization_id: parseInt(unAssignOrgId)
        }
        dispatch(unassignPersonnel(data));
    }
    const handlealertClose = () => {
        setShowAlert(false);
        setUnAssignShowAlert(false);
    }
    const handleTelegramTextChange = (value) => {
        setMsgForCleaner(value)
    }
    const handleSendMsg = () => {
        const data = {
            org_id: parseInt(sessionStorage.getItem('org_Id')),
            person_id: cleanerId,
            message: msgForCleaner
        }

        dispatch(sendTelegramMsgPersonnel(data))
    }
    return (
        <div className='table_Scroll '>
            <DeleteModal
                handleDeleteModal={handleDeleteModal}
                openDeleteModal={openDeleteModal}
                ModalHead={'Delete Personnel'}
                ModalBody={deletePersonnelName}
                onDeleteConfirm={onDeleteConfirm}
            />
            <DeleteModal
                handleDeleteModal={handleBulkDelete}
                openDeleteModal={isBulkDelete}
                ModalHead={'Delete Personnels'}
                ModalBody={'the selected personnels'}
                onDeleteConfirm={onBulkDeleteConfirm}
            />
            <PersonnelUnAssignModal
                openModal={openUnassignModal}
                onClose={handleUnAssignmodal}
                modalbody={unAssignPersonnelName}
                onUnAssignConfirm={onUnAssignConfirm}
            />
            <TelegramMessagingModal
                handleMessagingModal={handleMessagingModal}
                openMessagingModal={openMessagingModal}
                name={cleanerName}
                handleSendMsg={handleSendMsg}
                handleTelegramTextChange={handleTelegramTextChange}
                msgForCleaner={msgForCleaner}
            />
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}

                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity="warning"
                    variant="outlined"
                    sx={{ width: '100', color: '#FFAC0B', backgroundColor: 'white' }}

                >
                    Personnel not assigned! Only assigned personnel can be unassigned.
                </Alert>
            </Snackbar>
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity={isError ? "error" : "success"}
                    variant="outlined"
                    sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : 'white' }}

                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={unAssignshowAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity={isError ? "error" : "success"}
                    variant="outlined"
                    sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : 'white' }}

                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            {/* {showAlert ?
                <Alert variant="outlined" severity="error">Personnel deletion Failed</Alert> : <></>
            }
            {
                unAssignshowAlert ? <Alert variant="outlined" severity="error">Personnel UnAssign Failed</Alert> : <></>

            } */}
            {
                personnelData.isFetching ?
                    <Grid item lg={12} md={12} sm={12} xs={12} container
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Grid>
                    :
                    personnelList.length && personnelList !== 'undefined' ?
                        <>
                            {/* {JSON.stringify(selectedDelete)} */}


                            <Grid container className="">
                                <Grid item lg={11.5} sm={11.5} md={11.5} xs={11.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {selectedDelete.length ?
                                        <Grid item
                                            // lg={12} sm={12} md={12} xs={12} className="alignR" 
                                            sx={{ mr: "2.5%" }}
                                        >
                                            <DeleteButton
                                                sx={{ mt: '2px !important', pt: '9px !important', pb: '9px !important' }} variant="contained" color="error"
                                                //disabled={selectedDelete.length === 0}
                                                onClick={() => setIsBulkDelete(!isBulkDelete)}
                                            >
                                                <RiDeleteBin6Line style={iconStyle} />
                                                Delete
                                            </DeleteButton>
                                        </Grid>
                                        : <></>
                                    }
                                    {!isWidget &&
                                        <Grid item
                                        // xs={2} sm={2} lg={3} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}
                                        // sx={{ ml: '4% !important' }}
                                        >
                                            <YellowBorderButton
                                                startIcon={<SyncIcon style={{ color: 'black' }} />}
                                                sx={{ pl: '30% !important' }}
                                                onClick={handleRefresh}
                                            >

                                            </YellowBorderButton>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>


                            <Table className="table_mob" sx={{ mt: 2, mb: 2, width: "100%" }} >
                                <TableHead>
                                    <TableRow>
                                        <CustomizedTableCell>Name</CustomizedTableCell>
                                        {/* <CustomizedTableCell>Employee Id</CustomizedTableCell> */}
                                        <CustomizedTableCell>Tag </CustomizedTableCell>
                                        <CustomizedTableCell>Schedule</CustomizedTableCell>
                                        <CustomizedTableCell>Duty Type </CustomizedTableCell>
                                        {/* <CustomizedTableCell>Alert</CustomizedTableCell> */}
                                        <CustomizedTableCell>Assign</CustomizedTableCell>
                                        <CustomizedTableCell>Location</CustomizedTableCell>
                                        <CustomizedTableCell>Last Seen</CustomizedTableCell>
                                        <CustomizedTableCell >Actions</CustomizedTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {personnelList.map((item, index) => (
                                        <CustomizedTableRow key={index}>
                                            <CustomizedTableCell>
                                                <div className='personel_name'>
                                                    {item.name}
                                                </div></CustomizedTableCell>
                                            {/* <CustomizedTableCell>{item.emp_id}</CustomizedTableCell> */}
                                            <CustomizedTableCell>{item.tag_id}</CustomizedTableCell>

                                            <CustomizedTableCell>
                                                {/* {item.route} */}
                                                {(!item.route?.length && !item.checkpoint_Data?.length) ? "- -" :
                                                    item.route?.length ?
                                                        <div className="person_route_table_div">
                                                            {item.route_Data.map((obj, index) =>
                                                                <span className="route_text_div" key={index}>{obj.routename}{'  '}</span>
                                                            )}
                                                        </div> : <div className="person_route_table_div">
                                                            {item.checkpoint_Data.map((obj, index) =>
                                                                <span className="route_text_div" key={index}>{obj.check_point_name}{'  '}</span>
                                                            )}
                                                        </div>

                                                }
                                            </CustomizedTableCell>
                                            <CustomizedTableCell>{item.duty_type === 0 ? "- -" :
                                                item.duty_type === 2 ?
                                                    <span className="route_text_div">Patrol</span> :
                                                    item.duty_type === 1 ?
                                                        <span className="route_text_div">Sentry</span> :
                                                        <span className="route_text_div">Cleaner</span>

                                            }</CustomizedTableCell>

                                            {/* <CustomizedTableCell>
                                                <Tooltip title={"Send message on Telegram"}>
                                                <LightBorderButton 
                                                disabled ={item.duty_type != 4 ? true :false}
                                                startIcon={<TbBrandTelegram color={item.duty_type == 4 ? "#484747" : "#bdb9b9"} />                                            }
                                                >

                                                </LightBorderButton>
                                                   
                                                </Tooltip>
                                            </CustomizedTableCell> */}
                                            <CustomizedTableCell>
                                                <LightBorderButton
                                                    disabled={item.infant_id > 0 ? true : false}
                                                    onClick={() => { handleAssign(item.id) }}
                                                >
                                                    <img src={require("../../../assets/icons/assign.png")} />
                                                    Assign
                                                </LightBorderButton>
                                            </CustomizedTableCell>
                                            <CustomizedTableCell>
                                                <div className="active_loc">
                                                    <span className={item.location !== "NA" ? "alert_stage_text active_loc_text"
                                                        : ""} >
                                                        {item.location}
                                                    </span>
                                                </div>
                                            </CustomizedTableCell>
                                            <CustomizedTableCell>
                                                <div className="date_div">
                                                    {item.last_seen_date == "NA" ? '' : item.last_seen_date}
                                                    {item.last_seen_time == "NA" ? ' ' : ', '}
                                                    {item.last_seen_time == "NA" ? 'NA' : item.last_seen_time}
                                                </div>

                                            </CustomizedTableCell>

                                            <CustomizedTableCell>
                                                <Tooltip title={item.tag_assigned > 0 ? "UnAssign Personnel" : "Personnel is not assigned"}>
                                                    <IconButton
                                                        onClick={() => handleUnAssign(item.id, item.name, item.infant_id, item.tag_assigned, item.organization_id)}
                                                    >
                                                        <FiTag color={item.tag_assigned > 0 ? "#C61A1A" : "#FFAC0B"} />
                                                    </IconButton>
                                                </Tooltip>
                                                <IconButton
                                                    onClick={() => handleView(item.id)}
                                                >
                                                    <IoEyeOutline color="#161616" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => handleEdit(item.id)}
                                                >
                                                    <MdOutlineEdit color="black" />
                                                </IconButton>
                                                <Tooltip title={"Select to delete Personnel"}>
                                                    <Checkbox
                                                        checked={selectedDelete.includes(item.id)}
                                                        onChange={() => handleCheckboxChange(item.id)}

                                                        sx={{
                                                            color: isDarkMode ? "white" : "#161616",
                                                            // backgroundColor: isDarkMode?"#1E1D2C":"#E9E9E9",
                                                            '&.Mui-checked': {
                                                                color: isDarkMode ? "white" : "#161616",
                                                                //   backgroundColor:  isDarkMode?"#1E1D2C":"#E9E9E9"
                                                            },
                                                        }}
                                                        size="small"
                                                    />
                                                </Tooltip>
                                                <Tooltip title={"Send message on Telegram"}>
                                                    <IconButton
                                                        disabled={item.duty_type != 4 ? true : false}
                                                        onClick={() => handleMessaging(item.name, item.id)}
                                                    >
                                                        <TbBrandTelegram color={item.duty_type == 4 ? "#484747" : "#bdb9b9"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </CustomizedTableCell>
                                        </CustomizedTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div>
                                <Paginations
                                    isDarkMode={isDarkMode}
                                    pageCount={pageCount}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </> : <>No Data Found</>
            }
        </div>
    )
}