import {
  addEncoderError, addEncoderSuccess,addPowerError,addPowerSuccess,addSensorError,addSensorSuccess,deleteEncoderError,
  deleteEncoderSuccess,deletePowerError,deletePowerSuccess,deleteSensorError,deleteSensorSuccess, deviceOnboardingError,
  deviceOnboardingSuccess, editEncoderError, editEncoderSuccess, editPowerError, editPowerSuccess, fetchBatteryDropdownError,
  fetchBatteryDropdownSuccess,fetchCameraDropdownError, fetchCameraDropdownSuccess, fetchCameraHistoryError, fetchCameraHistorySuccess,
   fetchIotDeviceListError,
   fetchIotDeviceListSuccess,
   fetchPowerListError, fetchPowerListSuccess, fetchSensorDropdownError, fetchSensorDropdownSuccess, fetchSensorListError,
  fetchSensorListSuccess,fetchSinglePowerError,fetchSinglePowerSuccess,fetchSurveillanceDeviceListError,
  fetchSurveillanceDeviceListSuccess,getMapError,getMapSuccess,getSingleEncoderError,getSingleEncoderSuccess,
  getSingleSensorError,getSingleSensorSuccess,surveillanceActionType,updateCameraStatusError,updateCameraStatusSuccess,
  updateSensorError,updateSensorSuccess,
} from "../actions/SurveillanceAction";
import { all, put, takeLatest } from "redux-saga/effects";
import {
  fetchSensorListData,
  fetchVmsDeviceListData,
  editSensorDetails,
  addSensorDetails,
  getSingleSesorDetails,
  deleteSensorData,
  getGatewayDropdown,
  getSensorDropdownlist,
  getBatteryDropdownlist,
  addEncoderDetails,
  fetchsingleMap,
  addPowerDetails,
  getPowerListData,
  deletePowerData,
  updatePowerDetails,
  getSinglePowerData,
  fetchSingleEncoder,
  deleteEncoderDetails,
  editEncoderDetails,
  editCameraStatus,
  getCameraHistoryDetails,
  uploadDevices,
  fetchIotDeviceDropDown,
} from "../../services/SurveillanceSurvices";
//fetch iot device lit
function* _getIotList(action) {
  try {
    const deviceUpload = yield fetchIotDeviceDropDown(action.data);
    console.log(deviceUpload)
    yield put(fetchIotDeviceListSuccess(deviceUpload));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(fetchIotDeviceListError( e.response.data));
  }else{
      yield put(fetchIotDeviceListError( e));
  }
  }
}
function* getIotList() {
  yield takeLatest(surveillanceActionType.FETCH_IOT_LIST, _getIotList);
}
//import device 
function* _importDevices(action) {
  try {
    const deviceUpload = yield uploadDevices(action.data);
    yield put(deviceOnboardingSuccess(deviceUpload));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(deviceOnboardingError( e.response.data));
  }else{
      yield put(deviceOnboardingError( e));
  }
  }
}
function* importDevices() {
  yield takeLatest(surveillanceActionType.DEVICE_ONBOARD, _importDevices);
}
//fetch camera history 
function* _getCameraHistory(action) {
  try {
    const camStatus = yield getCameraHistoryDetails(action.data);
    yield put(fetchCameraHistorySuccess(camStatus));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(fetchCameraHistoryError( e.response.data));
  }else{
      yield put(fetchCameraHistoryError( e));
  }
  }
}
function* getCameraHistory() {
  yield takeLatest(surveillanceActionType.FETCH_CAMERA_HISTORY, _getCameraHistory);
}
//fetch single powwer 
function* _changeCameraStatus(action) {
  try {
    const camStatus = yield editCameraStatus(action.data);
    yield put(updateCameraStatusSuccess(camStatus));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(updateCameraStatusError( e.response.data));
  }else{
      yield put(updateCameraStatusError( e));
  }
  }
}
function* changeCameraStatus() {
  yield takeLatest(surveillanceActionType.UPDATE_CAMERA_STATUS, _changeCameraStatus);
}
//fetch single powwer 
function* _getSinglePower(action) {
  try {
    const powerData = yield getSinglePowerData(action.id);
    yield put(fetchSinglePowerSuccess(powerData));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(fetchSinglePowerError( e.response.data));
  }else{
      yield put(fetchSinglePowerError( e));
  }
  }
}
function* getSinglePower() {
  yield takeLatest(surveillanceActionType.FETCH_SINGLE_POWER, _getSinglePower);
}
//dlete powwer 
function* _updatePower(action) {
  try {
    const powerData = yield updatePowerDetails(action.id,action.data);
    yield put(editPowerSuccess(powerData));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(editPowerError( e.response.data));
  }else{
      if(e.response && e.response.data && e.response.data){
        yield put(editPowerError( e.response.data));
    }else{
        yield put(editPowerError( e));
    } 
  }
  }
}
function* updatePower() {
  yield takeLatest(surveillanceActionType.EDIT_POWER, _updatePower);
}
//dlete powwer 
function* _removePower(action) {
  try {
    const powerData = yield deletePowerData(action.data);
    yield put(deletePowerSuccess(powerData));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(deletePowerError( e.response.data));
  }else{
      yield put(deletePowerError( e));
  }
  }
}
function* removePower() {
  yield takeLatest(surveillanceActionType.DELETE_POWER, _removePower);
}
//get powwer list
function* _getPowerList(action) {
  try {
    const powerData = yield getPowerListData(action.data);
    yield put(fetchPowerListSuccess(powerData));
  } catch (e) {
    yield put(fetchPowerListError("error"));
  }
}
function* getPowerList() {
  yield takeLatest(surveillanceActionType.FETCH_POWERLIST, _getPowerList);
}
//add powwer
function* _postPowerData(action) {
  try {
    const powerData = yield addPowerDetails(action.data);
    yield put(addPowerSuccess(powerData));
  } catch (e) {
   
    if(e.response && e.response.data && e.response.data){
      yield put(addPowerError( e.response.data));
  }else{
      yield put(addPowerError( e));
  } 
  }
}
function* postPowerData() {
  yield takeLatest(surveillanceActionType.ADD_POWER, _postPowerData);
}
//Delete encoder
function* _deleteEncoderData(action) {
  try {
    const encoderData = yield deleteEncoderDetails(action.data);
    yield put(deleteEncoderSuccess(encoderData));
  } catch (e) {
        if(e.response && e.response.data && e.response.data){
      yield put(deleteEncoderError( e.response.data));
  }else{
      yield put(deleteEncoderError( e));
  } 
  }
}
function* deleteEncoderData() {
  yield takeLatest(surveillanceActionType.DELETE_ENCODER, _deleteEncoderData);
}
//edit encoder
function* _updateEncoderData(action) {
  try {
    const encoderData = yield editEncoderDetails(action.id,action.data);
    yield put(editEncoderSuccess(encoderData));
  } catch (e) {
    if(e.response && e.response.data && e.response.data){
  yield put(editEncoderError( e.response.data));
}else{
  yield put(editEncoderError( e));
} 
}
}
function* updateEncoderData() {
  yield takeLatest(surveillanceActionType.EDIT_ENCODER, _updateEncoderData);
}
//get single encoder
function* _getSingleEncoderData(action) {
  try {
    const encoderData = yield fetchSingleEncoder(action.data);
    yield put(getSingleEncoderSuccess(encoderData));
  } catch (e) {
    yield put(getSingleEncoderError("error"));
  }
}
function* getSingleEncoderData() {
  yield takeLatest(surveillanceActionType.FETCH_SINGLE_ENCODER, _getSingleEncoderData);
}
//add encoder
function* _postEncoderData(action) {
  try {
    const encoderData = yield addEncoderDetails(action.data);
    yield put(addEncoderSuccess(encoderData));
  }catch (e) {
    if(e.response && e.response.data && e.response.data){
  yield put(addEncoderError( e.response.data));
}else{
  yield put(addEncoderError( e));
} 
}
}
function* postEncoderData() {
  yield takeLatest(surveillanceActionType.ADD_ENCODER, _postEncoderData);
}
//battery dropdown
function* _getBatteryDropdown(action) {
  try {
    const batterydrop = yield getBatteryDropdownlist(action.data);
    yield put(fetchBatteryDropdownSuccess(batterydrop));
  } catch (e) {
    yield put(fetchBatteryDropdownError("error"));
  }
}
function* getBatteryDropdown() {
  yield takeLatest(
    surveillanceActionType.FETCH_BATTERY_DROPDOWN,
    _getBatteryDropdown
  );
}
//sensor dropdown
function* _getSensorDropdown(action) {
  try {
    const sensordrop = yield getSensorDropdownlist(action.data);
    yield put(fetchSensorDropdownSuccess(sensordrop));
  } catch (e) {
    yield put(fetchSensorDropdownError("error"));
  }
}
function* getSensorDropdown() {
  yield takeLatest(
    surveillanceActionType.FETCH_SENSOR_DROPDOWN,
    _getSensorDropdown
  );
}
//camera dropdown
function* _getCameraDropdown(action) {
  try {
    const cameradrop = yield getGatewayDropdown(action.data);
    yield put(fetchCameraDropdownSuccess(cameradrop));
  } catch (e) {
    yield put(fetchCameraDropdownError("error"));
  }
}
function* getCameraDropdown() {
  yield takeLatest(
    surveillanceActionType.FETCH_CAMERA_DROPDOWN,
    _getCameraDropdown
  );
}
//delete single sensor
function* _deleteSensor(action) {
  try {
    const singleSensor = yield deleteSensorData(action.data);
    yield put(deleteSensorSuccess(singleSensor));
  } catch (e) {
    if(e.response && e.response.data && e.response.data){
      yield put(deleteSensorError( e.response.data));
  }else{
      yield put(deleteSensorError( e));
  }  }
}
function* deleteSensor() {
  yield takeLatest(surveillanceActionType.DELETE_SENSOR, _deleteSensor);
}
//update single sensor
function* _editSensor(action) {
  try {
    const singleSensor = yield editSensorDetails(action.id, action.data);
    yield put(updateSensorSuccess(singleSensor));
  } catch (e) {
    if(e.response && e.response.data && e.response.data){
      yield put(updateSensorError( e.response.data));
  }else{
      yield put(updateSensorError( e));
  }
  }
}
function* editSensor() {
  yield takeLatest(surveillanceActionType.UPDATE_SENSOR, _editSensor);
}
//fetch single sensor
function* _getSingleSensor(action) {
  try {
    const singleSensor = yield getSingleSesorDetails(action.id);
    yield put(getSingleSensorSuccess(singleSensor));
  } catch (e) {
    yield put(getSingleSensorError("error"));
  }
}
function* getSingleSensor() {
  yield takeLatest(
    surveillanceActionType.FETCH_SINGLE_SENSOR,
    _getSingleSensor
  );
}
//fetch sensor list
function* _postSensorData(action) {
  try {

    const sensorList = yield addSensorDetails(action.data);
    yield put(addSensorSuccess(sensorList));
  } catch (e) {
    if(e.response && e.response.data && e.response.data){
      yield put(addSensorError( e.response.data));
  }else{
      yield put(addSensorError( e));
  }
  }
}
function* postSensorData() {
  yield takeLatest(surveillanceActionType.ADD_SENSOR, _postSensorData);
}
//fetch sensor list
function* _fetchSensorListDetails(action) {
  try {
    const sensorList = yield fetchSensorListData(action.data);
    yield put(fetchSensorListSuccess(sensorList));
  } catch (e) {
    yield put(fetchSensorListError("error"));
  }
}
function* fetchSensorListDetails() {
  yield takeLatest(
    surveillanceActionType.FETCH_SENSOR_LIST,
    _fetchSensorListDetails
  );
}
//fetch vms device list
function* _fetchVmsDeviceList(action) {
  try {
    const vmsDeviceList = yield fetchVmsDeviceListData(action.data);
    yield put(fetchSurveillanceDeviceListSuccess(vmsDeviceList));
  } catch (e) {
    yield put(fetchSurveillanceDeviceListError("error"));
  }
}
function* fetchVmsDeviceList() {
  yield takeLatest(
    surveillanceActionType.FETCH_VMS_DEVICE_LIST,_fetchVmsDeviceList
  );
}
//fetch map data
function* _fetchSuveillanceMap(action) {
  try {
    const mapData = yield fetchsingleMap(action.data);
    yield put(getMapSuccess(mapData));
  } catch (e) {
    yield put(getMapError("error"));
  }
}
function* fetchSuveillanceMap() {
  yield takeLatest(
    surveillanceActionType.FETCH_SURVEILLANCE_MAP,
    _fetchSuveillanceMap
  );
}
export function* SurveillanceSaga() {
  yield all([
    getIotList(),
    fetchVmsDeviceList(),
    fetchSensorListDetails(),
    postSensorData(),
    getSingleSensor(),
    editSensor(),
    deleteSensor(),
    getCameraDropdown(),
    getSensorDropdown(),
    getBatteryDropdown(),
    postEncoderData(),
    postPowerData(),
    fetchSuveillanceMap(),
    getPowerList(),
    removePower(),
    updatePower(),
    getSinglePower(),
    getSingleEncoderData(),
    deleteEncoderData(),
    updateEncoderData(),
    changeCameraStatus(),
    getCameraHistory(),
    importDevices()
  ]);
}
