import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import {
  MenuProps,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../Styles";
import SearchIcon from "@mui/icons-material/Search";
import { StyledCreateButton } from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RxDownload } from "react-icons/rx";

export const CameraFilter = ({
  isAssigned,
  handleAssignAssets,
  selectedSites,
  handleSites,
  sites,
  levels,
  selectedLevel,
  handleLevels,
  blocks,
  selectedBlocks,
  handleBlocks,
  search,
  onChangeSearch,
  handleAddDevice,
  handleImportModal, isWidget, removeWidget, id
}) => {
  return (
    <Grid container sx={{ mt: 4 }} columnSpacing={2}>
      {!isWidget && <>
       <Grid item lg={2.5} md={2.5} xs={12} sm={12} sx={{ ml: -3 }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon
              sx={{ color: "#8F8F91" }}
              fontSize="medium"
              className="mb"
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search Stream ID"
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        </Search>
      </Grid>
        <Grid
          item
          lg={isAssigned ? 2.5 : 4.5}
          md={isAssigned ? 2.5 : 4.5}
          xs={12}
          sm={12}
          sx={{ml:3,'@media (max-width: 600px)': {ml:0}}}
        >
          <Grid container className="mt2">
            <Grid item sx={{ mt: 2 }}>
              <Typography
                sx={{ textAlign: "center" }}
                className={"headersubhead light_text"}
              >
                Assign :
              </Typography>
            </Grid>
            <Grid item lg={4} sm={8.5} xs={8.5} md={4}>
              <Select
                //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                sx={{
                  color: "#161616",
                  ml: 1,
                  minWidth: 135,
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                    border: "",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(233, 233, 233, 1)",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "#161616",
                  },
                }}
                displayEmpty
                MenuProps={MenuProps}
                className={"headerSelect select_light"}
                value={isAssigned}
                onChange={(e) => handleAssignAssets(e.target.value)}
              >
                <MenuItem value={0}>Un Assigned</MenuItem>
                <MenuItem value={1}>Assigned</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        {isAssigned ? (
          <>
            {" "}
            <Grid item lg={2.3} md={2.3} xs={12} sm={12}>
              <Grid container className="mt2">
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    sx={{ textAlign: "center" }}
                    className={"headersubhead light_text"}
                  >
                    Sites :
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={9} xs={9} md={4}>
                  <Select
                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                    sx={{
                      color: "#161616",
                      ml: 1,
                      minWidth: 135,
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(233, 233, 233, 1)",
                        border: "",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(233, 233, 233, 1)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(233, 233, 233, 1)",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#161616",
                      },
                    }}
                    displayEmpty
                    MenuProps={MenuProps}
                    className={"headerSelect select_light"}
                    value={selectedSites}
                    onChange={(e) => handleSites(e.target.value)}
                  >
                    {" "}
                    <MenuItem value={""}>All</MenuItem>
                    {sites.map((site, index) => (
                      <MenuItem key={index} value={site.organizationId}>
                        {site.organizationName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2.3} md={2.3} xs={12} sm={12} sx={{ mr: 1 }}>
              <Grid container className="mt2">
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    sx={{ textAlign: "center" }}
                    className={"headersubhead light_text"}
                  >
                    Level :
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={9.2} xs={9.2} md={4}>
                  <Select
                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                    sx={{
                      color: "#161616",
                      ml: 1,
                      minWidth: 140,
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(233, 233, 233, 1)",
                        border: "",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(233, 233, 233, 1)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(233, 233, 233, 1)",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#161616",
                      },
                    }}
                    displayEmpty
                    MenuProps={MenuProps}
                    className={"headerSelect select_light"}
                    value={selectedLevel}
                    onChange={(e) => handleLevels(e.target.value)}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    {levels.map((level, index) => (
                      <MenuItem key={index} value={level.layerId}>
                        {level.layerName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}</>}
      {
        isWidget && 
        <> <Grid item lg={5} md={5} xs={12} sm={12} sx={{ ml: {lg:-3,md:-3,sm:0,xs:0}, mr: 2 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon
                sx={{ color: "#8F8F91" }}
                fontSize="medium"
                className="mb"
              />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Stream Id"
              inputProps={{ "aria-label": "search" }}
              value={search}
              onChange={(e) => onChangeSearch(e.target.value)}
            />
          </Search>
        </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            sm={12}
          >
            <Grid container className="mt2">
              <Grid item sx={{ mt: 2 }}>
                <Typography
                  sx={{ textAlign: "center" }}
                  className={"headersubhead light_text"}
                >
                  Assign :
                </Typography>
              </Grid>
              <Grid item lg={4} sm={8.6} xs={8.6} md={4}>
                <Select
                  //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                  sx={{
                    color: "#161616",
                    ml: 1,
                    minWidth: 135,
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(233, 233, 233, 1)",
                      border: "",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(233, 233, 233, 1)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(233, 233, 233, 1)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#161616",
                    },
                  }}
                  displayEmpty
                  MenuProps={MenuProps}
                  className={"headerSelect select_light"}
                  value={isAssigned}
                  onChange={(e) => handleAssignAssets(e.target.value)}
                >
                  <MenuItem value={0}>Un Assigned</MenuItem>
                  <MenuItem value={1}>Assigned</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      {!isWidget && <>
        <Grid item lg={2.2} md={2.2} xs={6} sm={6}>
          <StyledCreateButton
            sx={{ paddingBottom: "6% !important", paddingTop: "6% !important",'@media (max-width: 600px)': { mt:'5px !important' } }}

            variant="contained"
            disableRipple
            startIcon={<MdOutlineAddToPhotos color="white" />}
            onClick={handleAddDevice}
          >
            Add Encoder
          </StyledCreateButton>

        </Grid>
        {!isAssigned ? (
          <Grid
            item
            lg={2.2}
            md={2.2}
            sm={6}
            xs={6}
            container
            className=" import_btn_device "
            sx={{ ml: "2%" ,'@media (max-width: 600px)': { mt:'10px !important' }}}

          >
            <div class="file-input-container">
              <label for="file-input" className=" import_btn">
                <IconButton sx={{ ml: "2px" }}>
                  <RxDownload color="#FFAC0B" />
                </IconButton>
                Import Devices
                <span style={{ marginLeft: "10px" }}></span>
              </label>
              <input
                type="file"
                id="file-input"
                accept=".xlsx, .xls"
                onChange={handleImportModal}
                onClick={(e) => {
                  e.target.value = null;
                }}
                style={{ display: "none" }}
              />
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </>}
    </Grid>
  );
};
