import React, { useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { TrackingPatrolSentry } from "./TrackingPetrolSentry";
import { TopPersonnelAlerts } from "./TopPersonnelAlerts";
import { TimeBasedReport } from "./TimeBasedReport";
import { TagBatteryAlerts } from "./TagBatteryAlerts";
import { TbFileExport } from "react-icons/tb";
import { LightBorderBlackButton } from '../../styles/ButtonStyles';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CleanerViolationAlerts } from "./CleanerViolationAlerts";
export const TrackingReport = () => {
  const trackingViolationRef = useRef();
  const personelviolationRef = useRef();
  const tagBatteryRef = useRef();
  const violationonTimeRef = useRef();
  const cleanerViolationRef = useRef();

  const trackingviolationButtonRef = useRef();
  const personelviolationButtonRef = useRef();
  const tagbatteryButtonRef = useRef();
  const statisticbuttonRef = useRef();
  const [isExporting, setIsExporting] = useState({
    surveillance: false,
    va: false,
    batteryperformance: false,
    sensor: false,
  });
  const handlePdfExport = (key) => {

    if (key == "trackingViolationReport") {
      const input = trackingViolationRef.current;
      exportFunction(input, key, trackingviolationButtonRef);
    }
    else if (key == "personelViolationReport") {
      const input = personelviolationRef.current;
      exportFunction(input, key, personelviolationButtonRef);
    }
    else if (key == "StatisticViolationReport") {
      const input = violationonTimeRef.current;
      exportFunction(input, key, statisticbuttonRef);
    }
    else if (key == "cleanerViolationAlert") {
      const input = cleanerViolationRef.current;
      exportFunction(input, key, cleanerViolationRef);
    }
    else {
      const input = tagBatteryRef.current;
      exportFunction(input, key, tagbatteryButtonRef);
    }


  };
  const exportFunction = (input, key, buttonRef) => {
    setIsExporting(true);
    //buttonRef.current.style.visibility = 'hidden';
    html2canvas(input, { scale: 2, useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgWidth = pdf.internal.pageSize.getWidth(); // Width of the PDF page
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      let heightLeft = imgHeight;
      let position = 0;

      while (heightLeft >= 0) {
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= imgHeight;
        position -= pdf.internal.pageSize.getHeight(); // Move to the next page

        if (heightLeft >= 0) {
          pdf.addPage();
        }
      }

      pdf.save(`${key}.pdf`);
      setIsExporting(false);
      //  buttonRef.current.style.visibility = 'visible';
    });
  }
  return (
    <Grid container sx={{ mt: 3 }}>
      {/* <Grid item lg={12} sm={12} xs={12} md={12} sx={{ mb: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       <LightBorderBlackButton
                    disableRipple
                    startIcon={<TbFileExport />}
                     onClick={handleExport}
                   // onClick={handleExportMenu}
                >
                    Export
                </LightBorderBlackButton>
       </Grid> */}
      <div ref={trackingViolationRef} className="trackingchart_div" >
        <Grid item lg={12} sm={12} xs={12} md={12} container>

          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Tracking Violation Alerts
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LightBorderBlackButton
              ref={trackingviolationButtonRef}
              disableRipple
              startIcon={<TbFileExport />}
              onClick={() => handleExport("trackingViolationReport")}
            // onClick={handleExportMenu}
            >
              Export
            </LightBorderBlackButton>
          </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <TrackingPatrolSentry
              handlePdfExport={handlePdfExport}
            />
          </div>
        </Grid>
      </div>
      <div ref={personelviolationRef} className="trackingchart_div" >
        <Grid item lg={12} sm={12} xs={12} md={12} container>

          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Top Personnel Violations
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LightBorderBlackButton
              ref={personelviolationButtonRef}
              disableRipple
              startIcon={<TbFileExport />}
              onClick={() => handleExport("personelViolationReport")}
            // onClick={handleExportMenu}
            >
              Export
            </LightBorderBlackButton>
          </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <TopPersonnelAlerts
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>
      <div ref={cleanerViolationRef} className="trackingchart_div" >
        <Grid item lg={12} sm={12} xs={12} md={12} container>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Cleaner Violations
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LightBorderBlackButton
              ref={statisticbuttonRef}
              disableRipple
              startIcon={<TbFileExport />}
              onClick={() => handleExport("StatisticViolationReport")}
            // onClick={handleExportMenu}
            >
              Export
            </LightBorderBlackButton>
          </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <CleanerViolationAlerts
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>
      <div ref={violationonTimeRef} className="trackingchart_div" >
        <Grid item lg={12} sm={12} xs={12} md={12} container>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Statistic of the violation based on time of the day
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LightBorderBlackButton
              ref={statisticbuttonRef}
              disableRipple
              startIcon={<TbFileExport />}
              onClick={() => handleExport("StatisticViolationReport")}
            // onClick={handleExportMenu}
            >
              Export
            </LightBorderBlackButton>
          </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <TimeBasedReport
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>
      <div ref={tagBatteryRef} className="trackingchart_div" >
        <Grid item lg={12} sm={12} xs={12} md={12} container>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Tag Battery Alert
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LightBorderBlackButton
              ref={tagbatteryButtonRef}
              disableRipple
              startIcon={<TbFileExport />}
              onClick={() => handleExport("tagBatteryReport")}
            // onClick={handleExportMenu}
            >
              Export
            </LightBorderBlackButton>
          </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <TagBatteryAlerts
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>
    </Grid>
  );
};
