import { Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSites } from "../../../../redux/actions/UserRoleActions";
import { useParams } from "react-router-dom";
import { fetchCleanerHisory } from "../../../../redux/actions/PersonnelAction";
import { MenuProps } from "../../../Styles";
import { Layout } from "../../../layout/Layout";
import { CleanerHistoryTable } from "./CleanerHistoryTable";
import { alertTimeOnly, dateOnly, dateSearchConverter, GmtToLocalClockTime } from "../../../convertors/TimeConvertor";
import dayjs from "dayjs";
import { subDays } from "date-fns";
import { Paginations } from "../../../sharable/pagination/Pagination";
export const CleanerHistoryList = () => {
    const { id } = useParams();

    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [historyList, setHistoryList] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 20));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const itemsPerPage = 20;
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const siteData = useSelector(state => state.userRole.siteData);
    const cleanerHistory = useSelector(state => state.personnel.cleanerHistory);
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");

    useEffect(() => {
        dispatch(fetchSites());

    }, []);
    // useEffect(() => {
    //     if (id !== null) {
    //         let data = {
    //             user_id: id,
    //             to_date: dateSearchConverter(selectedEndDate),
    //             from_date: dateSearchConverter(selectedStartDate),
    //         }
    //         dispatch(fetchCleanerHisory(data));
    //     }
    // }, [id])
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        if (id !== null) {

            let data = {
                to_date: dateSearchConverter(selectedEndDate),
                from_date: dateSearchConverter(selectedStartDate),
                user_id: id,
                site_id: selectedSites,
                start: start,
                limit: limit
            }
            dispatch(fetchCleanerHisory(data));
        }

    }, [id, selectedEndDate, selectedStartDate, currentPage, selectedSites])
    const dateFormat = (date) => {
        let formattedDate = dayjs(date);
        return formattedDate.format("MM/DD/YYYY")
    }
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
            const siteId = sessionStorage.getItem('user_site_id');
            if (siteId != 0) {
                setSelectedSites(siteId)
            }

        }
        if (!cleanerHistory.isFetching && cleanerHistory.payload && cleanerHistory.payload.success === true) {
            let temp = cleanerHistory.payload.data;
            const tempPagination = cleanerHistory.payload.pagination;
            setPageCount(tempPagination.total_pages)

            const templist = temp.map((item) => {
                return {
                    id: item.id,
                    datedata: dateOnly(item.date),
                    first_seen: item.startat == 0 || item.startat == 0 ? 0 : alertTimeOnly(item.startat),
                    last_seen: item.endat == 0 || item.endat == 0 ? 0 : alertTimeOnly(item.endat),
                    site: item.site_name,
                    patrol_end_time: item.routeendtime ? GmtToLocalClockTime(item.routeendtime) : "NA",
                    patrol_start_time: item.routestarttime ? GmtToLocalClockTime(item.routestarttime) : "NA",
                    routename: item.routename,

                    checkpoints: item.checkpoints,

                }
            })
            const tempData = templist.map((item) => {
                return {
                    ...item,
                    date: dateFormat(item.datedata),
                    //trackDate:dateFormat(item.trackDate)

                }
            })
            setHistoryList(tempData)
        }
    }, [siteData, cleanerHistory]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleEndDateChange = (date) => {


        setSelectedEndDate(date);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    }
    return (
        <Layout
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            handleEndDateChange={handleEndDateChange}
            handleStartDateChange={handleStartDateChange}
            activeMenuItem={"tracking"}
            activeMenuKey={"tracking"}
            isCreate={true}
            activeSubtab={"three"}
            isClenerHistory={true}
            handleActiveMenuItems={handleActiveMenuItems}

        // searchWord={searchWord}
        // onSearchWordChange={onSearchWordChange}
        >
            <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">

                <Grid container spacing={1} sx={{ pt: 5, mb: 2, ml: 2, mt: 3 }}>
                    < Grid container sx={{ mt: 2 }}>
                        <Grid item lg={4} md={4} sm={10} xs={10} className="mtdash2" >
                            <Typography className='headersubhead light_text htl'>History Of Cleaner</Typography>
                        </Grid>
                        <Grid item lg={4} sm={12} xs={12} md={4} sx={{ ml: 2 }}>
                            <Grid container className="mt2">
                                <Grid item sx={{ mt: 2 }}>
                                    <Typography sx={{ textAlign: 'center' }}
                                        className={"headersubhead light_text"}>
                                        Sites :
                                    </Typography>

                                </Grid>
                                <Grid item lg={7} sm={8} xs={8} md={4}>
                                    <Select
                                        //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                        sx={{
                                            color: "#161616", ml: 1, minWidth: 100,
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                                border: '',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "#161616",
                                            }
                                        }}

                                        displayEmpty
                                        MenuProps={MenuProps}
                                        className={"headerSelect select_light"}
                                        value={selectedSites}
                                        onChange={(e) => setSelectedSites(e.target.value)}
                                    >
                                        <MenuItem value={''}>All</MenuItem>
                                        {sites.map((site, index) => (
                                            <MenuItem
                                                key={index}
                                                value={site.organizationId}>
                                                {site.organizationName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ pt: 2, }} >
                        <Grid item lg={11.8} sm={11.8} xs={11.8}
                            className={"tableheight chartbg_light "}>
                            {historyList.length && historyList !== undefined ?
                                <CleanerHistoryTable
                                    // isDarkMode={isDarkMode}
                                    row={historyList}
                                // handleEdit={handleEdit}
                                />
                                : <>No History found</>
                            }
                        </Grid>
                    </Grid>
                    <Grid item lg={12} sm={12} xs={12} sx={{ marginTop: 2 }}>

                        {
                            historyList.length && historyList !== undefined ?

                                <Paginations
                                    pageCount={pageCount}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                />
                                : <></>}
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}