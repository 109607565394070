import { Grid, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { MenuProps, Search, SearchIconWrapper, StyledInputBase } from '../../Styles';
import SearchIcon from '@mui/icons-material/Search';

export const DeviceFilter = ({ isDarkMode, handleTabListValues, deviceTabListValue, sites, selectedSites,
    handleSites, search, handleSearchWord, tagStatus, handleAssetStuatus, isAssigned, handleAssignAssets }) => {
    return (
        <Grid container sx={{ mt: 4 }} columnSpacing={2}>
            {deviceTabListValue === 'listener' || deviceTabListValue === 'bridge' ?
                <Grid item lg={4} md={4} xs={12} sm={12} sx={{ ml:{lg: -3,md:-3,sm:0,xs:0 },mt:{lg:0,md:0,sm:2,xs:2}}}>
                    <Search isDarkMode={isDarkMode}>
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                        </SearchIconWrapper>
                        <StyledInputBase
                            isDarkMode={isDarkMode}
                            placeholder={deviceTabListValue === 'listener' ? "Search listener name" : "Search bridge name"}
                            inputProps={{ 'aria-label': 'search' }}
                            value={search}
                            onChange={(e) => handleSearchWord(e.target.value)}
                        />
                    </Search>
                </Grid>
                : null
            }
            <Grid item lg={2.3} md={2.3} xs={11} sm={11} sx={{ml:3, '@media (max-width: 600px)': { ml: '0% !important' }}}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                            Device :
                        </Typography>

                    </Grid>
                    <Grid item lg={6} sm={9} xs={9} md={6}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 110,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: isDarkMode ? "white !important" : "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                            value={deviceTabListValue}
                            onChange={(e) => handleTabListValues(e.target.value)}
                        >

                            <MenuItem value="tag">Tag</MenuItem>
                            <MenuItem value="listener">Listener</MenuItem>
                            <MenuItem value="bridge">Bridge</MenuItem>


                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item lg={2.9} md={2.9} xs={12} sm={12}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                            Sites :
                        </Typography>

                    </Grid>
                    <Grid item lg={4} sm={8.5} xs={8.5} md={4}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 180,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: isDarkMode ? "white !important" : "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                            value={selectedSites}
                            onChange={(e) => handleSites(e.target.value)}
                        >   <MenuItem value={''}>All</MenuItem>
                            {sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))}


                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            {deviceTabListValue === 'tag' ?
                <>
                    <Grid item lg={3.2} md={3.2} xs={12} sm={12}>
                        <Grid container className="mt2">
                            <Grid item sx={{ mt: 2 }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                    Assets :
                                </Typography>

                            </Grid>
                            <Grid item lg={4} sm={8} xs={8} md={4}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 195,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={tagStatus}
                                    onChange={(e) => handleAssetStuatus(e.target.value)}
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Checked Out</MenuItem>
                                    <MenuItem value={2}>Transfer Requested</MenuItem>



                                </Select>
                            </Grid>

                        </Grid>{/*  */}
                    </Grid>
                    <Grid item lg={3} md={3} xs={12} sm={12}>
                        <Grid container className="mt2">
                            <Grid item sx={{ mt: 2 }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                    Assign :
                                </Typography>

                            </Grid>
                            <Grid item lg={4} sm={8} xs={8} md={4}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 160,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={isAssigned}
                                    onChange={(e) => handleAssignAssets(e.target.value)}
                                >

                                    <MenuItem value={0}>Un Assigned</MenuItem>
                                    <MenuItem value={1}>Assigned</MenuItem>

                                </Select>
                            </Grid>

                        </Grid>
                    </Grid>
                </> : null
            }
        </Grid>
    )
}