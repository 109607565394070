import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory, useNavigate } from 'react-router-dom';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import { Search, SearchIconWrapper, StyledInputBase } from "../../Styles";
import { DatePick } from "../datePicker/DatePicker";
export const MainHeader = ({ isDarkMode, handleEndDateChange, handleStartDateChange, selectedStartDate,
    selectedEndDate, activeMenuItem, isCreate,navigation,placeHolder,searchWord ,onSearchWordChange }) => {
        const navigate = useNavigate();
        const handleBack = () => {
            sessionStorage.setItem("edit_telegram_id",null);

        navigate(-1);
       // window.location.href = `/${navigation}`;

    }
   
    return (
        <>
            {isCreate === true ? <>
                <Grid item lg={8.5} sm={12} xs={12} md={8.5} className="" sx={{ textAlign: 'center' }}>
                <Grid container>
                    <Grid item>
                        <IconButton sx={{ mt: 0, }}
                            onClick={handleBack}
                        >
                            <ArrowBackIosNewOutlinedIcon 
                            sx={{ color: isDarkMode ? "white" : "#000000" }} 
                            fontSize="medium" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography 
                        className={isDarkMode ?  "headerhead headerhead_dark" : "headerhead headerhead_light"}>  
                        {activeMenuItem}
                        </Typography>
                    </Grid>
                </Grid>
                </Grid>
            </>
                : <>
                    <Grid item lg={1.5} sm={12} xs={12} md={1.5}
                            className={{lg:"ml",md:"ml",xs:"",sm:""}} sx={{ textAlign: 'center' ,ml:{lg:"8px !important",md:"8px !important",xs:"",sm:""}}}>

                        <Typography 
                        sx={{ textAlign: 'center', mt: 1 }} 
                        className={isDarkMode ? "headerhead headerhead_dark" : "headerhead headerhead_light"}>
                            {activeMenuItem}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} sm={12} xs={12} md={3} sx={{mr:4,mb:{lg:0,md:0,sm:1,xs:1}}}>
                        <Search isDarkMode={isDarkMode}>
                            <SearchIconWrapper>
                                <SearchIcon 
                                sx={{ color: isDarkMode ? "white" : "#8F8F91" }} 
                                fontSize="medium" className="mb" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                isDarkMode={isDarkMode}
                                placeholder={placeHolder}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchWord}
                                onChange={(e)=>onSearchWordChange(e.target.value)}
                            />
                        </Search>
                    </Grid>
                    <Grid item lg={1.5} sm={4} xs={4} md={1.5} className={{lg:"mt2",md:"mt2",sm:"",xs:''}} >
                        <DatePick
                            handleDateChange={handleStartDateChange}
                            isDarkMode={isDarkMode}
                            selectedDate={selectedStartDate} />
                    </Grid>
                    <Grid item lg={.5} md={.5} sm={.5} xs={.5} className="to_arrow"
                        sx={{mr:{lg:0,md:0,sm:1,xs:2},ml:{lg:0,md:0,sm:'20px !important',xs:'20px !important'}}}>
                        <IconButton>
                            <SyncAltRoundedIcon sx={{ color: isDarkMode ? "white" : 'black' }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={1.5} sm={5} xs={5} md={1.5}
                     className={{lg:"mlback",md:"mlback",sm:"",xs:''}} 
                     sx={{ mr:{lg:3,md:3,sm:1,xs:1} }}>
                        <DatePick
                            handleDateChange={handleEndDateChange}
                            isDarkMode={isDarkMode}
                            selectedDate={selectedEndDate} />
                    </Grid>
                </>
            }
        </>
    )
}