import { Alert, Box, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { MessagingModalstyle, } from "../styles/ModalStyle";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BorderButton, StyledCreateSmallButton } from "../styles/ButtonStyles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { IoIosCloseCircleOutline } from "react-icons/io";

export const TelegramMessagingModal = ({ openMessagingModal, handleMessagingModal, name,handleSendMsg
    ,handleTelegramTextChange,msgForCleaner
 }) => {
    const [showAlert, setShowAlert] = useState(false);


    return (
        <>
            <Modal
                open={openMessagingModal}
                onClose={handleMessagingModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={MessagingModalstyle}>

                    {showAlert ?
                        <Alert variant="outlined" severity="error">Camera status Updation Failed </Alert> : <></>
                    }
                    {/* <IconButton><GoTag color="#FFAC0B" /></IconButton> */}
                    <Grid container>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            style={{ display: "flex", justifyContent: "flex-start" }}
                        >
                            <Typography className="cleaner-modalhead" >
                                Cleaner Name: {name}

                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <IconButton onClick={handleMessagingModal}>
                                <IoIosCloseCircleOutline
                                    sx={{ fontSize: "20px", mr: "5px", color: "#161616" }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className="card_divider" ></div>
                        </Grid>
                        <Grid item xs={11} sm={11} lg={11} md={11} sx={{ mt: 3 }}>
                            <InputLabel
                                className={"form_text_light"}
                                sx={{ color: "black", ml: 0, mb: 2 }}
                            >
                                Message{" "}
                            </InputLabel>

                            <TextField
                                multiline
                                rows={4}
                                className={
                                    "fullwidth form_field_light"
                                }
                                placeholder="send alert message .."
                                 onChange={(e) => handleTelegramTextChange(e.target.value)}
                                 value={msgForCleaner}
                                // // error={!!validationErrors.org_name}
                                // helperText={validationErrors.org_name}
                                type="text"
                            />


                        </Grid>
                        <Grid
                            item
                            lg={11}
                            md={11}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                           
                            <BorderButton
                                sx={{ mr: 2,mt: 3, }} variant="outlined" color="error"
                                onClick={handleMessagingModal}
                            >
                                <HighlightOffRoundedIcon
                                    sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
                                Cancel
                            </BorderButton>
                            <StyledCreateSmallButton
                                sx={{  mt: 3 }} variant="outlined"
                              onClick={handleSendMsg}
                            >

                                
                                Send
                            </StyledCreateSmallButton>
                        </Grid>
                    </Grid>
                </Box>

            </Modal>
        </>
    );
};
