import React, { useState, useEffect } from 'react';
import { Layout } from '../../layout/Layout';
import { Alert, CircularProgress, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { StyledCreateSmallButton } from '../../styles/ButtonStyles';
import { fetchSites } from '../../../redux/actions/UserRoleActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addPersonnel, clearAddPersonnelStatus } from '../../../redux/actions/PersonnelAction';
import { validateEmail, validatePhoneNumber } from '../../helpers/ValidationHelper';
import { DatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
export const PersonnelCreate = ({ }) => {
    const dispatch = useDispatch();
    const siteData = useSelector(state => state.userRole.siteData);
    const newPersonStatus = useSelector(state => state.personnel.addPerson);
    const [activeMenuItem, setActiveMenuItem] = useState("tracking");
    const [isDarkMode, setDarkMode] = useState(false);
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [permitIssuanceTime, setPermitIssuanceTime] = useState(null);
    const [endDateLimit, setEndDateLimit] = useState(null);
    const [alertMessage, setAlertMessage] = useState('')
    const [personnelDetails, setPersonnelDetails] = useState({
        personnelName: '',
        emp_id: '',
        sites: '',
        phone: '',
        mail_id: '',
        organisation_name: '',
        serial_no: "",
        insurance_date: null,
        domain: "",
        project: "",
        project_startdate: null,
        project_enddate: null,
        project_maneger: "",
        supervisor_phone: '',
        supervisor_name: '',
        user_type :0

    });
    const [validationErrors, setValidationErrors] = useState({
        personnelName: '',
        emp_id: '',
        sites: '',
        phone: '',
        mail_id: '',
        organisation_name: '',
        serial_no: "",
        insurance_date: '',
        domain: "",
        project: "",
        project_startdate: '',
        project_enddate: '',
        project_maneger: "",
        supervisor_phone: '',
        supervisor_name: '',
        user_type :''

    });
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        dispatch(fetchSites())
    }, []);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp);
            const siteId = sessionStorage.getItem('user_site_id');
            if (siteId != 0) {
                setSelectedSites(parseInt(siteId))
            }
        }
        // if (!tagListData.isFetching && tagListData.payload && tagListData.payload.success === true) {
        //     const tempData = tagListData.payload.data;
        //     setTagList(tempData)
        // }
    }, [siteData]);
    useEffect(() => {
        if (!newPersonStatus.isFetching && newPersonStatus.payload && newPersonStatus.payload.success === true) {
            sessionStorage.setItem('trackingTab', 'three')
            window.location.href = '/tracking';
        }
        if (!newPersonStatus.isFetching && newPersonStatus.error) {
            setShowAlert(true);
            const error = newPersonStatus.errorMessage.message;
            setAlertMessage(error);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            dispatch(clearAddPersonnelStatus());
            return () => clearTimeout(timeoutId);
        }
    }, [newPersonStatus])
    useEffect(() => {
        if (selectedSites != '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sites: '',
            }));
        }

    }, [selectedSites])
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const onChange = (value, key) => {
        let temp = { ...personnelDetails };
        temp[key] = value;
        setPersonnelDetails(temp);
        if(key != 'user_type')
        {
        const valueLength = value.trim().length;
        let error = '';
        switch (key) {
            case 'personnelName':
            case 'organisation_name':
            case 'serial_no':
            case 'domain':
            case 'project':
            case 'project_maneger':
            case 'supervisor_name':
                error =
                    valueLength < 2 || valueLength >= 30
                        ? 'Field must be between 2 and 30 characters long'
                        : '';
                break;
            case 'emp_id':
                error =
                    valueLength < 2 || valueLength >= 30
                        ? 'Employee Id must be between 2 and 30 characters long'
                        : '';
                break;
            case 'mail_id':
                const validEmail = validateEmail(value);
                error = validEmail ? '' : 'Invalid Email';
                break;
            case 'phone':
            case 'supervisor_phone':
                const validPhone = validatePhoneNumber(value);
                error = validPhone ? '' : 'Invalid phone';
                break;
            default:
                break;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));
    }
    }
    const handlePermitIssuanceTime = (newTime) => {

        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        setPermitIssuanceTime(formattedTime);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                insurance_date: '',
            }));
        }
    }
    const handleEndTimeChange = (newTime) => {
        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const tempDate = newTime.$d.toISOString();
        setSelectedEndTime(tempDate);
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                project_enddate: '',
            }));
        }
        if (newTime < endDateLimit) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                project_enddate: 'Invalid End Date',
            }));
        }
    };
    const handleStartTimeChange = (newTime) => {
        const formattedTime = dayjs(newTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const tempDate = newTime.$d.toISOString();
        setSelectedStartTime(tempDate);
        setEndDateLimit(newTime)
        if (newTime !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                project_startdate: '',
            }));
        }

    }
    const handleCrete = (e) => {
        let data = {
            site_id: selectedSites,
            contractor_name: personnelDetails.personnelName,
            emp_id: personnelDetails.emp_id,
            phone: personnelDetails.phone,
            email: personnelDetails.mail_id,
            organization_id: sessionStorage.getItem('org_Id'),
            organisation_name: personnelDetails.organisation_name,
            serial_no: personnelDetails.serial_no,
            insurace_date: permitIssuanceTime,
            domain: personnelDetails.domain,
            project: personnelDetails.project,
            project_startdate: selectedStartTime,
            project_enddate: selectedEndTime,
            project_maneger: personnelDetails.project_maneger,
            supervisor: personnelDetails.supervisor_name,
            supervisor_phone: personnelDetails.supervisor_phone,
            created_user: parseInt(sessionStorage.getItem('id')),
            organization_id: parseInt(sessionStorage.getItem('org_Id')),
            user_type :parseInt(personnelDetails.user_type),

        }
        e.preventDefault();
        const isEmptyField = Object.values(data).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                personnelName: personnelDetails.personnelName.trim() === '' ? 'This field is mandatory' : prevErrors.personnelName,
                emp_id: personnelDetails.emp_id.trim() === '' ? 'This field is mandatory' : prevErrors.emp_id,
                phone: personnelDetails.phone ? !validatePhoneNumber(personnelDetails.phone) ? prevErrors.phone : '' : 'This field is mandatory',
                mail_id: personnelDetails.mail_id ? !validateEmail(personnelDetails.mail_id) ? prevErrors.mail_id : '' : 'This field is mandatory',
                organisation_name: personnelDetails.organisation_name.trim() === '' ? 'This field is mandatory' : prevErrors.organisation_name,
                sites: selectedSites ? '' : 'This field is mandatory',
                serial_no: personnelDetails.serial_no.trim() === '' ? 'This field is mandatory' : prevErrors.serial_no,
                domain: personnelDetails.domain.trim() === '' ? 'This field is mandatory' : prevErrors.domain,
                project: personnelDetails.project.trim() === '' ? 'This field is mandatory' : prevErrors.project,
                project_startdate: selectedStartTime ? '' : 'This field is mandatory',
                insurance_date: permitIssuanceTime ? '' : 'This field is mandatory',
                project_enddate: selectedEndTime ? '' : 'This field is mandatory',
                user_type: personnelDetails.user_type === 0 ? 'This field is mandatory' : prevErrors.user_type,

                project_maneger: personnelDetails.project_maneger.trim() === '' ? 'This field is mandatory' : prevErrors.project_maneger,
                supervisor_name: personnelDetails.supervisor_name.trim() === '' ? 'This field is mandatory' : prevErrors.supervisor_name,

                supervisor_phone: personnelDetails.supervisor_phone ? !validatePhoneNumber(personnelDetails.supervisor_phone) ? prevErrors.supervisor_phone : '' : 'This field is mandatory',

            }));

        }
        if (!isEmptyField && allClear) {

            setValidationErrors({
                personnelName: '',
                sites: '',
                emp_id: '',
                phone: '',
                mail_id: '',
                organisation_name: '',
                serial_no: '',
                insurance_date: '',
                domain: '',
                project: '',
                project_startdate: '',
                project_enddate: '',
                user_type:'',
                supervisor_phone: '',
                supervisor_name: '',
                project_maneger: '',
            });
            dispatch(addPersonnel(data));
        }
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                activeSubtab={"three"}
                isCreate={true}
            >
                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handlealertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handlealertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {newPersonStatus.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                    <Grid item lg={12} >
                        <Typography
                            className='device_view_head'>Personnel Details</Typography>

                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Personnel Name</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Personnel name"
                            value={personnelDetails.personnelName}
                            onChange={(e) => onChange(e.target.value, 'personnelName')}
                            error={!!validationErrors.personnelName}
                            helperText={validationErrors.personnelName}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Employee Id</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Employee Id'
                            value={personnelDetails.emp_id}
                            onChange={(e) => onChange(e.target.value, 'emp_id')}
                            error={!!validationErrors.emp_id}
                            helperText={validationErrors.emp_id}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                    <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                           Type</InputLabel>
                    <Select
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                            value={personnelDetails.user_type}
                            onChange={(e) => onChange(e.target.value,'user_type')}
                            displayEmpty
                            error={!!validationErrors.user_type}
                        >
                                <MenuItem value={2}>
                                    {"Cleaner"}
                                </MenuItem>
                                <MenuItem value={1}>
                                    {"Personnel"}
                                </MenuItem>
                        </Select>
                        {validationErrors.user_type && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.user_type}
                            </Typography>
                        )}
                        </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Site</InputLabel>
                        <Select
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                            value={selectedSites}
                            onChange={(e) => setSelectedSites(e.target.value)}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected == '') {
                                    return <span style={{ color: '#a2a2a2' }}>Site</span>;
                                }

                                const selectedSite = sites.find(site => site.organizationId === selected);

                                // Return the organizationName of the selected site
                                return selectedSite ? selectedSite.organizationName : '';
                            }}
                            error={!!validationErrors.sites}
                        >
                            {sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))}
                        </Select>
                        {validationErrors.sites && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={
                                    isDarkMode
                                        ? 'errormargin  errorStyle '
                                        : ' errormargin errorStyle'
                                }
                            >
                                {validationErrors.sites}
                            </Typography>
                        )}
                    </Grid>
                   
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Phone No</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div " : "form_field_right form_field_light"}
                            placeholder='Phone Number'
                            type='phone'
                            value={personnelDetails.phone}
                            onChange={(e) => onChange(e.target.value, 'phone')}
                            error={!!validationErrors.phone}
                            helperText={validationErrors.phone}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Mail Id</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="example@gmail.com"
                            type='email'
                            value={personnelDetails.mail_id}
                            onChange={(e) => onChange(e.target.value, 'mail_id')}
                            error={!!validationErrors.mail_id}
                            helperText={validationErrors.mail_id}
                        />
                    </Grid>
                    {/* <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Company Name
                        </InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Company Name"
                            type='text'
                            value={personnelDetails.organisation_name}
                            onChange={(e) => onChange(e.target.value, 'organisation_name')}
                            error={!!validationErrors.organisation_name}
                            helperText={validationErrors.organisation_name}
                        />
                    </Grid> */}
                    <Grid item lg={12} sx={{ mt: 3, mb: 3 }}>
                        <Typography
                            className='device_view_head'>Project Details</Typography>
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Permit Serial Number</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Permit Serial Number"
                            value={personnelDetails.serial_no}
                            onChange={(e) => onChange(e.target.value, 'serial_no')}
                            error={!!validationErrors.serial_no}
                            helperText={validationErrors.serial_no}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Permit Issuance Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker ']}>
                                <DatePicker
                                    sx={{ pt: 0, width: "85.4% !important" }}
                                    // minDateTime={dayjs()}
                                    slotProps={{
                                        textField: {
                                            helperText: validationErrors.insurance_date,
                                            error: validationErrors.insurance_date != '' ? true : false
                                        },

                                    }}
                                    // value={clockEnd}
                                    onChange={handlePermitIssuanceTime}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Domain / Unit</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Domain / Unit"
                            value={personnelDetails.domain}
                            onChange={(e) => onChange(e.target.value, 'domain')}
                            error={!!validationErrors.domain}
                            helperText={validationErrors.domain}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Project Title</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Project Title'
                            value={personnelDetails.project}
                            onChange={(e) => onChange(e.target.value, 'project')}
                            error={!!validationErrors.project}
                            helperText={validationErrors.project}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <Grid container>
                            <Grid item xs={10} sm={10} lg={5.4} md={5.4}>
                                <InputLabel
                                    className={isDarkMode ? "form_text " : "form_text_light "}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    Start Date & Time </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} className="datetime-container" >
                                    <DemoContainer
                                        className="datetime-container"
                                        sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                        <DesktopDateTimePicker
                                            // className="timepicker"

                                            // minDateTime={dayjs()}
                                            slotProps={{
                                                textField: {
                                                    helperText: validationErrors.project_startdate,
                                                    error: validationErrors.project_startdate != "" ? true : false
                                                },

                                            }}

                                            //  value={clockStart}
                                            onChange={handleStartTimeChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={10} sm={10} lg={5.5} md={5.5}>
                                <InputLabel
                                    className={isDarkMode ? "form_text " : "form_text_light "}
                                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                    End Date & Time </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer sx={{ pt: 0, width: "215px !important" }} components={['DesktopDateTimePicker']}>
                                        <DesktopDateTimePicker
                                            minDateTime={endDateLimit}

                                            // minDateTime={dayjs()}
                                            slotProps={{
                                                textField: {
                                                    helperText: validationErrors.project_enddate,
                                                    error: validationErrors.project_enddate != "" ? true : false
                                                },

                                            }}
                                            // value={clockEnd}
                                            onChange={handleEndTimeChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Project Manager</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Project Manager'
                            value={personnelDetails.project_maneger}
                            onChange={(e) => onChange(e.target.value, 'project_maneger')}
                            error={!!validationErrors.project_maneger}
                            helperText={validationErrors.project_maneger}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Name of Awarded Contractor</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Name of Awarded Contractor"
                            value={personnelDetails.organisation_name}
                            onChange={(e) => onChange(e.target.value, 'organisation_name')}
                            error={!!validationErrors.organisation_name}
                            helperText={validationErrors.organisation_name}
                        />

                    </Grid>
                    <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Supervisor Name</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Supervisor Name'
                            value={personnelDetails.supervisor_name}
                            onChange={(e) => onChange(e.target.value, 'supervisor_name')}
                            error={!!validationErrors.supervisor_name}
                            helperText={validationErrors.supervisor_name}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Supervisor Phone</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                            placeholder="Supervisor Phone"
                            value={personnelDetails.supervisor_phone}
                            onChange={(e) => onChange(e.target.value, 'supervisor_phone')}
                            error={!!validationErrors.supervisor_phone}
                            helperText={validationErrors.supervisor_phone}
                        />

                    </Grid>
                    <Grid item tem xs={10} sm={10} lg={10} md={10} >
                        <StyledCreateSmallButton
                            onClick={handleCrete}
                            sx={{ mt: 5, width: 150 }} variant="contained"
                            disableRipple>
                            Create
                        </StyledCreateSmallButton>
                    </Grid>
                </Grid>
}
            </Layout>
        </>
    )
}