export const surveillanceActionType = {
    FETCH_SURVEILLANCE_MAP: "FETCH_SURVEILLANCE_MAP",
    FETCH_SURVEILLANCE_MAP_SUCCESS: "FETCH_SURVEILLANCE_MAP_SUCCESS",
    FETCH_SURVEILLANCE_MAP_ERROR: "FETCH_SURVEILLANCE_MAP_ERROR",

    FETCH_VMS_DEVICE_LIST: "FETCH_VMS_DEVICE_LIST",
    FETCH_VMS_DEVICE_LIST_SUCCESS: "FETCH_VMS_DEVICE_LIST_SUCCESS",
    FETCH_VMS_DEVICE_LIST_ERROR: "FETCH_VMS_DEVICE_LIST_ERROR",

    FETCH_SENSOR_LIST: "FETCH_SENSOR_LIST",
    FETCH_SENSOR_LIST_SUCCESS: "FETCH_SENSOR_LIST_SUCCESS",
    FETCH_SENSOR_LIST_ERROR: "FETCH_SENSOR_LIST_ERROR",

    ADD_SENSOR: "ADD_SENSOR",
    ADD_SENSOR_SUCCESS: "ADD_SENSOR_SUCCESS",
    ADD_SENSOR_ERROR: "ADD_SENSOR_ERROR",
    CLEAR_ADD_SENSOR_STATUS: "CLEAR_ADD_SENSOR_STATUS",

    FETCH_SINGLE_SENSOR: "FETCH_SINGLE_SENSOR",
    FETCH_SINGLE_SENSOR_SUCCESS: "FETCH_SINGLE_SENSOR_SUCCESS",
    FETCH_SINGLE_SENSOR_ERROR: "FETCH_SINGLE_SENSOR_ERROR",

    UPDATE_SENSOR: "UPDATE_SENSOR",
    UPDATE_SENSOR_SUCCESS: "UPDATE_SENSOR_SUCCESS",
    UPDATE_SENSOR_ERROR: "UPDATE_SENSOR_ERROR",
    CLEAR_UPDATE_SENSOR_STATUS: "CLEAR_UPDATE_SENSOR_STATUS",

    DELETE_SENSOR: "DELETE_SENSOR",
    DELETE_SENSOR_SUCCESS: "DELETE_SENSOR_SUCCESS",
    DELETE_SENSOR_ERROR: "DELETE_SENSOR_ERROR",
    CLEAR_DELETE_SENSOR_STATUS: "CLEAR_DELETE_SENSOR_STATUS",

    FETCH_CAMERA_DROPDOWN: "FETCH_CAMERA_DROPDOWN",
    FETCH_CAMERA_DROPDOWN_SUCCESS: "FETCH_CAMERA_DROPDOWN_SUCCESS",
    FETCH_CAMERA_DROPDOWN_ERROR: "FETCH_CAMERA_DROPDOWN_ERROR",

    FETCH_SENSOR_DROPDOWN: "FETCH_SENSOR_DROPDOWN",
    FETCH_SENSOR_DROPDOWN_SUCCESS: "FETCH_SENSOR_DROPDOWN_SUCCESS",
    FETCH_SENSOR_DROPDOWN_ERROR: "FETCH_SENSOR_DROPDOWN_ERROR",

    FETCH_BATTERY_DROPDOWN: "FETCH_BATTERY_DROPDOWN",
    FETCH_BATTERY_DROPDOWN_SUCCESS: "FETCH_BATTERY_DROPDOWN_SUCCESS",
    FETCH_BATTERY_DROPDOWN_ERROR: "FETCH_BATTERY_DROPDOWN_ERROR",

    ADD_ENCODER: "ADD_ENCODER",
    ADD_ENCODER_SUCCESS: "ADD_ENCODER_SUCCESS",
    ADD_ENCODER_ERROR: "ADD_ENCODER_ERROR",
    CLEAR_ADD_ENCODER_STATUS: "CLEAR_ADD_ENCODER_STATUS",

    FETCH_SINGLE_ENCODER: "FETCH_SINGLE_ENCODER",
    FETCH_SINGLE_ENCODER_SUCCESS: "FETCH_SINGLE_ENCODER_SUCCESS",
    FETCH_SINGLE_ENCODER_ERROR: "FETCH_SINGLE_ENCODER_ERROR",

    DELETE_ENCODER: "DELETE_ENCODER",
    DELETE_ENCODER_SUCCESS: "DELETE_ENCODER_SUCCESS",
    DELETE_ENCODER_ERROR: "DELETE_ENCODER_ERROR",
    CLEAR_DELETE_ENCODER_STATUS: "CLEAR_DELETE_ENCODER_STATUS",
    
    EDIT_ENCODER: "EDIT_ENCODER",
    EDIT_ENCODER_SUCCESS: "EDIT_ENCODER_SUCCESS",
    EDIT_ENCODER_ERROR: "EDIT_ENCODER_ERROR",
    CLEAR_EDIT_ENCODER_STATUS: "CLEAR_EDIT_ENCODER_STATUS",

    SET_SURVEILLANCE_MAP: "SET_SURVEILLANCE_MAP",
    SET_SURVEILLANCE_MAP_MARKERS: "SET_SURVEILLANCE_MAP_MARKERS",

    ADD_POWER: "ADD_POWER",
    ADD_POWER_SUCCESS: "ADD_POWER_SUCCESS",
    ADD_POWER_ERROR: "ADD_POWER_ERROR",
    CLEAR_ADD_POWER_STATUS: "CLEAR_ADD_POWER_STATUS",

    FETCH_POWERLIST : "FETCH_POWERLIST",
    FETCH_POWERLIST_SUCCESS : "FETCH_POWERLIST_SUCCESS",
    FETCH_POWERLIST_ERROR : "FETCH_POWERLIST_ERROR",

    DELETE_POWER: "DELETE_POWER",
    DELETE_POWER_SUCCESS: "DELETE_POWER_SUCCESS",
    DELETE_POWER_ERROR: "DELETE_POWER_ERROR",
    CLEAR_DELETE_POWER_STATUS: "CLEAR_DELETE_POWER_STATUS",

    EDIT_POWER: "EDIT_POWER",
    EDIT_POWER_SUCCESS: "EDIT_POWER_SUCCESS",
    EDIT_POWER_ERROR: "EDIT_POWER_ERROR",
    CLEAR_EDIT_POWER_STATUS: "CLEAR_EDIT_POWER_STATUS",

    FETCH_SINGLE_POWER : "FETCH_SINGLE_POWER",
    FETCH_SINGLE_POWER_SUCCESS : "FETCH_SINGLE_POWER_SUCCESS",
    FETCH_SINGLE_POWER_ERROR : "FETCH_SINGLE_POWER_ERROR",

    UPDATE_CAMERA_STATUS : "UPDATE_CAMERA_STATUS",
    UPDATE_CAMERA_STATUS_SUCCESS : "UPDATE_CAMERA_STATUS_SUCCESS",
    UPDATE_CAMERA_STATUS_ERROR : "UPDATE_CAMERA_STATUS_ERROR",
    CLEAR_UPDATE_CAMERA_STATUS : "CLEAR_UPDATE_CAMERA_STATUS",

    FETCH_CAMERA_HISTORY : "FETCH_CAMERA_HISTORY",
    FETCH_CAMERA_HISTORY_SUCCESS : "FETCH_CAMERA_HISTORY_SUCCESS",
    FETCH_CAMERA_HISTORY_ERROR : "FETCH_CAMERA_HISTORY_ERROR",

    DEVICE_ONBOARD : "DEVICE_ONBOARD",
    DEVICE_ONBOARD_SUCCESS :"DEVICE_ONBOARD_SUCCESS",
    DEVICE_ONBOARD_ERROR : "DEVICE_ONBOARD_ERROR",
    CLEAR_DEVICE_ONBOARD_STATUS : "CLEAR_DEVICE_ONBOARD_STATUS",

    FETCH_PREV_HISTORY : "FETCH_PREV_HISTORY",
    FETCH_PREV_HISTORY_SUCCESS : "FETCH_PREV_HISTORY_SUCCESS",
    FETCH_PREV_HISTORY_ERROR : "FETCH_PREV_HISTORY_ERROR",

    
    FETCH_IOT_LIST : "FETCH_IOT_LIST",
    FETCH_IOT_LIST_SUCCESS : "FETCH_IOT_LIST_SUCCESS",
    FETCH_IOT_LIST_ERROR : "FETCH_IOT_LIST_ERROR",
}
//fetch map 

export const getMap = (data) => {

    return {
        type: surveillanceActionType.FETCH_SURVEILLANCE_MAP,
        data: data,

    }
}
export const getMapSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_SURVEILLANCE_MAP_SUCCESS,
        payload: data
    }
}
export const getMapError = (error) => {
    return {
        type: surveillanceActionType.FETCH_SURVEILLANCE_MAP_ERROR,
        error
    }
}
//fetch surveillance list

export const fetchSurveillanceDeviceList = (data) => {

    return {
        type: surveillanceActionType.FETCH_VMS_DEVICE_LIST,
        data: data,

    }
}
export const fetchSurveillanceDeviceListSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_VMS_DEVICE_LIST_SUCCESS,
        payload: data
    }
}
export const fetchSurveillanceDeviceListError = (error) => {
    return {
        type: surveillanceActionType.FETCH_VMS_DEVICE_LIST_ERROR,
        error
    }
}

//fetch sensor list

export const fetchSensorList = (data) => {

    return {
        type: surveillanceActionType.FETCH_SENSOR_LIST,
        data: data,

    }
}
export const fetchSensorListSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_SENSOR_LIST_SUCCESS,
        payload: data
    }
}
export const fetchSensorListError = (error) => {
    return {
        type: surveillanceActionType.FETCH_SENSOR_LIST_ERROR,
        error
    }
}
//add sensor 

export const addSensor = (data) => {
    return {
        type: surveillanceActionType.ADD_SENSOR,
        data: data,

    }
}
export const addSensorSuccess = (data) => {

    return {
        type: surveillanceActionType.ADD_SENSOR_SUCCESS,
        payload: data
    }
}
export const addSensorError = (error) => {
    return {
        type: surveillanceActionType.ADD_SENSOR_ERROR,
        error
    }
}
export const clearAddSensorStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_ADD_SENSOR_STATUS,

    }
}

//get single sensor 

export const getSingleSensor = (id) => {
    return {
        type: surveillanceActionType.FETCH_SINGLE_SENSOR,
        id: id,

    }
}
export const getSingleSensorSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_SINGLE_SENSOR_SUCCESS,
        payload: data
    }
}
export const getSingleSensorError = (error) => {
    return {
        type: surveillanceActionType.FETCH_SINGLE_SENSOR_ERROR,
        error
    }
}

//update sensor 

export const updateSensor = (id, data) => {
    return {
        type: surveillanceActionType.UPDATE_SENSOR,
        id: id,
        data: data,

    }
}
export const updateSensorSuccess = (data) => {

    return {
        type: surveillanceActionType.UPDATE_SENSOR_SUCCESS,
        payload: data
    }
}
export const updateSensorError = (error) => {
    return {
        type: surveillanceActionType.UPDATE_SENSOR_ERROR,
        error
    }
}
export const clearupdateSensorStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_UPDATE_SENSOR_STATUS,

    }
}

//delete sensor 

export const deleteSensor = (data) => {
    return {
        type: surveillanceActionType.DELETE_SENSOR,

        data: data,

    }
}
export const deleteSensorSuccess = (data) => {

    return {
        type: surveillanceActionType.DELETE_SENSOR_SUCCESS,
        payload: data
    }
}
export const deleteSensorError = (error) => {
    return {
        type: surveillanceActionType.DELETE_SENSOR_ERROR,
        error
    }
}
export const cleardeleteSensorStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_DELETE_SENSOR_STATUS,

    }
}
//fetch camera dropdown

export const fetchCameraDropdown = (data) => {

    return {
        type: surveillanceActionType.FETCH_CAMERA_DROPDOWN,
        data: data,

    }
}
export const fetchCameraDropdownSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_CAMERA_DROPDOWN_SUCCESS,
        payload: data
    }
}
export const fetchCameraDropdownError = (error) => {
    return {
        type: surveillanceActionType.FETCH_CAMERA_DROPDOWN_ERROR,
        error
    }
}
//fetch sensor dropdown

export const fetchSensorDropdown = (data) => {

    return {
        type: surveillanceActionType.FETCH_SENSOR_DROPDOWN,
        data: data,

    }
}
export const fetchSensorDropdownSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_SENSOR_DROPDOWN_SUCCESS,
        payload: data
    }
}
export const fetchSensorDropdownError = (error) => {
    return {
        type: surveillanceActionType.FETCH_SENSOR_DROPDOWN_ERROR,
        error
    }
}
//fetch battery dropdown

export const fetchBatteryDropdown = (data) => {

    return {
        type: surveillanceActionType.FETCH_BATTERY_DROPDOWN,
        data: data,

    }
}
export const fetchBatteryDropdownSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_BATTERY_DROPDOWN_SUCCESS,
        payload: data
    }
}
export const fetchBatteryDropdownError = (error) => {
    return {
        type: surveillanceActionType.FETCH_BATTERY_DROPDOWN_ERROR,
        error
    }
}
//add encoder 

export const addEncoder = (data) => {
    return {
        type: surveillanceActionType.ADD_ENCODER,
        data: data,

    }
}
export const addEncoderSuccess = (data) => {

    return {
        type: surveillanceActionType.ADD_ENCODER_SUCCESS,
        payload: data
    }
}
export const addEncoderError = (error) => {
    return {
        type: surveillanceActionType.ADD_ENCODER_ERROR,
        error
    }
}
export const clearaddEncoderStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_ADD_ENCODER_STATUS,

    }
}

//fetch single encoder 

export const getSingleEncoder = (data) => {
    return {
        type: surveillanceActionType.FETCH_SINGLE_ENCODER,
        data: data,

    }
}
export const getSingleEncoderSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_SINGLE_ENCODER_SUCCESS,
        payload: data
    }
}
export const getSingleEncoderError = (error) => {
    return {
        type: surveillanceActionType.FETCH_SINGLE_ENCODER_ERROR,
        error
    }
}

//delete encoder 

export const deleteEncoder = (data) => {
    return {
        type: surveillanceActionType.DELETE_ENCODER,
        data: data,

    }
}
export const deleteEncoderSuccess = (data) => {

    return {
        type: surveillanceActionType.DELETE_ENCODER_SUCCESS,
        payload: data
    }
}
export const deleteEncoderError = (error) => {
    return {
        type: surveillanceActionType.DELETE_ENCODER_ERROR,
        error
    }
}
export const cleardeleteEncoderStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_DELETE_ENCODER_STATUS,

    }
}

//edit encoder 

export const editEncoder = (id,data) => {
    return {
        type: surveillanceActionType.EDIT_ENCODER,
        id:id,
        data: data,

    }
}
export const editEncoderSuccess = (data) => {

    return {
        type: surveillanceActionType.EDIT_ENCODER_SUCCESS,
        payload: data
    }
}
export const editEncoderError = (error) => {
    return {
        type: surveillanceActionType.EDIT_ENCODER_ERROR,
        error
    }
}
export const cleareditEncoderStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_EDIT_ENCODER_STATUS,

    }
}

export const addSurveillanceMap = (data) => {

    return {
        type: surveillanceActionType.SET_SURVEILLANCE_MAP,
        payload: data
    }
}

export const addSurveillanceMarkers = (data) => {
    return {
        type: surveillanceActionType.SET_SURVEILLANCE_MAP_MARKERS,
        payload: data
    }
}
//add power 

export const addPower = (data) => {
    return {
        type: surveillanceActionType.ADD_POWER,
        data: data,

    }
}
export const addPowerSuccess = (data) => {

    return {
        type: surveillanceActionType.ADD_POWER_SUCCESS,
        payload: data
    }
}
export const addPowerError = (error) => {
    return {
        type: surveillanceActionType.ADD_POWER_ERROR,
        error
    }
}
export const clearaddPowerStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_ADD_POWER_STATUS,

    }
}
//get power list 

export const fetchPowerList = (data) => {
    return {
        type: surveillanceActionType.FETCH_POWERLIST,
        data: data,

    }
}
export const fetchPowerListSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_POWERLIST_SUCCESS,
        payload: data
    }
}
export const fetchPowerListError = (error) => {
    return {
        type: surveillanceActionType.FETCH_POWERLIST_ERROR,
        error
    }
}
//delete power 

export const deletePower = (data) => {
    return {
        type: surveillanceActionType.DELETE_POWER,

        data: data,

    }
}
export const deletePowerSuccess = (data) => {

    return {
        type: surveillanceActionType.DELETE_POWER_SUCCESS,
        payload: data
    }
}
export const deletePowerError = (error) => {
    return {
        type: surveillanceActionType.DELETE_POWER_ERROR,
        error
    }
}
export const cleardeletePowerStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_DELETE_POWER_STATUS,

    }
}
//edit power 

export const editPower = (id,data) => {
    return {
        type: surveillanceActionType.EDIT_POWER,
        id:id,
        data: data,

    }
}
export const editPowerSuccess = (data) => {

    return {
        type: surveillanceActionType.EDIT_POWER_SUCCESS,
        payload: data
    }
}
export const editPowerError = (error) => {
    return {
        type: surveillanceActionType.EDIT_POWER_ERROR,
        error
    }
}
export const cleareditPowerStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_EDIT_POWER_STATUS,

    }
}
//single power 

export const fetchSinglePower = (id) => {
    return {
        type: surveillanceActionType.FETCH_SINGLE_POWER,
        id:id,

    }
}
export const fetchSinglePowerSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_SINGLE_POWER_SUCCESS,
        payload: data
    }
}
export const fetchSinglePowerError = (error) => {
    return {
        type: surveillanceActionType.FETCH_SINGLE_POWER_ERROR,
        error
    }
}
//edit power 

export const updateCameraStatus = (data) => {
    return {
        type: surveillanceActionType.UPDATE_CAMERA_STATUS,
        data: data,

    }
}
export const updateCameraStatusSuccess = (data) => {

    return {
        type: surveillanceActionType.UPDATE_CAMERA_STATUS_SUCCESS,
        payload: data
    }
}
export const updateCameraStatusError = (error) => {
    return {
        type: surveillanceActionType.UPDATE_CAMERA_STATUS_ERROR,
        error
    }
}
export const clearUpdateCameraStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_UPDATE_CAMERA_STATUS,

    }
}
//fetch camera history

export const fetchCameraHistory = (data) => {
    return {
        type: surveillanceActionType.FETCH_CAMERA_HISTORY,
        data: data,

    }
}
export const fetchCameraHistorySuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_CAMERA_HISTORY_SUCCESS,
        payload: data
    }
}
export const fetchCameraHistoryError = (error) => {
    return {
        type: surveillanceActionType.FETCH_CAMERA_HISTORY_ERROR,
        error
    }
}
//device onboarding

export const deviceOnboarding = (data) => {
    return {
        type: surveillanceActionType.DEVICE_ONBOARD,
        data: data,

    }
}
export const deviceOnboardingSuccess = (data) => {

    return {
        type: surveillanceActionType.DEVICE_ONBOARD_SUCCESS,
        payload: data
    }
}
export const deviceOnboardingError = (error) => {
    return {
        type: surveillanceActionType.DEVICE_ONBOARD_ERROR,
        error
    }
}
export const clearDeviceOnboardingStatus = () => {
    return {
        type: surveillanceActionType.CLEAR_DEVICE_ONBOARD_STATUS,
        
    }
}
//prev history

export const fetchPrevHistory = (data) => {
    return {
        type: surveillanceActionType.FETCH_CAMERA_HISTORY,
        data: data,

    }
}
export const fetchPrevHistorySuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_CAMERA_HISTORY_SUCCESS,
        payload: data
    }
}
export const fetchPrevHistoryError = (error) => {
    return {
        type: surveillanceActionType.FETCH_PREV_HISTORY_ERROR,
        error
    }
}

//iot device

export const fetchIotDeviceList = (data) => {
    return {
        type: surveillanceActionType.FETCH_IOT_LIST,
        data: data,

    }
}
export const fetchIotDeviceListSuccess = (data) => {

    return {
        type: surveillanceActionType.FETCH_IOT_LIST_SUCCESS,
        payload: data
    }
}
export const fetchIotDeviceListError = (error) => {
    return {
        type: surveillanceActionType.FETCH_IOT_LIST_ERROR,
        error
    }
}