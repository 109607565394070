import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';

import { OrganisationCreate } from './components/organisation/OrganisationCreate';
import store from './redux/store';
import { UserLogin } from './components/authentication/UserLogin';
import { ForgotPassword } from './components/authentication/ForgotPassword';
import { OtpVerification } from './components/authentication/OtpVerification';
import { PasswordConfirmation } from './components/authentication/PasswordConfirm';
import { Dashboard } from './components/dashboard/Dashboard';
import { OrganisationList } from './components/organisation/OrganisationList';

import { RoleList } from './components/roleManagement/RoleList';
import { TrackingList } from './components/tracking/TrackingList';
import { RoleCreate } from './components/roleManagement/RoleCreate';
import { CheckPointCreate } from './components/tracking/checkPoint/CheckPointCreate';
import { CheckPointEdit } from './components/tracking/checkPoint/CheckPointEdit';
import { RouteCreate } from './components/tracking/route/RouteCreate';
import { RouteEdit } from './components/tracking/route/RouteEdit';
import { ListenerView } from './components/tracking/devices/listener/ListenerView';
import { TagView } from './components/tracking/devices/tag/TagView';
import { PersonnelCreate } from './components/tracking/personnel/PersonnelCreate';
import { PersonnelEdit } from './components/tracking/personnel/PersonnelEdit';
import { PersonnelAssign } from './components/tracking/personnel/PersonnelAssign';
import { UserList } from './components/userManagement/UserList';
import { AlertList } from './components/alert/AlertList';
import { PersonnelView } from './components/tracking/personnel/personelView/PersonnelView';
import { AlertView } from './components/alert/view/AlerView';
import { UserView } from './components/userManagement/view/ViewUser';
import { SubUserView } from './components/userManagement/view/SubUserView';
import { FinalView } from './components/userManagement/view/FinalView';
import { UserCreation } from './components/userManagement/create/UserCreation';
import { UsersSubuserCreation } from './components/userManagement/create/UsersSubuserCreate';
import { SubUserCreation } from './components/userManagement/create/subUserCreation';
import { UserEdit } from './components/userManagement/edit/UserEdit';
import { UsersSubuserEdit } from './components/userManagement/edit/UsersSubuserEdit';
import { SubUserEdit } from './components/userManagement/edit/SubUserEdit';
import { OrganisationView } from './components/organisation/view/OrganisationView';
import { CreateUser } from './components/organisation/userCreate/CreateUser';
import { ViewUser } from './components/organisation/userView/ViewUser';
import { CreateSubUser } from './components/organisation/userCreate/CreateSubUser';
import { ViewSubUser } from './components/organisation/userView/ViewSubUser';
import { CreateSubSubUser } from './components/organisation/userCreate/CreateSubSubUser';
import { ViewSubSubUser } from './components/organisation/userView/ViewSubSubUser';
import { EditUser } from './components/organisation/userEdit/EditUser';
import { EditSubUser } from './components/organisation/userEdit/EditSubUser';
import { EditSubSubUser } from './components/organisation/userEdit/EditSubSubUser';
import { EditOrganisation } from './components/organisation/EditOrganistation';
import { parseMessage, socket } from './services/SocketService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDevices } from './services/DeviceServices';
import { InitialLoading } from './components/authentication/IntialLoadingPage';
import { SurveillanceHome } from './components/surveillance/SurveillanceHome';
import { AuditTrailList } from './components/audittrail/AuditTrailList';
import { CameraView } from './components/surveillance/camera/view/CameraView';
import { AddCamera } from './components/surveillance/camera/addCamera/addCamera';
import { AddSite } from './components/organisation/orgSites/AddSites';
import { Stream } from './components/surveillance/camera/stream/Stream';
import { AddLevel } from './components/organisation/orgSites/AddLevel';
import { EditLevel } from './components/organisation/orgSites/EditLevel';
import { ViewLevelMap } from './components/organisation/orgSites/ViewMap';
import { RoleEdit } from './components/roleManagement/RoleEdit';
import { AddSensor } from './components/surveillance/sensor/AddSensor';
import { EditSensor } from './components/surveillance/sensor/EditSensor';
import { AddEncoder } from './components/surveillance/encoder/AddEncoder';
import { AddPower } from './components/surveillance/power/AddPower';
import { EditPower } from './components/surveillance/power/EditPower';
import { ReportHome } from './components/report/ReportHome';
import { ViewEncoder } from './components/surveillance/encoder/ViewEncoder';
import { EditEncoder } from './components/surveillance/encoder/EditEncoder';
import { MoreOn } from './components/authentication/MoreOn';
import { ContactUs } from './components/authentication/ContactUs';
import { CameraPreviousHistory } from './components/surveillance/camera/CameraPreviousHistory';
import { CameraSingleHistory } from './components/report/surveillanceReports/CameraSingleHistory';
import { TelegramBotCreation } from './components/organisation/OrgTelegram/TelegramBotCreation';
import { OrgTelegramBotEdit } from './components/organisation/OrgTelegram/OrgTelegramBotEdit';
import PropTypes from 'prop-types';
import { CleanerHistoryList } from './components/tracking/personnel/personelView/CleanerHistoryList';
import { CleanerHistoryView } from './components/tracking/personnel/personelView/CleanerHistoryView/CleanerHistoryView';
  // "react-app",
  //           "react-app/jest"
const Msg = ({ title, message }) => (
  <div>
    <h4>{title}</h4>
    <p>{message} </p>
  </div>
)
Msg.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  // closeToast: PropTypes.func, // Optional, if you need to use it
};


function App() {

  useEffect(() => {
    let devices = [];
    async function initSocket() {
      const token = sessionStorage.getItem('socketAuthToken');
      const org_id = sessionStorage.getItem('org_Id');
      if (token) {
        const resp = await getDevices({
          start: 0,
          limit: 10000,
          site_id: 0,
          org_id: org_id
        });
        devices = resp.data;
        socket.connect();
      }
    }
    initSocket();
    function onConnect() {
      const token = sessionStorage.getItem('socketAuthToken');
      socket.emit('authentication', { token: token });
    }

    function onDisconnect() {

    }

    function onServerMessage(serverMessage) {
      const deviceExists = devices.findIndex((e) => { return e.tag_name === serverMessage.listenerName || e.tag_name === serverMessage.infantName })
      if (deviceExists > -1) {
        const message = parseMessage(serverMessage);
        toast.error(<Msg title={message.createdTitle} message={message.createdMessage}></Msg>);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('serverMessage', onServerMessage);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('serverMessage', onServerMessage);
    };
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter >
        <Routes>
          <Route path='/' element={<UserLogin />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/otp-verification' element={<OtpVerification />} />
          <Route path='/password-confirmation' element={<PasswordConfirmation />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/loading..' element={<InitialLoading />} />
          <Route path='/organisation' element={<OrganisationList />} />
          <Route path='/organisation/create' element={<OrganisationCreate />} />
          <Route path='/organisation/edit' element={<EditOrganisation />} />
          <Route path='/organisation/view' element={<OrganisationView />} />
          <Route path='/organisation/user/create' element={<CreateUser />} />
          <Route path='/organisation/subuser/create' element={<CreateSubUser />} />
          <Route path='/organisation/subuser/user/create' element={<CreateSubSubUser />} />
          <Route path='/organisation/user/view' element={<ViewUser />} />
          <Route path='/organisation/user/subuser/view' element={<ViewSubUser />} />
          <Route path='/organisation/subuser/user/view' element={<ViewSubSubUser />} />
          <Route path='/organisation/user/edit' element={<EditUser />} />
          <Route path='/organisation/user/subuser/edit' element={<EditSubUser />} />
          <Route path='/organisation/subuser/user/edit' element={<EditSubSubUser />} />
          <Route path='/organisation/site/create/:id' element={<AddSite />} />
          <Route path='/organisation/level/create/:id' element={<AddLevel />} />
          <Route path='/organisation/level/edit/:id/:orgId' element={<EditLevel />} />
          <Route path='/organisation/site/view/map/:id' element={<ViewLevelMap />} />
          <Route path='/organisation/telegram/create' element={<TelegramBotCreation />} />
          <Route path='/organisation/telegram/edit/:id' element={<OrgTelegramBotEdit />} />

          
          <Route path='/roleManagement' element={<RoleList />} />
          <Route path='/roleManagement/create' element={<RoleCreate />} />
          <Route path='/roleManagement/edit/:id' element={<RoleEdit />} />

          <Route path='/tracking' element={<TrackingList />} />
          <Route path='/tracking/checkpoint/create' element={<CheckPointCreate />} />
          <Route path='/tracking/checkpoint/edit' element={<CheckPointEdit />} />
          <Route path='/tracking/route/create' element={<RouteCreate />} />
          <Route path='/tracking/route/edit' element={<RouteEdit />} />
          <Route path='/tracking/device/view' element={<ListenerView />} />
          <Route path='/tracking/device/tag/view' element={<TagView />} />
          <Route path='/tracking/personnel/create' element={<PersonnelCreate />} />
          <Route path='/tracking/personnel/view' element={<PersonnelView />} />
          <Route path='/tracking/personnel/edit' element={<PersonnelEdit />} />
          <Route path='/tracking/personnel/assign' element={<PersonnelAssign />} />
          <Route path='/tracking/cleaner/history/:id' element={<CleanerHistoryList />} />
          <Route path='/tracking/cleaner/history/view/:id' element={<CleanerHistoryView />} />

          <Route path='/usermanagement' element={<UserList />} />
          <Route path='/usermanagement/create' element={<UserCreation />} />
          <Route path='/usermanagement/view' element={<UserView />} />
          <Route path='/usermanagement/subuser/view' element={<SubUserView />} />
          <Route path='/usermanagement/user/subuser/create' element={<UsersSubuserCreation />} />
          <Route path='/usermanagement/subuser/create' element={<SubUserCreation />} />
          <Route path='/usermanagement/subuser/user/view' element={<FinalView />} />
          <Route path='/usermanagement/user/edit' element={<UserEdit />} />
          <Route path='/usermanagement/user/subuser/edit' element={<UsersSubuserEdit />} />
          <Route path='/usermanagement/subuser/edit' element={<SubUserEdit />} />
          <Route path='/alert' element={<AlertList />} />
          <Route path='/alert/view/:type/:id' element={<AlertView />} />
          <Route path='/surveillance' element={<SurveillanceHome />} />
          <Route path='/surveillance/view' element={<CameraView />} />
          <Route path='/surveillance/camera/add' element={<AddCamera />} />
          <Route path='/surveillance/camera/stream' element={<Stream />} />
          <Route path='/surveillance/sensor/create' element={<AddSensor />} />
          <Route path='/surveillance/sensor/edit/:id' element={<EditSensor />} />
          <Route path='/surveillance/encoder/add' element={<AddEncoder />} />
          <Route path='/surveillance/encoder/view/:id' element={<ViewEncoder />} />
          <Route path='/surveillance/encoder/edit/:id' element={<EditEncoder />} />
          <Route path='/surveillance/encoder/previous-history/:id' element={<CameraPreviousHistory />} />

          <Route path='/surveillance/power/add' element={<AddPower />} />
          <Route path='/surveillance/power/edit/:id' element={<EditPower />} />


          {/* <Route path='/report' element={<ReportList />} /> */}
          <Route path='/report' element={<ReportHome />} />
          <Route path='/report/view/:id' element={<CameraSingleHistory />} />

          <Route path='/audittrail' element={<AuditTrailList />} />

          <Route path='/more' element={<MoreOn />} />
          <Route path='/contactus' element={<ContactUs />} />

          
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
