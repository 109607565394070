import { Grid, Typography } from '@mui/material'
import React from 'react'
export const Guagebox = ({ isDarkMode,guageVals,userRole }) => {
  return (
    <Grid container spacing={6} sx={{ pt: 5 }} style={{ alignContent: 'left' }}>
      {
userRole == 1 ?
      
     <>
      <Grid item className=" zeromr" sx={{ m: 0 }} lg={2.95} md={2.9} sm={12} xs={12} >
        <Grid container className={isDarkMode ? "alert-box alert_left_border " : "alert-box-light alert_left_border"} style={{alignItems:'left'}}>
          <Grid item >
            <img className="" src={require("../../assets/icons/org_alert.png")} alt="user_icon" />
          </Grid>
          <Grid item>
            <Typography className={isDarkMode ? "alert_text " : "guage_text_light"}>
              No. Of Organisation
            </Typography>
            <Typography className={isDarkMode ? "alert_text alert_no" : "guage_text_light alert_no"}>
              {guageVals.organisation_count}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className=" zeromr" sx={{ m: 0 }} lg={2.95} md={2.9} sm={12} xs={12}>
        <Grid container className={isDarkMode ? "alert-box  alert_left_border" : "alert-box-light alert_left_border"}>
          <Grid item>
            <img className="" src={require("../../assets/icons/total_user.png")} alt="user_icon" />
          </Grid>
          <Grid item>
            <Typography className={isDarkMode ? "alert_text" : "guage_text_light"}>
              Total No. Of Users
            </Typography>
            <Typography className={isDarkMode ? "alert_text alert_no" : "guage_text_light alert_no"}>
            {guageVals.users_count}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
</>

:
<>
<Grid item className=" zeromr" sx={{ m: 0 }} lg={2.95} md={2.9} sm={12} xs={12} >
  <Grid container className={isDarkMode ? "alert-box alert_left_border " : "alert-box-light alert_left_border"} style={{alignItems:'left'}}>
    <Grid item >
      <img className="" src={require("../../assets/icons/total_alert.png")} alt="user_icon" />
    </Grid>
    <Grid item>
      <Typography className={isDarkMode ? "alert_text " : "guage_text_light"}>
        No. Of Sentry Alerts
        
      </Typography>
      <Typography className={isDarkMode ? "alert_text alert_no" : "guage_text_light alert_no"}>
        {guageVals.sentry_alert}
      </Typography>
    </Grid>
  </Grid>
</Grid>
<Grid item className=" zeromr" sx={{ m: 0 }} lg={2.95} md={2.9} sm={12} xs={12}>
  <Grid container className={isDarkMode ? "alert-box  alert_left_border" : "alert-box-light alert_left_border"}>
    <Grid item>
      <img className="" src={require("../../assets/icons/total_user.png")} alt="user_icon" />
    </Grid>
    <Grid item>
      <Typography className={isDarkMode ? "alert_text" : "guage_text_light"}>
      No. Of Patrol Alerts
      </Typography>
      <Typography className={isDarkMode ? "alert_text alert_no" : "guage_text_light alert_no"}>
      {guageVals.patrol_alert}
      </Typography>
    </Grid>
  </Grid>
</Grid>
</>
      }
      <Grid item className=" zeromr" sx={{ m: 0 }} lg={2.95} md={2.9} sm={12} xs={12}>
        <Grid container className={isDarkMode ? "alert-box alert_left_border" : "alert-box-light alert_left_border"}>
          <Grid item>
            <img className="" src={require("../../assets/icons/total_alert.png")} alt="user_icon" />
          </Grid>
          <Grid item>
            <Typography className={isDarkMode ? "alert_text" : "guage_text_light"}>
              No. Of Alerts
            </Typography>
            <Typography className={isDarkMode ? "alert_text alert_no" : "guage_text_light alert_no"}>
            {guageVals.total_alert}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className=" zeromr" sx={{ m: 0 }} lg={2.95} md={2.9} sm={12} xs={12}>
        <Grid container className={isDarkMode ? "alert-box " : "alert-box-light"} >
          <Grid item>
            <img className="" src={require("../../assets/icons/critical_alert.png")} alt="user_icon" />
          </Grid>
          <Grid item>
            <Typography className={isDarkMode ? "alert_text" : "guage_text_light"}>
              No. Of Critical Alerts
            </Typography>
            <Typography className={isDarkMode ? "alert_text alert_no" : "guage_text_light alert_no"}>
            {guageVals.critical_alert}

            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  )
} 
