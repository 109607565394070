import React, { useEffect, useState } from "react";
import { Layout } from "../../../layout/Layout";
import { useDispatch } from "react-redux";
import { fetchSingleTag } from "../../../../redux/actions/DeviceActions";
import { useSelector } from "react-redux";
import { CircularProgress, Grid, Typography } from "@mui/material";
export const TagView = ({ }) => {
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
    const [tagLastSeen, setTagLastSeen] = useState("")
    const [tagDetails, setTagDeatails] = useState({});
    const dispatch = useDispatch();
    const TagData = useSelector(state => state.device.singleTag);

    useEffect(() => {
        const id = sessionStorage.getItem('viewListenerId')
        dispatch(fetchSingleTag(id))
    }, [])
    useEffect(() => {
        if (!TagData.isFetching && TagData.payload && TagData.payload.success === true) {
            const tempTag = TagData.payload.checkpoint_details;

            const timestamp = tempTag.last_reported_time * 1000;
            const lastSeen = new Date(timestamp).toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            });

            const templastSeen = tempTag.last_reported_time == 0 || tempTag.last_reported_time == null ? "NA" : lastSeen;

            // timestamp: devices.timestamp

            setTagLastSeen(templastSeen);
            setTagDeatails(tempTag)
        }
    }, [TagData]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    return (
        <Layout
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"tracking"}
            activeMenuKey={"tracking"}
            isCreate={true}
            activeSubtab={"four"}

        >
            {TagData.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} columnSpacing={3}
                 sx={{ mt: 3, pb: 5, ml: { lg: '3% !important', md: '3% !important', sm: '-5% !important', xs: '-5% !important' } }}
                    className={isDarkMode ? "table_dark " : "table_light "} >
                    <Grid item lg={12} >
                        <Typography
                            className='device_view_head'>Tag</Typography>
                    </Grid>
                    <Grid item lg={11} sx={{ mt: 2 }}>
                        <div className='device_view'>
                            <Typography
                                className='device_view_head'
                                sx={{ mb: 5 }}>{tagDetails.infant_name}</Typography>
                            <Grid container>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Tag Name</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {tagDetails.tag_name}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Room
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {tagDetails.block_name}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Checked Out</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {tagDetails.checkoutstatus === 0 ? "No" : "Yes"}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Tag type
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {tagDetails.asset_type === 0 ? "Normal" : "Invalid"}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Asset Cloud Id</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {tagDetails.infant_id}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Asset Name
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {tagDetails.infant_name}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Tag Cloud Id</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {tagDetails.tag_id}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Tag Major
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {tagDetails.tag_major}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Tag Minor</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {tagDetails.tag_minor}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Personnel Name

                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {tagDetails.contractorname == "" ? "Not Assinged Yet" : tagDetails.contractorname}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Battery Level</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {tagDetails.battery == null ? "NA" : tagDetails.battery + "%"}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Last Reported
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {tagLastSeen}

                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            {/* <Grid container>
                            <Grid item sx={{ pb: 7, }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Tag Name</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.tag_name}
                                </Typography>

                            </Grid>

                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Room</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.block_name}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Checked Out</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.checkoutstatus === 0 ? "No" : "Yes"}

                                </Typography>

                            </Grid>
                             <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Room</Typography>
                                <Typography className='device_view_text content_color'>
                                {tagDetails.block_name}

                                </Typography>

                            </Grid> 
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Tag type</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.asset_type === 0 ? "Normal" : "Invalid"}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Asset Cloud Id</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.infant_id}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Asset Name</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.infant_name}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Tag Cloud Id</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.tag_id}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Tag Major</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.tag_major}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Tag Minor</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.tag_minor}

                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'> Personnel Name</Typography>
                                <Typography className='device_view_text content_color'>
                                    {tagDetails.contractorname == "" ? "Not Assinged Yet" : tagDetails.contractorname}

                                </Typography>

                            </Grid>
    </Grid> */}
                        </div>
                    </Grid>
                </Grid>
            }
        </Layout>
    )
}